import React, { useState } from 'react';
import { Box, Button, Input, Textarea, Image, Flex } from '@chakra-ui/react';
import axios from 'axios';
import {API_URL} from "../../utils/apiConfig";

function CreateNews() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [mainImage, setMainImage] = useState(null);
  const [images, setImages] = useState([]);

  // Update handleFileChange to append new files to existing files
  const handleFileChange = (e, setter, isMainImage = false) => {
    const files = Array.from(e.target.files);

    if (isMainImage) {
      // Set only the main image
      setter(files);
    } else {
      // Append new files to existing images
      setImages((prevImages) => [...prevImages, ...files]);
    }
  };

  const handlePost = async () => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);

    if (mainImage) {
      formData.append('mainImage', mainImage[0]); // Ensure mainImage is an array
    }

    images.forEach((file) => formData.append('images', file)); // Attach all images

    try {
      await axios.post(`${API_URL}/api/news`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
      alert('News posted successfully');
      // Clear form after successful post
      setTitle('');
      setDescription('');
      setMainImage(null);
      setImages([]);
    } catch (error) {
      console.error('Error posting news:', error.response.data);
      alert('Failed to post news');
    }
  };

  return (
    <Box p={4}>
      <Input
        placeholder="Title"
        mb={2}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <Textarea
        placeholder="Description"
        mb={2}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      
      <Box mb={4}>
        <Input
          type="file"
          mb={2}
          accept="image/*"
          onChange={(e) => handleFileChange(e, setMainImage, true)}
        />
        {mainImage && mainImage[0] && (
          <Image
            src={URL.createObjectURL(mainImage[0])}
            alt="Main Image Preview"
            boxSize="100px"
            objectFit="cover"
            mb={2}
          />
        )}
      </Box>
      
      <Box mb={4}>
        <Input
          type="file"
          mb={2}
          accept="image/*"
          multiple
          onChange={(e) => handleFileChange(e, setImages)}
        />
        <Flex wrap="wrap" gap={2}>
          {images.map((file, index) => (
            <Image
              key={index}
              src={URL.createObjectURL(file)}
              alt={`Additional Image Preview ${index + 1}`}
              boxSize="100px"
              objectFit="cover"
              mb={2}
            />
          ))}
        </Flex>
      </Box>

      <Button mt={4} onClick={handlePost}>
        Post
      </Button>
    </Box>
  );
}

export default CreateNews;
