// NotificationContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';

const NotificationContext = createContext();

export const useNotifications = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState(() => {
    const savedNotifications = localStorage.getItem('notifications');
    // const isNewNotification = localStorage.getItem()
    return savedNotifications ? JSON.parse(savedNotifications) : [];
  });

  const [isNewNotification, setIsNewNotification] = useState(false); // New state variable

  const addNotification = (message, eventId,eventName, game) => {
    setNotifications(prevNotifications => {
      const newNotification = { id: Date.now(), message, eventId, eventName, game, timestamp: new Date() };
      const newNotifications = [newNotification, ...prevNotifications];
      localStorage.setItem('notifications', JSON.stringify(newNotifications.slice(0, 200)));
      localStorage.setItem('isNewNotification', true);
      setIsNewNotification(true);
      return newNotifications;
    });
  };

  const clearNotifications = () => {
    setNotifications([]);
    localStorage.removeItem('notifications');
  };

  const clearNewNotification = () => {
    localStorage.setItem('isNewNotification', false); 
    setIsNewNotification(false); // Clear isNewNotification when viewing notifications
  };

  console.log("isnewwww",isNewNotification)

  useEffect(() => {
    localStorage.setItem('notifications', JSON.stringify(notifications));
  }, [notifications]);

  return (
    <NotificationContext.Provider value={{ notifications, addNotification, clearNotifications, isNewNotification, clearNewNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};
