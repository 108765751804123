// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDfRjBU3UCk8cjeMiuXz4o-ofGs1bzgiek",
  authDomain: "gymnazien-e2c4f.firebaseapp.com",
  projectId: "gymnazien-e2c4f",
  storageBucket: "gymnazien-e2c4f.appspot.com",
  messagingSenderId: "897138303884",
  appId: "1:897138303884:web:89330189adf1fdbf1b6ca9",
  measurementId: "G-YRZWVMLB9R"
};

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// };


// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
