// src/pages/PlayerListPrintPage.js
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Text, VStack, Table, Thead, Tr, Th, Tbody, Td, Spinner, Heading, Button, Flex, Input } from '@chakra-ui/react';
import axios from 'axios';
import { useReactToPrint } from 'react-to-print';
import { useAuth } from '../context/AuthContext';
import { API_URL } from "../utils/apiConfig";
import { GiCheckMark } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import { IoMdPlay } from "react-icons/io";

const PlayerListPrintPageRanked = () => {
  const { eventId, game } = useParams();
  const { user, userSupervisorRole, loading: userLoading } = useAuth();
  const [event, setEvent] = useState(null);
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [customHeading, setCustomHeading] = useState(''); // State for custom heading
  const navigate = useNavigate();
  const componentPDF = useRef();

  useEffect(() => {
    if (!userLoading) {
      if (!user || userSupervisorRole !== 'supervisor') {
        navigate('/supervisor');
        return;
      }

      const fetchPlayers = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/events/${eventId}`);
          const eventDetails = response.data;
          const gamePlayers = eventDetails.players[game];
          if (eventDetails) {
            setEvent(eventDetails);
            setPlayers(gamePlayers);
          } else {
            console.error('Event not found');
          }
        } catch (error) {
          console.error('Error fetching players:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchPlayers();
    }
  }, [userLoading, user, userSupervisorRole, eventId, game, navigate]);

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "gymnast-scorelist-gymnazien",
    onAfterPrint: () => alert("Gymnast Scorelist PDF exported successfully"),
  });

  if (loading || userLoading) {
    return <Spinner />;
  }

  if (!user || userSupervisorRole !== 'supervisor') {
    return <Text>You are not authorized to view this page.</Text>;
  }

  // Sort players by total scores for the ranking tab
  const sortedPlayers = [...players].sort((a, b) => b.scores.total - a.scores.total);


  return (
    <>
      
    </>
  );
};

export default PlayerListPrintPageRanked;
