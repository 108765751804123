import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ChakraProvider, Box, Button, VStack, HStack, Text, useDisclosure } from '@chakra-ui/react';
import ManageEventModal from './ManageCompetition';
import { useNavigate } from 'react-router-dom';
import {API_URL} from "../../utils/apiConfig";

const AllEvents = () => {
  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/events`);
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  const handleManage = (id) => {
    setSelectedEventId(id);
    onOpen();
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/api/events/${id}`);
      setEvents(events.filter(event => event._id !== id));
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  return (
    <ChakraProvider>
      <Box p={5}>
        <HStack justify="space-between">
          <Text fontSize="2xl">All Events</Text>
          <Button bg={"#ffbf00"} onClick={() => navigate('/create-event')}>
            Create Compitition +
          </Button>
        </HStack>
        <VStack spacing={4} mt={4}>
          {events.map((event) => (
            <HStack key={event._id} justify="space-between" w="100%">
              <Text>{event.eventName}</Text>
              <HStack>
                <Button colorScheme="blue" onClick={() => handleManage(event._id)}>
                  Manage
                </Button>
                <Button colorScheme="red" onClick={() => handleDelete(event._id)}>
                  Delete
                </Button>
              </HStack>
            </HStack>
          ))}
        </VStack>
      </Box>
      {selectedEventId && (
        <ManageEventModal
          isOpen={isOpen}
          onClose={onClose}
          eventId={selectedEventId}
          fetchEvents={async () => {
            try {
              const response = await axios.get(`${API_URL}/api/events`);
              setEvents(response.data);
            } catch (error) {
              console.error('Error fetching events:', error);
            }
          }}
        />
      )}
    </ChakraProvider>
  );
};

export default AllEvents;
