import React from 'react';
// import EventForm from './Components/EventForm';
import AllEvents from './Events';
import MyEvents from './MyEvents';
import GetStarted from '../Components/HomePage/GetStarted';
import SplitWithImage from '../Components/HomePage/SplitWithImages';
import SimpleThreeColumns from '../Components/HomePage/SimpleThreeColumns';
import AllNews from './news/AllNews';

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <AllNews/>
        <GetStarted/>
        {/* <MyEvents/> */}
        <SplitWithImage/>
        <SimpleThreeColumns/>
        {/* <AllEvents/> */}
        
      </header>
    </div>
  );
}

export default Home;
