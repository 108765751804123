import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading, Spinner, Text } from '@chakra-ui/react';
import axios from 'axios';
import { FaCircleCheck } from "react-icons/fa6";
import {API_URL} from "../utils/apiConfig";

const VerifyCertificate = () => {
    const {eventId, certificateNumber } = useParams();
    const [verificationResult, setVerificationResult] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const verifyCertificate = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/events/${eventId}/verify-certificate/${certificateNumber}`);
                setVerificationResult(response.data);
                console.log("Verify certificate",response.data)
            } catch (error) {
                setVerificationResult({ error: 'Certificate not found' });
            } finally {
                setLoading(false);
            }
        };

        verifyCertificate();
    }, [certificateNumber]);

    if (loading) {
        return (
            <Box textAlign="center" minHeight={"90vh"} mt={10}>
                <Spinner size="xl" />
            </Box>
        );
    }

    return (
        <Box textAlign="center" minHeight={"90vh"} mt={10}>
            {verificationResult.error ? (
                <Text fontSize="2xl" color="red.500">
                    {verificationResult.error}
                </Text>
            ) : (
                <Box textAlign="center" py={10} px={6}>
                <FaCircleCheck style={{fontSize:"150px", color:"green", margin:"auto"}} />
                <Heading as="h2" size="xl" mt={6} mb={2}>
                Certificate Verified for {verificationResult.name}
                </Heading>
                <Text fontWeight={"bold"} color={'gray.500'}>
                    Certificate Number:- {certificateNumber}
                </Text>
              </Box>
            )}
        </Box>
    );
};

export default VerifyCertificate;
