import React, { useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Image,
  Button,
  Spinner,
  Box,
  Heading,
  Flex,
  Text,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SideBar from './Sidebar/SideBar';
import { API_URL } from '../../utils/apiConfig';
import maleathleteimg from "../../Images/Screenshot (2).png";

const AthletesList = () => {
  const [athletes, setAthletes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [totalPages, setTotalPages] = useState(0);  // Track total pages
  const athletesPerPage = 50; // Fetch 50 athletes per page
  const fakephotourl = "";
  const token = localStorage.getItem('token');

  // Fetch athletes whenever currentPage changes
  useEffect(() => {
    const fetchAthletes = async () => {
      setLoading(true);
      try {
        // Fetch athletes for the current page with pagination
        const response = await axios.get(`${API_URL}/api/athlete/athletes/all-list?page=${currentPage}&limit=${athletesPerPage}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const { athletes, totalPages } = response.data;  // Extract data from backend
        setAthletes(athletes);  // Set athletes data
        setTotalPages(totalPages);  // Set total number of pages
        setLoading(false);
      } catch (error) {
        console.error('Error fetching athletes:', error);
        setLoading(false);
      }
    };

    fetchAthletes();
  }, [currentPage]);

  // Handler for the "Next" button
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handler for the "Previous" button
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (loading) {
    return <Spinner size="xl" />;
  }

  return (
    <SideBar>
      <Box>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Heading size="lg" mb={4}>
          Total Athletes Per Page: {athletes.length}
        </Heading>
        <Box mb={4}  display="flex" alignItems={"center"} gap={"10px"} justifyContent="space-between">
          <Button
            onClick={handlePreviousPage}
            isDisabled={currentPage === 1}  // Disable if on the first page
            colorScheme="blue"
          >
            Previous
          </Button>
          <Text fontWeight={"bold"}>{currentPage} of {totalPages}</Text>
          <Button
            onClick={handleNextPage}
            isDisabled={currentPage === totalPages}  // Disable if on the last page
            colorScheme="blue"
          >
            Next
          </Button>
        </Box>
        </Flex>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr border={"1px solid black"} >
                <Th fontSize={"14px"} border={"1px solid black"} textAlign={"center"} >Photo</Th>
                <Th fontSize={"14px"} border={"1px solid black"} textAlign={"center"}>Name</Th>
                <Th fontSize={"14px"} border={"1px solid black"} textAlign={"center"}>District</Th>
                <Th fontSize={"14px"} border={"1px solid black"} textAlign={"center"}>City</Th>
                <Th fontSize={"14px"} border={"1px solid black"} textAlign={"center"}>Athlete Code</Th>
                <Th fontSize={"14px"} border={"1px solid black"} textAlign={"center"}>Aadhar </Th>
                <Th fontSize={"14px"} border={"1px solid black"} textAlign={"center"}>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {athletes.map((athlete) => (
                <Tr border={"1px solid black"} key={athlete._id}>
                  <Td border={"1pc"} textAlign={"center"}>
                    <Image
                      src={athlete.aadhaarData?`data:image/jpeg;base64,${athlete.aadhaarData?.photoUrl ? athlete.aadhaarData.photoUrl : fakephotourl}` : maleathleteimg}
                      alt={athlete.name}
                      boxSize="50px"
                      borderRadius="full"
                    />
                  </Td>
                  <Td border={"1pc"}>{athlete.name}</Td>
                  <Td border={"1pc"} textAlign={"center"}>{athlete.district}</Td>
                  <Td border={"1pc"} textAlign={"center"}>{athlete.city}</Td>
                  <Td border={"1pc"} textAlign={"center"}>{athlete.athleteCode}</Td>
                  <Td border={"1pc"} textAlign={"center"}>{athlete.aadhaarNumber}</Td>
                  
                  <Td border={"1pc"} textAlign={"center"}>
                    <Link to={`/admin/superadmin/dashboard/athlete/athletes/${athlete.email}`}>
                      <Button colorScheme="blue">View Details</Button>
                    </Link>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        {/* Pagination Controls */}
        <br />
        <Box mt={4} display="flex" justifyContent={"center"} alignItems={"center"} gap={"10px"}>
          <Button
            onClick={handlePreviousPage}
            isDisabled={currentPage === 1}  // Disable if on the first page
            colorScheme="blue"
          >
            Previous
          </Button>
          <Text fontWeight={"bold"}>{currentPage} of {totalPages}</Text>
          <Button
            onClick={handleNextPage}
            isDisabled={currentPage === totalPages}  // Disable if on the last page
            colorScheme="blue"
          >
            Next
          </Button>
        </Box>
      </Box>
    </SideBar>
  );
};

export default AthletesList;
