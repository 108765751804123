import React, { useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Spinner,
  Box,
  Select,
  Input,
  Heading,
  Flex
} from '@chakra-ui/react';
import axios from 'axios';
import { API_URL } from '../../utils/apiConfig';
import ChartFlex from './Charts/ChartFlex';
import { useNavigate } from 'react-router-dom';
import SideBar from './Sidebar/SideBar';

const AthletesAnalytics = () => {
  const navigate = useNavigate();
  const [athletes, setAthletes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [gender, setGender] = useState('');
  const [isAadhaarVerified, setIsAadhaarVerified] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [state, setState] = useState('');
  const [district, setDistrict] = useState('');
  const [sortByAge, setSortByAge] = useState('');
  const [stateCounts, setStateCounts] = useState([]);
  const [districtCounts, setDistrictCounts] = useState([]);
  const [genderCounts, setGenderCounts] = useState([]);
  const [aadhaarVerifiedCounts, setIsAadhaarVerifiedCounts] = useState([]);
  const athletesPerPage = 1000;

  const token = localStorage.getItem('token');

  // Fetch athletes whenever the page or filter/sort options change
  useEffect(() => {
    const fetchAthletes = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/api/athlete/athletes/all-sorted`, {
          params: {
            page: currentPage,
            limit: athletesPerPage,
            gender,
            isAadhaarVerified,
            birthYear,
            state,
            district,
            sortByAge
          },
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const { athletes, totalPages, stateCounts, districtCounts, genderCounts, aadhaarVerifiedCounts } = response.data;
        console.log("athles", athletes)
        setAthletes(athletes);
        setTotalPages(totalPages);
        setStateCounts(stateCounts);
        setDistrictCounts(districtCounts);
        setGenderCounts(genderCounts);
        setIsAadhaarVerifiedCounts(aadhaarVerifiedCounts);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching athletes:', error);
        setLoading(false);
      }
    };

    fetchAthletes();
  }, [currentPage, gender, isAadhaarVerified, birthYear, state, district, sortByAge]);

  // Handler for page navigation
  const handlePageChange = (e) => {
    setCurrentPage(Number(e.target.value));
  };

  // Function to format date to dd/mm/yyyy
  const formatDate = (dob) => {
    if (!dob || dob === 'N/A') return 'N/A';
    const date = new Date(dob);
    return `${date.getFullYear()}`;
  };


  if (loading) {
    return <Spinner size="xl" />;
  }

  // Handle click on athlete to view details
  const handleAthleteClick = (athleteId) => {
    navigate(`/admin/superadmin/dashboard/athlete/athletes/${athleteId}`); // Navigate to athlete details page
  };


  return (
    <SideBar>
    <Box>
      {/* <Heading textAlign={"center"} size="lg" mb={4}>Athletes Analytics (Page {currentPage} of {totalPages})</Heading> */}
      <Heading textAlign={"center"} size="lg" mb={4}>Athletes Analytics</Heading>
      <ChartFlex genderCounts={genderCounts} aadhaarVerifiedCounts={aadhaarVerifiedCounts}/>

<br />
      {/* Filters */}
      <Flex w={"95%"} m={'auto'} flex={1} mb={4}>
        <Select placeholder="Filter by Gender" onChange={(e) => setGender(e.target.value)}>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </Select>

        <Select placeholder="Aadhaar Verified?" onChange={(e) => setIsAadhaarVerified(e.target.value)}>
          <option value="true">Verified</option>
          <option value="false">Not Verified</option>
        </Select>

        <Select placeholder="Filter by State" onChange={(e) => setState(e.target.value)}>
        {stateCounts.map((state) => (
          <option value={state._id}>{state._id}: {state.count}</option>
        ))}
        </Select>

        <Select placeholder="Filter by District" onChange={(e) => setDistrict(e.target.value)}>
        {districtCounts.map((state) => (
          <option value={state._id}>{state._id}: {state.count}</option>
        ))}
        </Select>

        {/* <Input placeholder="Filter by State" onChange={(e) => setState(e.target.value)} /> */}
        {/* <Input placeholder="Filter by District" onChange={(e) => setDistrict(e.target.value)} /> */}

        <Select placeholder="Sort by Age" onChange={(e) => setSortByAge(e.target.value)}>
          <option value="asc">Oldest First</option>
          <option value="desc">Youngest First</option>
        </Select>
      </Flex>
      <br />
      <br />

      {/* Athlete Table */}
      <TableContainer w={"95%"} m={"auto"}>
        <Table variant="striped" colorScheme='gray'>
          <Thead>
            <Tr border={"1px solid black"}>
                <Th border={"1px solid black"}>Sr</Th>
              <Th border={"1px solid black"}>Name</Th>
              <Th border={"1px solid black"}>Aadhar V</Th>
              <Th border={"1px solid black"}>Gender</Th>
              <Th border={"1px solid black"}>Birth Year</Th>
              <Th border={"1px solid black"}>District</Th>
              <Th border={"1px solid black"}>State</Th>
            </Tr>
          </Thead>
          <Tbody border={"1px solid black"}>
            {athletes.map((athlete, index) => (
              <Tr onClick={() => handleAthleteClick(athlete.email)} key={athlete._id}>
                <Td>{index+1}</Td>
                <Td>{athlete.name}</Td>
                <Td>{athlete.aadhaarVerified === true ? "Yes": "No"}</Td>
                <Td>{athlete.gender}</Td>
                <Td>{formatDate(athlete.dob)}</Td>
                <Td>{athlete.district}</Td>
                <Td>{athlete.state}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      {/* Pagination and Page Number Input */}
      <Box mt={4} display="flex" justifyContent="space-between">
        <Button onClick={() => setCurrentPage((prev) => prev - 1)} isDisabled={currentPage === 1}>Previous</Button>

        <Input
          type="number"
          placeholder="Enter page number"
          value={currentPage}
          onChange={handlePageChange}
          max={totalPages}
        />

        <Button onClick={() => setCurrentPage((prev) => prev + 1)} isDisabled={currentPage === totalPages}>Next</Button>
      </Box>

      {/* Athlete Counts by State and District */}
      <Box mt={4}>
        <Heading size="md">Athlete Count by State</Heading>
        {stateCounts.map((state) => (
          <Box key={state._id}>{state._id}: {state.count}</Box>
        ))}

        <Heading size="md">Athlete Count by District</Heading>
        {districtCounts.map((district) => (
          <Box key={district._id}>{district._id}: {district.count}</Box>
        ))}

        <Heading size="md">Athlete Count by Gender</Heading>
        {genderCounts.map((gender) => (
          <Box key={gender._id}>{gender._id}: {gender.count}</Box>
        ))}
      </Box>
    </Box>
    </SideBar>
  );
};

export default AthletesAnalytics;
