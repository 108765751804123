import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { keyframes } from "@emotion/react";
import {
  Box,
  Heading,
  SimpleGrid,
  GridItem,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Text, Button, Flex, Card, CardBody, CardFooter, CardHeader, IconButton,
} from '@chakra-ui/react';
import cardBackground from "../../cardBackground.jpg";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from 'react-router-dom';
import {API_URL} from "../../utils/apiConfig";

const EventsPage = () => {
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(1);
  const eventsPerPage = 9; // Number of events per page
  const [totalPages, setTotalPages] = useState(1);
  const [activeTab, setActiveTab] = useState('live');

  useEffect(() => {
    fetchEvents(page, activeTab);
  }, [page, activeTab]);

  const fetchEvents = async (page, status) => {
    try {
      const response = await axios.get(`${API_URL}/api/events-peg?status=${status}&page=${page}&limit=9`);
      setEvents(response.data.events);
      setTotalPages(response.data.totalPages);
      setPage(response.data.currentPage);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const filterEventsByStatus = (status) => {
    return events.filter(event => event.eventStatus === status);
  };


    // Get the current events for the selected page
    // const getPaginatedEvents = (filteredEvents) => {
    //   const indexOfLastEvent = page * eventsPerPage;
    //   const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
    //   return filteredEvents.slice(indexOfFirstEvent, indexOfLastEvent);
    // };
  
    const handleNextPage = (status) => {
      if (page < Math.ceil(filterEventsByStatus(status).length / eventsPerPage)) {
        setPage(page + 1);
      }
    };
  
    const handlePrevPage = () => {
      if (page > 1) {
        setPage(page - 1);
      }
    };

    const handleTabChange = (status) => {
      setPage(1); // Reset to the first page on tab change
      fetchEvents(status);
    };

  

const blinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const BlinkingDot = () => (
  <Box as="span" 
       display="inline-block" 
       width="12px" 
       height="12px" 
       borderRadius="50%" 
       bg="green.500"
       mt={"5px"} 
       animation={`${blinkAnimation} 1s infinite`}>
  </Box>
);

const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

  return (
    <Box minHeight="100vh" h={"auto"} bg={"gray.200"}  p={4}>
      <Heading textAlign={"center"}  as="h1" mb={4}>Gymnazien Gymnastics Competitions</Heading>
      <Tabs variant="enclosed" onChange={(index) => {
        const tabMapping = ['live', 'upcoming', 'ended']; // Map tabs to eventStatus
        setActiveTab(tabMapping[index]);
        setPage(1); // Reset page when tab changes
      }}>
        <TabList mb={4}>
          <Tab fontWeight={"bold"} fontSize={"20px"}>Live</Tab>
          <Tab fontWeight={"bold"} fontSize={"20px"} >Upcoming</Tab>
          <Tab fontWeight={"bold"} fontSize={"20px"} >Recent</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
          {filterEventsByStatus('live').length === 0 ? (
              <Heading textAlign="center" as="h1">No live events</Heading>
            ) : (
              <>
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 3 }} spacing={4}>
              {(filterEventsByStatus('live')).map((event) => (
                <Card
                sx={{
                  backgroundImage: `url(${cardBackground})`, // Use the imported variable
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  color: "white", // Ensures text is readable against background
                  borderRadius: "15px",
                }}
                maxW="sm"
                align="center"
                key={event._id}
                as={Link}
                to={`/live/${event._id}`}
              >
                <CardHeader
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent background for better readability
                    width: "100%",
                    textAlign: "center",
                    borderRadius: "15px 15px 0 0",
                    width: "100%",
                  }}
                >
                  <Flex
                    spacing="4"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                  
                    <Heading marginRight={"10px"} size="md">
                      {event.category === "men"? "MAG":"WAG"}
                    </Heading>
                    <IconButton
                      variant="ghost"
                      colorScheme="gray"
                      color={"white"}
                      sx={{
                        _hover: {
                          color: "black",
                          backgroundColor: "gray.200",
                          borderRadius: "50%",
                        },
                      }}
                      aria-label="See menu"
                      icon={<BsThreeDotsVertical />}
                    />
                  </Flex>
                </CardHeader>
                <CardBody
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent background for better readability
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <Heading marginRight={"10px"} bgGradient="linear(to-r, yellow.300, yellow.500, yellow.600)" bgClip={"text"} size="lg">
                  {capitalizeFirstLetter(event.competitionLevel)} Level
                  </Heading>
                  <Heading marginRight={"10px"} size="lg">
                    {event.eventName}
                  </Heading>
                  <Text>{new Date(event.dateTime).toLocaleString()}</Text>
                  <Flex justifyContent={"center"} mr={"20px"} alignItems={"center"} gap={"10px"}>
                  <BlinkingDot/><Heading bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" bgClip={"text"} size={"lg"}>Live</Heading>
                  </Flex>
                  
                </CardBody>
                <CardFooter w={"100%"}>
                  <Flex
                  spacing="4"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  w={"100%"}
    
                  >
                    <Text textAlign={"center"}>
                    View a live event of gymnastics on Gymnazien.
                  </Text>
                  </Flex>
                </CardFooter>
              </Card>
              ))}
            </SimpleGrid>
            {/* Pagination Controls */}
            <Box m={"auto"} w={{sm:"70%", md:"50%", lg:"30%"}}>
            <Flex m={"auto"} justifyContent={"center"} gap={"10px"}  mt={4}>
            <Button
              onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
              isDisabled={page === 1}
            >
              Previous
            </Button>
            <Text alignSelf="center">Page {page}</Text>
            <Button
              onClick={() => setPage((prev) => Math.min(prev + 1, 1))}
              isDisabled={page === totalPages}
            >
              Next
            </Button>
                  </Flex>
                  </Box>
              </>
            )
          }
          </TabPanel>
          <TabPanel>
          {filterEventsByStatus('upcoming').length === 0 ? (
              <Heading textAlign="center" as="h1">No upcoming events</Heading>
            ) : (
              <>
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 3 }} spacing={4}>
              {(filterEventsByStatus('upcoming')).map((event) => (
                <Card
                sx={{
                  backgroundImage: `url(${cardBackground})`, // Use the imported variable
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  color: "white", // Ensures text is readable against background
                  borderRadius: "15px",
                }}
                maxW="sm"
                align="center"
                key={event._id}
                as={Link}
                to={`/live/${event._id}`}
              >
                <CardHeader
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent background for better readability
                    width: "100%",
                    textAlign: "center",
                    borderRadius: "15px 15px 0 0",
                    width: "100%",
                  }}
                >
                  <Flex
                    spacing="4"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Heading marginRight={"10px"} size="md">
                      {event.category === "men"? "MAG":"WAG"}
                    </Heading>
                    <IconButton
                      variant="ghost"
                      colorScheme="gray"
                      color={"white"}
                      sx={{
                        _hover: {
                          color: "black",
                          backgroundColor: "gray.200",
                          borderRadius: "50%",
                        },
                      }}
                      aria-label="See menu"
                      icon={<BsThreeDotsVertical />}
                    />
                  </Flex>
                </CardHeader>
                <CardBody
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent background for better readability
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <Heading marginRight={"10px"} bgGradient="linear(to-r, yellow.300, yellow.500, yellow.600)" bgClip={"text"} size="lg">
                  {capitalizeFirstLetter(event.competitionLevel)} Level
                  </Heading>
                  <Heading marginRight={"10px"} size="lg">
                    {event.eventName}
                  </Heading>
                  <Text>{new Date(event.dateTime).toLocaleString()}</Text>
                  
                </CardBody>
                <CardFooter w={"100%"}>
                  <Flex
                  spacing="4"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  w={"100%"}
    
                  >
                    <Text textAlign={"center"}>
                    View a upcoming event of gymnastics on Gymnazien.
                  </Text>
                  </Flex>
                </CardFooter>
              </Card>
              ))}
            </SimpleGrid>
            {/* Pagination Controls */}
            <Box m={"auto"} w={{sm:"70%", md:"50%", lg:"30%"}}>
            <Flex m={"auto"} justifyContent={"center"} gap={"10px"}  mt={4}>
            <Button
              onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
              isDisabled={page === 1}
            >
              Previous
            </Button>
            <Text alignSelf="center">Page {page}</Text>
            <Button
              onClick={() => setPage((prev) => Math.min(prev + 1, 1))}
              isDisabled={page === totalPages}
            >
              Next
            </Button>
            </Flex>
            </Box>
          </>
            )
          }
          </TabPanel>
          <TabPanel>
          {filterEventsByStatus('ended').length === 0 ? (
              <Heading textAlign="center" as="h1">No Recent events</Heading>
            ) : (
              <>
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 3 }} spacing={4}>
              {(filterEventsByStatus('ended')).map((event) => (
                <Card
                sx={{
                  backgroundImage: `url(${cardBackground})`, // Use the imported variable
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  color: "white", // Ensures text is readable against background
                  borderRadius: "15px",
                }}
                maxW="sm"
                align="center"
                key={event._id}
                as={Link}
                to={`/live/${event._id}`}
              >
                <CardHeader
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent background for better readability
                    width: "100%",
                    textAlign: "center",
                    borderRadius: "15px 15px 0 0",
                    width: "100%",
                  }}
                >
                  <Flex
                    spacing="4"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Heading marginRight={"10px"} size="md">
                      {event.category === "men"? "MAG":"WAG"}
                    </Heading>
                    <IconButton
                      variant="ghost"
                      colorScheme="gray"
                      color={"white"}
                      sx={{
                        _hover: {
                          color: "black",
                          backgroundColor: "gray.200",
                          borderRadius: "50%",
                        },
                      }}
                      aria-label="See menu"
                      icon={<BsThreeDotsVertical />}
                    />
                  </Flex>
                </CardHeader>
                <CardBody
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent background for better readability
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <Heading marginRight={"10px"} bgGradient="linear(to-r, yellow.300, yellow.500, yellow.600)" bgClip={"text"} size="lg">
                  {capitalizeFirstLetter(event.competitionLevel)} Level
                  </Heading>
                  <Heading marginRight={"10px"} size="lg">
                    {event.eventName}
                  </Heading>
                  <Text>{new Date(event.dateTime).toLocaleString()}</Text>
                  
                </CardBody>
                <CardFooter w={"100%"}>
                  <Flex
                  spacing="4"
                  justifyContent={"center"}
                  alignItems={"center"}
                  w={"100%"}
    
                  >
                    <Text textAlign={"center"}>
                    Competition Ended
                  </Text>
                  </Flex>
                </CardFooter>
              </Card>
              ))}
            </SimpleGrid>
            {/* Pagination Controls */}
            <Box m={"auto"} w={{sm:"70%", md:"50%", lg:"30%"}}>
            <Flex m={"auto"} justifyContent={"center"} gap={"10px"}  mt={4}>
            <Button
              onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
              isDisabled={page === 1}
            >
              Previous
            </Button>
            <Text alignSelf="center">Page {page}</Text>
            <Button
              onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
              isDisabled={page === totalPages}
            >
              Next
            </Button>
          </Flex>
          </Box>
              </>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default EventsPage;
