import React from 'react'
import Team from '../Components/Team'

const OurTeam = () => {
  return (
    <div>
        <Team/>
    </div>
  )
}

export default OurTeam