import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  Box, Text, VStack, Spinner, Td, Tr, Tbody, Table, Thead, Th, Button, Heading, Flex, Tabs, TabList, TabPanels, Tab, TabPanel 
} from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { useReactToPrint } from "react-to-print";
import {API_URL} from "../../utils/apiConfig";

const AppratusTab = () => {
  const { eventId, game } = useParams();
  const { user, userSupervisorRole, loading: userLoading } = useAuth();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const componentPDF = useRef();

  useEffect(() => {
    if (!userLoading) {
      if (!user || userSupervisorRole !== 'supervisor') {
        navigate('/supervisor');
        return;
      }

      const fetchEventDetails = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/events/${eventId}`);
          const eventDetails = response.data;
          setEvent(eventDetails);
        } catch (error) {
          console.error('Error fetching event details:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchEventDetails();
    }
  }, [userLoading, user, userSupervisorRole, eventId, navigate]);

  const handleBack = () => {
    navigate(`/supervisor`);
  };

  const handleLiveScore = () => {
    navigate(`/live/${event._id}/${game}`, { state: { fromSupervisorPanel: true } });
  };

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "gymnast-scorelist-gymnazien",
    onAfterPrint: () => alert("Gymnast Scorelist PDF exported successfully")
  });

  const handleRowClick = (playerId) => {
    navigate(`/supervisorpanel/${eventId}/${game}/${playerId}`);
  };

  const handlePrintPage = () => {
    navigate(`/print/${eventId}/${game}`);
  };

  if (loading || userLoading) {
    return <Spinner />;
  }

  if (!user || userSupervisorRole !== 'supervisor') {
    return <Text>You are not authorized to view this page.</Text>;
  }

  return (
    <Box p={4} w={"100%"} minHeight={"100vh"} bg={"#26263c"}>
      <VStack align="start" mt={4}>
        <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-between"}>
          <Button onClick={handleBack} mb={4}>Back</Button>
          <Heading color={"white"} as="h3" mb={4}>Event Sports</Heading>
          <Button onClick={handlePrintPage}>Export in White Sheet</Button>
          <Button onClick={handleLiveScore}>Live Score</Button>
          <Button bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" onClick={generatePDF}>Export to PDF</Button>
        </Flex>

        <Box w={"100%"}>
          <Tabs >
            <TabList w={"45%"} m={"auto"} color={"white"} border={"1px solid #26263c"}>
              {event && event.sports && Object.keys(event.sports).map(sport => (
                event.sports[sport] && <Tab m={"auto"} bgGradient="linear(to-r, yellow.400, yellow.400, yellow.600)" borderRadius={"10px"} fontWeight={"bold"} key={sport}>{sport}</Tab>
              ))}
            </TabList>

            <TabPanels>
              {event && event.sports && Object.keys(event.sports).map(sport => (
                event.sports[sport] && (
                  <TabPanel key={sport}>
                    <Text color={"white"} textAlign={"center"} fontSize="2xl" mb={4}>{sport.charAt(0).toUpperCase() + sport.slice(1)} Result</Text>
                    <div ref={componentPDF} style={{ width: "100%", overflow: "scroll", overflowY: "hidden", overflowX: "revert-layer" }}>
                      {event.players[sport] && event.players[sport].length > 0 ? (
                        <Table color={"black"} variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray">
                          <Thead>
                            <Tr>
                              <Th color={"white"}>SR</Th>
                              <Th color={"white"}>Name</Th>
                              <Th color={"white"}>E Score</Th>
                              <Th color={"white"}>D Score</Th>
                              <Th color={"white"}>Penalty</Th>
                              <Th color={"white"}>Total</Th>
                              <Th color={"white"}>Status</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {event.players[sport].map((player, index) => (
                              <Tr key={index} onClick={() => handleRowClick(player._id)} style={{ cursor: 'pointer' }}>
                                <Td>{index + 1}</Td>
                                <Td>{player.name}</Td>
                                <Td>{player.scores.e}</Td>
                                <Td>{player.scores.d1}</Td>
                                <Td>{player.scores.penalty}</Td>
                                <Td>{player.scores.total}</Td>
                                <Td style={{ color: player.status === 'completed' ? 'green' : player.status === 'playing' ? 'yellow' : 'red', fontWeight: "bold" }}>{player.status}</Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      ) : (
                        <Text>No approved players found for {sport}.</Text>
                      )}
                    </div>
                  </TabPanel>
                )
              ))}
            </TabPanels>
          </Tabs>
        </Box>
      </VStack>
    </Box>
  );
};

export default AppratusTab;
