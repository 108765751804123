import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
// import { Box, Heading } from '@chakra-ui/react';
import {
    Box,
    chakra,
    Container,
    Stack,
    Text,
    Image,
    Flex,
    VStack,
    Button,
    Heading,
    SimpleGrid,
    StackDivider,
    useColorModeValue,
    VisuallyHidden,
    List,
    ListItem,
    HStack,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    useDisclosure
} from '@chakra-ui/react';
import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { MdLocalShipping } from 'react-icons/md';
import {API_URL} from "../utils/apiConfig";

const AdminEventDetails = () => {
    const { eventId } = useParams();
    const [event, setEvent] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { user, userCompetitionAdmin, loading: userLoading } = useAuth();

    useEffect(() => {
        if (!userLoading) {
            if (!user || userCompetitionAdmin !== 'competitionAdmin') {
                // If not a supervisor, redirect to the supervisor dashboard or show a "Not Authorized" page
                navigate('/notaccess');
                return;
            }

            const fetchPlayers = async () => {
                try {
                    const response = await axios.get(`${API_URL}/api/events/${eventId}`);
                    const eventDetails = response.data;
                    if (eventDetails) {
                        setEvent(eventDetails);
                    } else {
                        console.error('Event not found');
                    }
                } catch (error) {
                    console.error('Error fetching players:', error);
                } finally {
                    setLoading(false);
                }
            };
            fetchPlayers();
        }
    }, [userLoading, user, userCompetitionAdmin, eventId, navigate]);

    const handleAppratusPage = ()=> {
        navigate(`/adminn/${eventId}`)
    }

    return (
        <Box>
            <Container maxW={'7xl'}>
                <SimpleGrid
                    columns={{ base: 1, lg: 2 }}
                    spacing={{ base: 8, md: 10 }}
                    py={{ base: 18, md: 24 }}>
                    <Flex>
                        <Image
                            rounded={'md'}
                            alt={'product image'}
                            src={
                                'https://images.unsplash.com/photo-1596516109370-29001ec8ec36?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyODE1MDl8MHwxfGFsbHx8fHx8fHx8fDE2Mzg5MzY2MzE&ixlib=rb-1.2.1&q=80&w=1080'
                            }
                            fit={'cover'}
                            align={'center'}
                            w={'100%'}
                            h={{ base: '100%', sm: '400px', lg: '500px' }}
                        />
                    </Flex>
                    <Stack spacing={{ base: 6, md: 10 }}>
                        <Box as={'header'}>
                            <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                                <Box>
                                    <Heading
                                        lineHeight={1.1}
                                        fontWeight={600}
                                        fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}>
                                        {event.eventName}
                                    </Heading>
                                    <Text
                                        color={useColorModeValue('gray.900', 'gray.400')}
                                        fontWeight={300}
                                        fontSize={'2xl'}>
                                        {new Date(event.dateTime).toLocaleString()}
                                    </Text>
                                </Box>
                                <Flex gap={"10px"}>
                                    <Button>Manage Competition</Button>
                                    <Button>Delete</Button>
                                    <Button onClick={onOpen}>More</Button>
                                </Flex>
                            </Flex>
                        </Box>

                        <Box>
                            <Box >
                                <Box >
                                    <Text
                                        fontSize={{ base: '16px', lg: '18px' }}
                                        color={useColorModeValue('yellow.500', 'yellow.300')}
                                        fontWeight={'500'}
                                        textTransform={'uppercase'}
                                        mb={'4'}>
                                        Category: {event.category === 'women' ? <span>Women</span> : <span>Men</span>}
                                    </Text>
                                    {/* <Text fontWeight={"bold"} marginBottom={"10px"} fontSize="xl">
                                    
                                    </Text> */}
                                    <Box>
                                        <Text
                                            fontSize={{ base: '16px', lg: '18px' }}
                                            color={useColorModeValue('yellow.500', 'yellow.300')}
                                            fontWeight={'500'}
                                            textTransform={'uppercase'}
                                            mb={'4'}>
                                            Apparatus
                                        </Text>
                                    </Box>
                                    {event.category === 'men' && (
                                        <VStack spacing={4}>
                                            {event.sports.floor && (
                                                <HStack justifyContent="space-between" width="100%">
                                                    <Text>Floor</Text>
                                                </HStack>
                                            )}
                                            {event.sports.pommel_horse && (
                                                <HStack justifyContent="space-between" width="100%">
                                                    <Text>Pommel Horse</Text>
                                                </HStack>
                                            )}
                                            {event.sports.rings && (
                                                <HStack justifyContent="space-between" width="100%">
                                                    <Text>Rings</Text>
                                                </HStack>
                                            )}
                                            {event.sports.vaultMen && (
                                                <HStack justifyContent="space-between" width="100%">
                                                    <Text>Vault</Text>
                                                </HStack>
                                            )}
                                            {event.sports.parallel_bars && (
                                                <HStack justifyContent="space-between" width="100%">
                                                    <Text>Parallel Bars</Text>
                                                </HStack>
                                            )}
                                            {event.sports.horizontal_bar && (
                                                <HStack justifyContent="space-between" width="100%">
                                                    <Text>Horizontal Bar</Text>
                                                </HStack>
                                            )}
                                        </VStack>
                                    )}
                                    {event.category === 'women' && (
                                        <VStack spacing={4}>
                                            {event.sports.vaultWomen && (
                                                <HStack justifyContent="space-between" width="100%">
                                                    <Text fontSize={'lg'}>Vault</Text>
                                                </HStack>
                                            )}
                                            {event.sports.uneven_bars && (
                                                <HStack justifyContent="space-between" width="100%">
                                                    <Text fontSize={'lg'}>Uneven Bars</Text>
                                                </HStack>
                                            )}
                                            {event.sports.balance_beam && (
                                                <HStack justifyContent="space-between" width="100%">
                                                    <Text fontSize={'lg'}>Balance Beam</Text>
                                                </HStack>
                                            )}
                                            {event.sports.floor_exercise && (
                                                <HStack justifyContent="space-between" width="100%">
                                                    <Text fontSize={'lg'}>Floor Exercise</Text>
                                                </HStack>
                                            )}
                                        </VStack>
                                    )}
                                </Box>
                            </Box>
                        </Box>


                        <Stack
                            spacing={{ base: 4, sm: 6 }}
                            direction={'column'}
                            divider={
                                <StackDivider
                                    borderColor={useColorModeValue('gray.200', 'gray.600')}
                                />
                            }>
                            <VStack spacing={{ base: 4, sm: 6 }}>
                                <Text
                                    color={useColorModeValue('gray.500', 'gray.400')}
                                    fontSize={'2xl'}
                                    fontWeight={'300'}>
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                                    diam nonumy eirmod tempor invidunt ut labore
                                </Text>
                                <Text fontSize={'lg'}>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad
                                    aliquid amet at delectus doloribus dolorum expedita hic, ipsum
                                    maxime modi nam officiis porro, quae, quisquam quos
                                    reprehenderit velit? Natus, totam.
                                </Text>
                            </VStack>
                            <Box>
                                <Text
                                    fontSize={{ base: '16px', lg: '18px' }}
                                    color={useColorModeValue('yellow.500', 'yellow.300')}
                                    fontWeight={'500'}
                                    textTransform={'uppercase'}
                                    mb={'4'}>
                                    Features
                                </Text>

                                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                                    <List spacing={2}>
                                        <ListItem>Chronograph</ListItem>
                                        <ListItem>Master Chronometer Certified</ListItem>{' '}
                                        <ListItem>Tachymeter</ListItem>
                                    </List>
                                    <List spacing={2}>
                                        <ListItem>Anti‑magnetic</ListItem>
                                        <ListItem>Chronometer</ListItem>
                                        <ListItem>Small seconds</ListItem>
                                    </List>
                                </SimpleGrid>
                            </Box>
                            <Box>
                                <Text
                                    fontSize={{ base: '16px', lg: '18px' }}
                                    color={useColorModeValue('yellow.500', 'yellow.300')}
                                    fontWeight={'500'}
                                    textTransform={'uppercase'}
                                    mb={'4'}>
                                    Product Details
                                </Text>

                                <List spacing={2}>
                                    <ListItem>
                                        <Text as={'span'} fontWeight={'bold'}>
                                            Between lugs:
                                        </Text>{' '}
                                        20 mm
                                    </ListItem>
                                    <ListItem>
                                        <Text as={'span'} fontWeight={'bold'}>
                                            Bracelet:
                                        </Text>{' '}
                                        leather strap
                                    </ListItem>
                                    <ListItem>
                                        <Text as={'span'} fontWeight={'bold'}>
                                            Case:
                                        </Text>{' '}
                                        Steel
                                    </ListItem>
                                    <ListItem>
                                        <Text as={'span'} fontWeight={'bold'}>
                                            Case diameter:
                                        </Text>{' '}
                                        42 mm
                                    </ListItem>
                                    <ListItem>
                                        <Text as={'span'} fontWeight={'bold'}>
                                            Dial color:
                                        </Text>{' '}
                                        Black
                                    </ListItem>
                                    <ListItem>
                                        <Text as={'span'} fontWeight={'bold'}>
                                            Crystal:
                                        </Text>{' '}
                                        Domed, scratch‑resistant sapphire crystal with anti‑reflective
                                        treatment inside
                                    </ListItem>
                                    <ListItem>
                                        <Text as={'span'} fontWeight={'bold'}>
                                            Water resistance:
                                        </Text>{' '}
                                        5 bar (50 metres / 167 feet){' '}
                                    </ListItem>
                                </List>
                            </Box>
                        </Stack>

                        <Button
                            rounded={'none'}
                            w={'full'}
                            mt={8}
                            size={'lg'}
                            py={'7'}
                            bg={useColorModeValue('gray.900', 'gray.50')}
                            color={useColorModeValue('white', 'gray.900')}
                            textTransform={'uppercase'}
                            _hover={{
                                transform: 'translateY(2px)',
                                boxShadow: 'lg',
                            }}>
                            Add to cart
                        </Button>

                        <Stack direction="row" alignItems="center" justifyContent={'center'}>
                            <MdLocalShipping />
                            <Text>2-3 business days delivery</Text>
                        </Stack>
                    </Stack>
                </SimpleGrid>
            </Container>
            <Drawer placement={"right"} onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader borderBottomWidth='1px'>Basic Drawer</DrawerHeader>
                    <DrawerBody>
                        <Button onClick={handleAppratusPage}>Export Result Apparatus</Button>
                        <Button>Export Result Competition</Button>
                        <Button>Participate Certificate</Button>
                        <Button>Scoreing Certificate</Button>
                        <Button>Manage Apparatus Score</Button>
                        <Button>Export Result Team</Button>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Box>
    )
}

export default AdminEventDetails;