import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { Box, Heading } from '@chakra-ui/react'; // Assuming you're using Chakra UI

const GenderPieChart = ({ genderCounts }) => {
  // Define colors for the pie chart
  const COLORS = ['#0088FE', '#FFBB28', '#FF8042'];

  return (
    <Box >
      <Box>
        {genderCounts.map((gender) => (
          <Box textAlign={"center"} fontSize={"18px"} fontWeight={"bold"} key={gender._id}>{gender._id}: {gender.count}</Box>
        ))}
      </Box>
      <PieChart width={340} height={270}>
        <Pie
          data={genderCounts}
          cx={170}
          cy={120}
          labelLine={false}
          label={({ _id }) => _id} // Using gender labels directly
          outerRadius={80}
          fill="#8884d8"
          dataKey="count"
        >
          {genderCounts.map((entry, index) => (
            <Cell key={`cell-${entry._id}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend formatter={(value) => <span>{value}</span>} />
      </PieChart>
      
    </Box>
  );
};

export default GenderPieChart;
