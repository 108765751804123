import React from "react";
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import SignIn from "./pages/SignIn";
// import SignUp from "./pages/SignUp";
import AllRoutes from "./pages/AllRoutes";
import SearchPlayer from "./pages/SearchPlayer";
// import Navbar from "./Components/Navbar";
import LargeWithNewsletter from "./Components/Footer";
// import Navbar from "./Components/Nav";
import WithSubnavigation from "./Components/Nav";
import { BrowserRouter } from "react-router-dom";
import Navbar from "./Components/Navbar";

const App = () => {
  return (
    <div>
    <BrowserRouter>
    {/* <Navbar/> */}
    <Navbar/>
    <AllRoutes/>
    {/* <LargeWithNewsletter/> */}
    {/* <SearchPlayer/> */}
    </BrowserRouter>
    </div>
  );
};

export default App;
