import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React from 'react'
import AppratusTab from './AppratusTab'
import ExportAllAround from './ExportAllAround'
import ExportResult from './CertificatesInfo/ExportResult'
import AllAthletelist from './AllAthletelist'
import ExportCertificates from './ExportCertificates'
import ExportMeritCertificates from './CertificatesMeritAllAround'
import ParticipantCertificate from './ParticipantCertificate'
import AppratuswiseMeritCertificate from './AppratuswiseMeritCertificate'

const ResultTab = () => {
  return (
    <div>
        <Box>
            <Tabs>
            <TabList>
                <Tab>Appratus</Tab>
                <Tab>All around</Tab>
                <Tab>Exports</Tab>
                <Tab>Player List</Tab>
                <Tab>Send Scoring Certificates</Tab>
                <Tab>All Around Merit Certificates</Tab>
                <Tab>Participant Certificate</Tab>
                <Tab>Appratus Merit Certificate</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel><AppratusTab/></TabPanel>
                    <TabPanel><ExportAllAround/></TabPanel>
                    <TabPanel><ExportResult/></TabPanel>
                    <TabPanel><AllAthletelist/></TabPanel>
                    <TabPanel><ExportCertificates/></TabPanel>
                    <TabPanel><ExportMeritCertificates/></TabPanel>
                    <TabPanel><ParticipantCertificate/></TabPanel>
                    <TabPanel><AppratuswiseMeritCertificate/></TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    </div>
  )
}

export default ResultTab