import React from 'react'
import ExportResult from './ExportResult'
import ExportAllAroundCertificates from './ExportAllAroundCertificates'
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import AppratusTab from '../AppratusTab'
import AppratusCertificateTab from './AppratusCertificateTab'

const CertificateList = () => {
  return (
    <div>
        <Box>
            <Tabs>
            <TabList>
                <Tab>Appratus Wise</Tab>
                <Tab>All around</Tab>
                <Tab>participants</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel><AppratusCertificateTab/></TabPanel>
                    <TabPanel><ExportAllAroundCertificates/></TabPanel>
                    <TabPanel><ExportResult/></TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    </div>
  )
}

export default CertificateList