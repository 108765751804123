import {
  ChakraProvider,
  Box,
  VStack,
  HStack,
  useDisclosure,
  Heading,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Text,
  IconButton,
  Flex,
  SimpleGrid,
} from "@chakra-ui/react";
import cardBackground from "../cardBackground.jpg"; // Make sure the path is correct
import { BsThreeDotsVertical } from "react-icons/bs";
import React, { useState, useEffect } from "react";
import axios from "axios";
// import {  } from '@chakra-ui/react';
import ManageEventModal from '../pages/ManageEvent';
import { Link, useNavigate } from "react-router-dom";
import {API_URL} from "../utils/apiConfig";
// import MyEvents from '../pages/MyEvents';

const MyEventsAll = () => {
  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/events-live`);
        const eventArray = response.data;
        eventArray.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
        setEvents(response.data);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const handleManage = (id) => {
    setSelectedEventId(id);
    onOpen();
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/api/events/${id}`);
      setEvents(events.filter((event) => event._id !== id));
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <VStack spacing={4} mt={4}>
      <SimpleGrid columns={[1, 2, 3]} spacing={10}>
        {events.map((event) => (
          <Card
            sx={{
              backgroundImage: `url(${cardBackground})`, // Use the imported variable
              backgroundSize: "cover",
              backgroundPosition: "center",
              color: "white", // Ensures text is readable against background
              borderRadius: "15px",
            }}
            maxW="sm"
            align="center"
            key={event._id}
          >
            <CardHeader
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent background for better readability
                width: "100%",
                textAlign: "center",
                borderRadius: "15px 15px 0 0",
                width: "100%",
              }}
            >
              <Flex
                spacing="4"
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Heading marginRight={"10px"} size="md">
                {event.category === "men"? "MAG":"WAG"}
                </Heading>
                <IconButton
                  variant="ghost"
                  colorScheme="gray"
                  color={"white"}
                  sx={{
                    _hover: {
                      color: "black",
                      backgroundColor: "gray.200",
                      borderRadius: "50%",
                    },
                  }}
                  aria-label="See menu"
                  icon={<BsThreeDotsVertical />}
                />
              </Flex>
            </CardHeader>
            <CardBody
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent background for better readability
                width: "100%",
                textAlign: "center",
              }}
            >
              <Heading marginRight={"10px"} bgGradient="linear(to-r, yellow.300, yellow.500, yellow.600)" bgClip={"text"} size="lg">
                  {capitalizeFirstLetter(event.competitionLevel)} Level
                  </Heading>
              <Heading marginRight={"10px"} size="lg">
                {event.eventName}
              </Heading>
              <Text>{new Date(event.dateTime).toLocaleString()}</Text>
              <Text>For live scoring go to <Link to={"https://www.gymnazien.com/live"}><Text fontSize={"18px"} bgClip={"text"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"> Gymnazien Live Scoring</Text></Link></Text>
            </CardBody>
            <CardFooter w={"100%"}>
              <Flex
              spacing="4"
              // justifyContent={"space-between"}
              alignItems={"center"}
              w={"100%"}

              >
              {/* <Button
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.2)", // Make the button background transparent
                  color: "white", // Button text color
                  border: "1px solid white", // Optional: Add a border to the button
                  _hover: {
                    backgroundColor: "rgba(255, 255, 255, 0.5)", // Change background color on hover
                  },
                  _active: {
                    backgroundColor: "rgba(255, 255, 255, 0.)", // Change background color when active
                  },
                }}
                margin={"auto"}
                colorScheme="blue"
              >
                View here
              </Button> */}
              {/* <Button
                sx={{
                  backgroundColor: "#ffbf00", // Make the button background transparent
                  color: "black", // Button text color
                  _hover: {
                    backgroundColor: "rgba(255, 160, 0)", // Change background color on hover
                    // bgGradient:"linear(to-r, yellow.500, yellow.600, yellow.700)"
                  },
                  _active: {
                    backgroundColor: "rgba(255, 255, 255, 0.)", // Change background color when active
                  },
                }}
                margin={"auto"}
                colorScheme="blue"
                // bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
                onClick={() => handleManage(event._id)}
              >
                Manage Compitition
              </Button> */}
              </Flex>
            </CardFooter>
          </Card>
        ))}
      </SimpleGrid>
      {selectedEventId && (
        <ManageEventModal
          isOpen={isOpen}
          onClose={onClose}
          eventId={selectedEventId}
          fetchEvents={async () => {
            try {
              const response = await axios.get(`${API_URL}/api/events-live`);
              setEvents(response.data);
            } catch (error) {
              console.error('Error fetching events:', error);
            }
          }}
        />
      )}
    </VStack>
    
  );
};

export default MyEventsAll;
