// src/pages/PlayerListPrintPage.js
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Text, VStack, Table, Thead, Tr, Th, Tbody, Td, Spinner, Heading, Button, Flex, Input,Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import axios from 'axios';
import { useReactToPrint } from 'react-to-print';
import { useAuth } from '../context/AuthContext';
import { API_URL } from "../utils/apiConfig";
import { GiCheckMark } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import { IoMdPlay } from "react-icons/io";
import PlayerListPrintPageRanked from './PlayerListPrintPageRankedWise.js';

const PlayerListPrintPage = () => {
  const { eventId, game } = useParams();
  const { user, userSupervisorRole, loading: userLoading } = useAuth();
  const [event, setEvent] = useState(null);
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [customHeading, setCustomHeading] = useState(''); // State for custom heading
  const navigate = useNavigate();
  const componentPDF = useRef();

  useEffect(() => {
    if (!userLoading) {
      if (!user || userSupervisorRole !== 'supervisor') {
        navigate('/supervisor');
        return;
      }

      const fetchPlayers = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/events/${eventId}`);
          const eventDetails = response.data;
          const gamePlayers = eventDetails.players[game];
          if (eventDetails) {
            setEvent(eventDetails);
            setPlayers(gamePlayers);
          } else {
            console.error('Event not found');
          }
        } catch (error) {
          console.error('Error fetching players:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchPlayers();
    }
  }, [userLoading, user, userSupervisorRole, eventId, game, navigate]);

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "gymnast-scorelist-gymnazien",
    onAfterPrint: () => alert("Gymnast Scorelist PDF exported successfully"),
  });

  if (loading || userLoading) {
    return <Spinner />;
  }

  if (!user || userSupervisorRole !== 'supervisor') {
    return <Text>You are not authorized to view this page.</Text>;
  }
  // Sort players by total scores for the ranking tab
  const sortedPlayers = [...players].sort((a, b) => b.scores.total - a.scores.total);


  return (
    <>
      <Flex w={"95%"} flex={1} gap={"10px"} justifyContent={"space-between"} m={"auto"} mt={"20px"}>
        <Button onClick={() => navigate(-1)} mb={4}>Back</Button>
        <Input 
              placeholder="Enter custom heading" 
              value={customHeading} 
              onChange={(e) => setCustomHeading(e.target.value)} 
              mb={4} 
            />
        <Button onClick={generatePDF} mb={4}>Export to PDF</Button>
      </Flex>
      <Tabs isFitted variant='enclosed'>
          <TabList>
            <Tab>Player List</Tab>
            <Tab>Ranked by Total Score</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
      <Box ref={componentPDF} p={4} border={"1px solid black"} w={"97%"} m={"auto"} minHeight={"99vh"} bg={"white"} color={"black"}>
        {event && (
          <Box mb={4} textAlign={"center"}>
            
            <Heading size="xl">{customHeading}</Heading>
            <Heading size="lg">{event.eventName}</Heading>
            <Text>Date: {new Date(event.dateTime).toLocaleString()}</Text>
            <Text>Apparatus: {game.charAt(0).toUpperCase() + game.slice(1)}</Text>
          </Box>
        )}
        <VStack align="start" mt={4}>
          <Box w={"90%"}>
            {players.length > 0 ? (
              <Table variant="simple" border="1px solid black" width={"100%"} margin={"auto"} borderRadius={"20px"}>
                <Thead>
                  <Tr style={{ cursor: 'pointer', border: "1px solid black" }}>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>SR</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>Name</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>D1</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>D2</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>D</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>E1</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>E2</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>E3</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>E4</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>E</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>Penalty</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>Total</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>Status</Th>
                  </Tr>
                </Thead>
                <Tbody style={{ cursor: 'pointer', border: "1px solid black" }}>
                  {players.map((player, index) => (
                    <Tr key={index} style={{ cursor: 'pointer', border: "1px solid black" }}>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{index + 1}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.name}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.scores.d1}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.scores.d1}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.scores.d1}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.scores.judge1 || "N/A"}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.scores.judge2 || "N/A"}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.scores.judge3 || "N/A"}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.scores.judge4 || "N/A"}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.scores.e}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.scores.penalty}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.scores.total}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.status === "completed" ? <GiCheckMark color='green' /> : player.status === "not played" ? <RxCross2 color='red' /> : <IoMdPlay />}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <div>
                <br />
                <Text>No approved players found.</Text>
              </div>
            )}
          </Box>
        </VStack>
      </Box>
      </TabPanel>
      <TabPanel>
      <Box ref={componentPDF} p={4} border={"1px solid black"} w={"97%"} m={"auto"} minHeight={"99vh"} bg={"white"} color={"black"}>
        {event && (
          <Box mb={4} textAlign={"center"}>
            
            <Heading size="xl">{customHeading}</Heading>
            <Heading size="lg">{event.eventName}</Heading>
            <Text>Date: {new Date(event.dateTime).toLocaleString()}</Text>
            <Text>Apparatus: {game.charAt(0).toUpperCase() + game.slice(1)}</Text>
          </Box>
        )}
        <VStack align="start" mt={4}>
          <Box w={"90%"}>
          {sortedPlayers.length > 0 ? (
              <Table variant="simple" border="1px solid black" width={"100%"} margin={"auto"} borderRadius={"20px"}>
                <Thead>
                  <Tr style={{ cursor: 'pointer', border: "1px solid black" }}>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>SR</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>Name</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>D1</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>D2</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>D</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>E1</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>E2</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>E3</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>E4</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>E</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>Penalty</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>Total</Th>
                    <Th style={{ cursor: 'pointer', border: "1px solid black" }}>Status</Th>
                  </Tr>
                </Thead>
                <Tbody style={{ cursor: 'pointer', border: "1px solid black" }}>
                {sortedPlayers.map((player, index) => (
                    <Tr key={index} style={{ cursor: 'pointer', border: "1px solid black" }}>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{index + 1}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.name}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.scores.d1}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.scores.d1}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.scores.d1}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.scores.judge1 || "N/A"}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.scores.judge2 || "N/A"}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.scores.judge3 || "N/A"}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.scores.judge4 || "N/A"}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.scores.e}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.scores.penalty}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.scores.total}</Td>
                      <Td style={{ cursor: 'pointer', border: "1px solid black" }}>{player.status === "completed" ? <GiCheckMark color='green' /> : player.status === "not played" ? <RxCross2 color='red' /> : <IoMdPlay />}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <div>
                <br />
                <Text>No approved players found.</Text>
              </div>
            )}
          </Box>
        </VStack>
      </Box>
      </TabPanel>
      </TabPanels>
      </Tabs>
    </>
  );
};

export default PlayerListPrintPage;
