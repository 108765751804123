// src/Profile.js
import React, { useEffect, useState } from 'react';
import {
    Box, Button, Flex, Text, Heading, Stack, Tabs, TabList, TabPanels, Tab, TabPanel, Avatar, Modal,
    ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel,
    Input, useDisclosure
} from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import {API_URL} from "../../utils/apiConfig";
import { MdVerified } from "react-icons/md";

const AthleteProfile = () => {
    const [athlete, setAthlete] = useState(null);
    const [loading, setLoading] = useState(true);
    const { user, loading: authLoading } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isModalOpen, onOpen: openModal, onClose: closeModal } = useDisclosure();
    const [aadhaarInput, setAadhaarInput] = useState('');
    const [otpInput, setOtpInput] = useState('');
    const [otpSent, setOtpSent] = useState(false); // Tracks if OTP is sent
    const [formState, setFormState] = useState({});
    const [originalState, setOriginalState] = useState({});
    const [isFormDirty, setIsFormDirty] = useState(false);
    //aadhar otp
  const [otpAadhaar, setOtpAadhaar] = useState('');
  const [referenceId, setReferenceId] = useState('');
  const [message, setMessage] = useState('');
  const [data, setData] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isGeneratingOtp, setIsGeneratingOtp] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
  const [timer, setTimer] = useState(120); // 2 minutes timer
  const [aadhaarData, setAadhaarData] = useState({});
  const [isAadhaarVerified, setIsAadhaarVerified] = useState(false);

  const fakephotourl = "";

    useEffect(() => {
        if (user && user.email) {
            const athleteEmail = user.email;
            axios.get(`${API_URL}/api/athlete/athletes/${athleteEmail}`)
                .then(response => {
                    setAthlete(response.data);
                    setFormState(response.data);
                    setOriginalState(response.data); // Save original state
                    setLoading(false);
                })
                .catch(error => {
                    console.error("There was an error fetching the athlete data!", error);
                    setLoading(false);
                });
        } else if (!authLoading) {
            setLoading(false);
            console.error("User not available to fetch athlete data.");
        }
    }, [user, authLoading]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState((prev) => ({
            ...prev,
            [name]: value,
        }));
        checkIfFormIsDirty({ ...formState, [name]: value }); // Check if form is dirty with the new value
    };

    // Function to check if form state is different from the original state
    const checkIfFormIsDirty = (updatedFormState) => {
        const isDirty = Object.keys(updatedFormState).some(key => updatedFormState[key] !== originalState[key]);
        setIsFormDirty(isDirty);
    };

    const handleUpdate = () => {
        if (!isFormDirty) {
            // If the form is not dirty, do nothing
            return;
        }

        axios.put(`${API_URL}/api/athlete/athletes/${athlete.email}`, formState)
            .then(response => {
                setAthlete(response.data); // Update the athlete state with the new data
                setFormState(response.data);
                setOriginalState(response.data); // Reset original state to new updated data
                setIsFormDirty(false); // Reset the form dirty state
                onClose(); // Close the modal
            })
            .catch(error => {
                console.error("There was an error updating the athlete data!", error);
            });
    };

    const handleGenerateOtp = async () => {
        setIsGeneratingOtp(true); // Start loading state for "Generate OTP"
        // const formattedAadhaarNumber = aadhaarNumber.replace(/\s/g, "");
         console.log("formated aadhar number")
    
        try {
          // Request to generate OTP
          const response = await axios.post('http://localhost:7000/generate-otp', {
            aadhaar_number: athlete.aadhaarNumber
          });
    
          // Extract the reference ID from the response and save it
          setReferenceId(response.data.data.reference_id.toString());
          
          setMessage('OTP sent successfully. Please enter the OTP.');
          setIsOtpSent(true);
          setTimer(120); // Reset the timer to 2 minutes
        } catch (error) {
          setMessage('Failed to generate OTP. Please try again.');
          console.error(error);
        } finally {
          setIsGeneratingOtp(false); // Stop loading state for "Generate OTP"
        }
      };
    
      const handleVerifyOtp = async () => {
        setIsVerifyingOtp(true); // Start loading state for "Verify OTP"
        try {
          // Request to verify OTP using the stored reference ID
          const response = await axios.post('http://localhost:7000/verify-otp', {
            reference_id: referenceId,
            otp: otpAadhaar
          });
    
          if (response.data.data.message === 'Aadhaar Card Exists') {
            setData(response.data.data);
            setAadhaarData({
              name: response.data.data.name,
              dateOfBirth: response.data.data.date_of_birth,
              yearOfBirth: response.data.data.year_of_birth,
              photoUrl: response.data.data.photo,
              fullAddress: response.data.data.full_address,
              address: response.data.data.address
            });
            setIsAadhaarVerified(true);
            setMessage('Aadhaar verified successfully.');
            setIsOtpSent(false); // Stop the OTP process
          } else {
            setMessage('Incorrect OTP. Please try again.');
          }
        } catch (error) {
          setMessage('Failed to verify OTP. Please try again.');
          console.error(error);
        } finally {
          setIsVerifyingOtp(false); // Stop loading state for "Verify OTP"
        }
      };

      // Function to format date to dd/mm/yyyy
  const formatDate = (dob) => {
    if (!dob || dob === 'N/A') return 'N/A';
    const date = new Date(dob);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  };

    
      const disableButtons = isGeneratingOtp || isVerifyingOtp || message === 'Aadhaar verified successfully.';

    if (loading) {
        return <Box>Loading...</Box>;
    }

    if (!athlete) {
        return <Box>Failed to load athlete data.</Box>;
    }

    return (
        <Box maxW="800px" mx="auto" p={5}>
            <Flex direction={{ base: 'column', md: 'row' }} align="center" justify="center" mb={8}>
                <Avatar
                    borderRadius="full"
                    boxSize="150px"
                    size='2xl'
                    src={`data:image/jpeg;base64,${athlete.aadhaarData?.photoUrl ? athlete.aadhaarData.photoUrl : fakephotourl}`}
                    bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
                    name={athlete.name}
                    mb={{ base: 4, md: 0 }}
                    mr={{ base: 0, md: 6 }}
                />
                <Box textAlign={{ base: 'center', md: 'left' }}>
                    <Flex flex={1} alignItems={"center"}>
                    <Heading as="h2" bgClip="text" bgGradient="linear(to-r, yellow.500, yellow.600, yellow.700)" size="lg">{athlete.name}</Heading>
                    <Box>{athlete.aadhaarVerified === true ? <MdVerified fontSize={"25px"} style={{color:"#4984fb"}} />: ""}</Box>
                    </Flex>
                    <Heading as="h4" size="md">Athlete Code: {athlete.athleteCode}</Heading>
                    
                    <Text fontSize="sm" color="gray.500">Rankings: {athlete.rankings}</Text>
                    <Button mt={2} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" onClick={onOpen}>Edit Profile</Button>
                </Box>
            </Flex>
            <Tabs>
                <TabList >
                    <Tab fontSize={"20px"} bgClip="text" bgGradient="linear(to-r, yellow.500, yellow.600, yellow.700)" style={{ color: 'black' }}>About</Tab>
                    <Tab fontSize={"20px"} style={{ color: 'black' }}>Timeline</Tab>
                    <Tab fontSize={"20px"} style={{ color: 'black' }}>Compititions</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Stack spacing={3}>
                            <Flex flexWrap={"wrap"} justifyContent={"space-between"}>
                                <Box>
                                    
                                    <Text pb={"10px"} fontSize="lg"><b>Name:</b> {athlete.name}</Text>
                                    <Text pb={"10px"} fontSize="lg"><b>Email:</b> {athlete.email}</Text>
                                    <Text pb={"10px"} fontSize="lg"><b>Phone:</b> {athlete.mobile}</Text>
                                    <Text pb={"10px"} fontSize="lg"><b>Aadhaar No:</b> {athlete.aadhaarNumber}</Text>
                                    <Text pb={"10px"} fontSize="lg">
                                        <b>Aadhaar Verified:</b> {athlete.aadhaarVerified ? "Yes" : "No"}
                                        {!athlete.aadhaarVerified && (
                                            <Button ml={4} size="sm" onClick={openModal}>
                                                Verify Aadhaar
                                            </Button>
                                        )}
                                    </Text>
                                </Box>
                                <Box>
                                    <Text pb={"10px"} fontSize="lg"><b>Club:</b> {athlete.club}</Text>
                                    <Text pb={"10px"} fontSize="lg"><b>City:</b> {athlete.city}</Text>
                                    <Text pb={"10px"} fontSize="lg"><b>State:</b> {athlete.state}</Text>
                                    <Text pb={"10px"} fontSize="lg"><b>Country:</b> {athlete.country}</Text>
                                    <Text pb={"10px"} fontSize="lg"><b>Date of Birth:</b>{formatDate(athlete.dob)}</Text>
                                </Box>

                            </Flex>



                        </Stack>
                    </TabPanel>
                    <TabPanel>
                        <Text>Timeline content...</Text>
                    </TabPanel>
                    <TabPanel>
                        <Text>No verified compititions available</Text>
                    </TabPanel>
                </TabPanels>
            </Tabs>

            {/* Aadhaar Verification Modal */}
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Verify Aadhaar</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl mb={4}>
                            <FormLabel>Aadhaar Number</FormLabel>
                            <Input value={athlete.aadhaarNumber} isDisabled />
                        </FormControl>
                        {!otpSent ? (
                            <Button colorScheme="blue" onClick={handleGenerateOtp}>
                                Generate OTP
                            </Button>
                        ) : (
                            <FormControl mb={4}>
                                <FormLabel>Enter OTP</FormLabel>
                                <Input
                                    value={otpInput}
                                    onChange={(e) => setOtpInput(e.target.value)}
                                    placeholder="Enter the OTP sent to your mobile"
                                />
                            </FormControl>
                        )}
                    </ModalBody>
                    {otpSent && (
                        <ModalFooter>
                            <Button colorScheme="blue" onClick={handleVerifyOtp}>
                                Verify OTP
                            </Button>
                            <Button onClick={closeModal} ml={3}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    )}
                </ModalContent>
            </Modal>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Profile</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={4}>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input
                                    name="name"
                                    value={formState.name + "  (name as per Aadhaar)"|| ''}
                                    onChange={handleInputChange}
                                    disabled
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    name="email"
                                    type="email"
                                    value={formState.email || ''}
                                    onChange={handleInputChange}
                                    disabled // Email should not be editable
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Phone</FormLabel>
                                <Input
                                    name="mobile"
                                    value={formState.mobile || ''}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Club</FormLabel>
                                <Input
                                    name="club"
                                    value={formState.club || ''}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>City</FormLabel>
                                <Input
                                    name="city"
                                    value={formState.city || ''}
                                    onChange={handleInputChange}
                                    disabled
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>State</FormLabel>
                                <Input
                                    name="state"
                                    value={formState.state || ''}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Country</FormLabel>
                                <Input
                                    name="country"
                                    value={formState.country || ''}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme={isFormDirty ? 'blue' : 'gray'}
                            color={isFormDirty ? 'white' : 'gray'}
                            mr={3}
                            onClick={handleUpdate}
                            disabled={!isFormDirty}
                            _disabled={{
                                cursor: 'not-allowed', // Prevent click
                                opacity: 0.5, // Reduce opacity to indicate disabled state
                                bg: 'gray.300', // Change background color to gray
                                color: 'gray.600', // Change text color to gray
                                _hover: { bg: 'gray.300' } // Remove hover effect
                            }}
                        >
                            Update
                        </Button>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default AthleteProfile;
