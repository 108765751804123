// src/App.js
import React, { useState } from 'react';
import { Box, Button, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import CreateCompetition from './CreateCompetition';
import ManageCompetition from './ManageCompetition';

const CompetitionPage = () => {
  return (
    <Box p={5}>
      <Tabs variant="enclosed">
        <TabList>
          <Tab>Create Competition</Tab>
          <Tab>Manage Competition</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <CreateCompetition />
          </TabPanel>
          <TabPanel>
            <ManageCompetition />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default CompetitionPage;
