import React from 'react';
import { Box, SimpleGrid, Icon, Text, Flex } from '@chakra-ui/react';
import { FcAssistant, FcDonate, FcInTransit } from 'react-icons/fc';

const Feature = ({ title, text, icon }) => {
  return (
    <Box w={"80%"} m={"auto"}>
      <Flex
        w={16}
        h={16}
        align={'center'}
        justify={'center'}
        color={'white'}
        rounded={'full'}
        bg={'gray.100'}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={'gray.600'}>{text}</Text>
    </Box>
  );
};

export default function SimpleThreeColumns() {
  return (
    <Box p={4}>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        <Feature
          icon={<Icon as={FcAssistant} w={10} h={10} />}
          title={'LIVE SCORING'}
          text={
            "Provides gymnasts and coaches with instant access to scores fostering transparency and engagement. Live competitions scoring visible on gymnazien"
          }
          
        />
        <Feature
          icon={<Icon as={FcDonate} w={10} h={10} />}
          title={'DIGITAL MARKETING AND BRANDING'}
          text={
            "We help gymnastics clubs, athletes, and related businesses establish a strong digital presence through targeted social media strategies and branding campaigns"
          }
        />
        <Feature
          icon={<Icon as={FcInTransit} w={10} h={10} />}
          title={'CUSTOM SOFTWARE DEVELOPMENT'}
          text={
            "We develop customized software solutions that cater to specific needs within the sports industry, ensuring seamless integration and enhanced functionality."
          }
        />
      </SimpleGrid>
    </Box>
  );
}
