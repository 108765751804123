import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';
import './VisitorsChart.css'; // Import CSS file for styles

// Sample data for visitors over the last 7 days
const data = [
  { day: 'Monday', visitors: 120 },
  { day: 'Tuesday', visitors: 150 },
  { day: 'Wednesday', visitors: 200 },
  { day: 'Thursday', visitors: 180 },
  { day: 'Friday', visitors: 220 },
  { day: 'Saturday', visitors: 300 },
  { day: 'Sunday', visitors: 280 },
];

const VisitorsChart = () => {
  return (
    <div className="chart-container">
      <h2>Visitors Over the Last 7 Days</h2>
      <BarChart
        width={window.innerWidth < 768 ? 300 : 550} // Adjust width for mobile
        height={window.innerWidth < 768 ? 200 : 300} // Adjust height for mobile
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="day" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="visitors" fill="#8884d8" />
      </BarChart>
    </div>
  );
};

export default VisitorsChart;
