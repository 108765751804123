import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Box, Text, VStack, List, ListItem, Spinner, Td, Tr, Tbody, Table, Thead, Th, Button, Heading, Flex } from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import {useReactToPrint} from "react-to-print";
import {API_URL} from "../utils/apiConfig";

const SupervisorPanelMoreDetails = () => {
  const { eventId, game } = useParams();
  const { user, userSupervisorRole, loading: userLoading } = useAuth();
  const [event, setEvent] = useState(null);
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [view, setView] = useState('events');
  const [currentEvent, setCurrentEvent] = useState(null);
  const [currentSport, setCurrentSport] = useState(null);
  const componentPDF = useRef();


  useEffect(() => {
    if (!userLoading) {
      if (!user || userSupervisorRole !== 'supervisor') {
        // If not a supervisor, redirect to the supervisor dashboard or show a "Not Authorized" page
        navigate('/supervisor');
        return;
      }

      const fetchPlayers = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/events/${eventId}`);
          const eventDetails = response.data;
          setEvent(eventDetails);
        } catch (error) {
          console.error('Error fetching players:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchPlayers();
    }
  }, [userLoading, user, userSupervisorRole, eventId, game, navigate]);

  const handleBack = () => {
    // if (view === 'sports') {
    //   setView('events');
    //   setCurrentEvent(null);
    // } else if (view === 'players') {
    //   setView('sports');
    //   setCurrentSport(null);
    // }
    navigate(`/supervisor`);
  };

  const handleLiveScore = () => {
    navigate(`/live/${event._id}/${game}`, { state: { fromSupervisorPanel: true } });
  };

  const generatePDF = useReactToPrint({
    content: ()=> componentPDF.current,
    documentTitle: "gymnast-scorelist-gymnazien",
    onAfterPrint: ()=>alert("Gymnast Scorelist PDF export successfully")
  })

  const handleRowClick = (playerId) => {
    navigate(`/supervisorpanel/${eventId}/${game}/${playerId}`);
  };

  const handlePrintPage = () => {
    navigate(`/print/${eventId}/${game}`);
  };

  if (loading || userLoading) {
    return <Spinner />;
  }

  if (!user || userSupervisorRole !== 'supervisor') {
    return <Text>You are not authorized to view this page.</Text>;
  }

  return (
    <Box p={4} w={"100%"} minHeight={"100vh"}  bg={"#26263c"}>
      
      <VStack align="start" mt={4}>
        
        <Box w={"100%"} >
            <Flex flex={1} flexWrap={"wrap"}  justifyContent={"space-between"}>
        <Button onClick={handleBack} mb={4}>Back</Button>
        <Heading color={"white"} as="h3" mb={4}>{currentSport}</Heading>
        <Button onClick={handlePrintPage}>Export in White Sheet</Button>
        <Button onClick={handleLiveScore}>Live Score</Button>
        <Button bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"  onClick={generatePDF}>Export to PDF</Button>
        </Flex>
        <Box style={{textAlign:"center"}}>
        <Text color={"white"} fontSize="2xl" mb={4}>Player List for {game.charAt(0).toUpperCase() + game.slice(1)}</Text>
        </Box>

        <div ref={componentPDF} style={{width:"100%", overflow:"scroll", overflowY:"hidden", overflowX:"revert-layer"}}>
        {players.length > 0 ? (
          <Table color={"black"} variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray" >
            <Thead>
              <Tr>
                <Th color={"white"}>SR</Th>
                <Th color={"white"}>Name</Th>
                <Th color={"white"}>Email</Th>
                <Th color={"white"}>Mobile</Th>
                <Th color={"white"}>E Score</Th>
                <Th color={"white"}>D Score</Th>
                <Th color={"white"}>Penalty</Th>
                <Th color={"white"}>Total</Th>
                <Th color={"white"}>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {players.map((player, index) => (
                <Tr key={index}
                onClick={() => handleRowClick(player._id)}
                // _hover={{ backgroundColor: 'gray.900' }}
                style={{ cursor: 'pointer' }}>
                    <Td>{index+1}</Td>
                  <Td>{player.name}</Td>
                  <Td>{player.email}</Td>
                  <Td>+{player.mobile}</Td>
                  <Td>{player.scores.e}</Td>
                    <Td>{player.scores.d1}</Td>
                    <Td>{player.scores.penalty}</Td>
                    <Td>{player.scores.total}</Td>
                    <Td style={{ color: player.status === 'completed' ? 'green' : player.status === 'playing' ? 'yellow' : 'red', fontWeight:"bold" }}>{player.status}</Td>
                </Tr> 
              ))}
            </Tbody>
          </Table>
        ) : (
            <div>
            <br/>
          <Text>No approved players found.</Text>
          </div>
        )}
        </div>
      </Box>
      </VStack>
    </Box>
  );
};

export default SupervisorPanelMoreDetails;
