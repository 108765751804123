import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import OtpInput from "otp-input-react";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../firebase.config";
import {API_URL} from "../utils/apiConfig";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import image from "../google.png";

const SignUp = () => {
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            if (showOTP) {
              onOTPVerify();
            } else {
              onSignup();
            }
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  }

  async function onSignupWithEmail() {
    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      setUser(userCredential.user);

      const userData = {
        name: name,
        email: email,
        mobile: ph,
        password: password,
      };

      try {
        const response = await axios.post(
          `${API_URL}/api/auth/signup`,
          userData
        );
        setLoading(false);
        toast.success(response.data.msg);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } catch (error) {
        setLoading(false);
        const errorMsg = error.response
          ? error.response.data.msg
          : error.message;
        toast.error(`${errorMsg}`);
        // Optionally, delete the created user from Firebase to maintain consistency
        await userCredential.user.delete();
      }
    } catch (error) {
      setLoading(false);
      if (error.message === "Firebase: Error (auth/email-already-in-use).") {
        toast.error("Email is already in use, Try to Login");
      } else {
        toast.error(`${error.message}`);
      }
    }
  }

  function onSignup() {
    setLoading(true);
    onCaptchVerify();
    const appVerifier = window.recaptchaVerifier;
    const formatPh = "+" + ph;
    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP sent successfully!");
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Failed to send OTP. Please try again.");
      });
  }

  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        setUser(res.user);
        setLoading(false);
        toast.success("OTP Verification Successful");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Invalid OTP. Please try again.");
      });
  }

  return (
    <Box
      bg={"gray.800"} 
      // className="flex items-center justify-center"
      style={{ backgroundColor: showOTP ? "auto" : "" }}
    >
      <Box w={"90%"} m={"auto"} background="gray.800">
        <Toaster toastOptions={{ duration: 4000 }} />
        <div id="recaptcha-container"></div>
        <br />
        <Box
          // marginLeft={{ sm: "auto", md: "auto", lg: "500px" }}
          // borderRadius={"20px"}
          padding={"10px"}
          w={{ sm: "100%", md: "50%", lg: "50%" }}
          m={"auto"}
          // style={{ border: "1px solid white" }}
          borderRadius="100%"
          boxShadow="md"
          border="1px solid" // Border thickness
          style={{
            borderImageSource:
              "linear-gradient(to right, #f6e05e, #ecc94b, #d69e2e)", // Gradient colors
            borderImageSlice: 1, // Controls how the gradient is sliced into the border
          }}
        >
          <Box w={"100%"}>
            <Heading textAlign={"center"} mt={"10px"} color={"white"}>
              Create account
            </Heading>
            <Flex flexWrap={"wrap"} justifyContent={"space-between"}>
              <Box
                w={{ base: "100%", md: "49%", lg: "49%" }}
                className="flex flex-col gap-4 rounded-lg p-4"
              >
                <FormLabel marginBottom={"-10px"} color={"white"}>
                  Name<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  required
                  bgColor={"white"}
                  placeholder="Enter name"
                  value={name}
                  w={"100%"}
                  onChange={(e) => setName(e.target.value)}
                />
              </Box>
              <Box
                w={{ base: "100%", md: "49%", lg: "49%" }}
                className="flex flex-col gap-4 rounded-lg p-4"
              >
                <FormLabel marginBottom={"-10px"} color={"white"}>
                  Email<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  required
                  bgColor={"white"}
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>
            </Flex>
            <Flex
              flexWrap={"wrap"}
              justifyContent={"space-between"}
              gap={"10px"}
            >
              <Box
                w={{ base: "100%", md: "49%", lg: "49%" }}
                className="flex flex-col gap-4 rounded-lg p-4"
              >
                <FormLabel
                  marginBottom={"-10px"}
                  htmlFor=""
                  className="font-bold text-xl text-white text-center"
                >
                  Enter your mobile number
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <PhoneInput
                  required
                  country={"in"}
                  value={ph}
                  onChange={setPh}
                />
                 {/* <Button
                  colorScheme="gray"
                  color={"white"}
                  variant="outline"
                  onClick={onSignup}
                  className="w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                >
                  {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>Send code via SMS</span>
                </Button> */}
                {/* {showOTP && (
                  <>
                    <label
                      htmlFor="otp"
                      className="font-bold text-xl text-white text-center"
                    >
                      Enter your OTP
                    </label>
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      OTPLength={6}
                      otpType="number"
                      disabled={false}
                      autoFocus
                      className="opt-container "
                    />
                    <Button
                      onClick={onOTPVerify}
                      className="bg-emerald-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                    >
                      {loading && (
                        <CgSpinner size={20} className="mt-1 animate-spin" />
                      )}
                      <span>Verify OTP</span>
                    </Button>
                  </>
                )} */}
              </Box>
              <Box
                w={{ base: "100%", md: "49%", lg: "49%" }}
                className="flex flex-col gap-4 rounded-lg p-4"
              >
                <FormLabel marginBottom={"-10px"} color={"white"}>
                  Password<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  required
                  bgColor={"white"}
                  placeholder="Create Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Input
                  required
                  bgColor={"white"}
                  placeholder="Confirm Password"
                  type="password"
                />
              </Box>
            </Flex>
            <Box className="flex flex-col gap-4 rounded-lg p-4">
              <Checkbox color={"white"} size="md" colorScheme="yellow">
                I have read and agree with 
                <a
                  style={{ textDecoration: "underline", color: "red" }}
                  href="#"
                >
                  Terms of Use
                </a>
                 and 
                <a
                  style={{ textDecoration: "underline", color: "red" }}
                  href="#"
                >
                  Privacy Policy.
                </a>{" "}
                Continue
              </Checkbox>
            </Box>
            <Box className="flex flex-col gap-4 rounded-lg p-4">
              <Button
                w={{ base: "100%", md: "50%", lg: "50%" }}
                m={"auto"}
                bgGradient="linear(to-r, yellow.300, yellow.400, yellow.600)"
                onClick={onSignupWithEmail}
              >
                Signup
              </Button>
              <Text color={"white"} textAlign={"center"}>
                Already have an account?
                <Link
                  style={{ textDecoration: "underline", color:"#ffbc0d"}}    
                  to="/signin"
                >
                  {" "}
                  Login
                </Link>
              </Text>
              <Text textAlign={"center"} color={"white"}>Register as Athlete <a style={{ textDecoration: "underline", color:"#ffbc0d"}} href="/athleteregister"> Athlete Register</a></Text>
              <Button w={{ base: "100%", md: "50%", lg: "50%" }} m={"auto"}>
                <img
                  width={"25px"}
                  style={{ marginRight: "10px" }}
                  src={image}
                  alt="Logo"
                />
                Continue with Google
              </Button>
            </Box>
          </Box>
          <br />
        </Box>
        <br />
        <br />
      </Box>
    </Box>
  );
};

export default SignUp;
