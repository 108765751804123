// SignIn.jsx
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase.config";
import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import image from "../../google.png";

const SignIn = () => {
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSignIn = async () => {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, emailOrMobile, password);
      toast.success("Sign in successful!");
      alert("Login Successful");
      navigate("/admin/superadmin/dashboard");
    } catch (error) {
      if (
        error.message === "Firebase: Error (auth/invalid-login-credentials)."
      ) {
        toast.error("Invalid email or password.");
      } else {
        toast.error(`${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      bg={"#26263c"}
      className="bg-emerald-500 flex items-center justify-center h-screen"
    >
      <div>
        <Toaster toastOptions={{ duration: 4000 }} />
        <Box
          boxShadow="md"
          border="1px solid" // Border thickness
          style={{
            borderImageSource:
              "linear-gradient(to right, #f6e05e, #ecc94b, #d69e2e)", // Gradient colors
            borderImageSlice: 1, // Controls how the gradient is sliced into the border
          }}
          mt={"-70px"}
          className="w-80 flex flex-col gap-4 rounded-lg p-6"
        >
          <Heading textAlign={"center"} color={"white"}>
            Admin Login
          </Heading>
          <>
            <FormLabel marginBottom={"-10px"} color={"white"}>
              Email<span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Input
              placeholder="Enter registered email "
              value={emailOrMobile}
              required
              bg={"white"}
              onChange={(e) => setEmailOrMobile(e.target.value)}
            />
          </>
          <>
            <FormLabel marginBottom={"-10px"} color={"white"}>
              Password<span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Input
              type="password"
              placeholder="Enter password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              // color={"white"}
              bg={"white"}
            />
          </>
          <Button
            bgGradient="linear(to-r, yellow.300, yellow.400, yellow.600)"
            onClick={onSignIn}
          >
            {loading ? "Signing in..." : "Sign In"}
          </Button>
          <Text color={"white"} textAlign={"center"}>
            Don't have an account?{" "}
            <a
              style={{ textDecoration: "underline", color: "#ffbc0d" }}
              href="/admin/superadmin/signup"
            >
              SignUp
            </a>
          </Text>
          {/* <br/> */}
        </Box>
      </div>
    </Box>
  );
};

export default SignIn;
