// MyEvents.js
import React, { useState, useEffect } from 'react';
import { Box, Text, VStack, List, ListItem, Spinner, Heading, SimpleGrid, Card, CardHeader, Flex, IconButton, CardBody, Button, CardFooter } from '@chakra-ui/react';
import axios from 'axios';
import cardBackground from "../cardBackground.jpg";
import { useAuth } from '../context/AuthContext'; // Adjust path as necessary
import { BsThreeDotsVertical } from 'react-icons/bs';
import {API_URL} from "../utils/apiConfig";
import { Link } from 'react-router-dom';

const MyEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user, loading: userLoading, logout } = useAuth();

  useEffect(() => {
    if (!userLoading && user) {
      const fetchEvents = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/events-user`, {
            params: { email: user.email } // Pass the user's email as a query parameter
          });
          setEvents(response.data); // Set the filtered events directly from the response
        } catch (error) {
          console.error('Error fetching events:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchEvents();
    } else if (!userLoading && !user) {
      // Clear events when user is logged out
      setEvents([]);
    }
  }, [user, userLoading]);
  

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  if (userLoading) {
    return (
      <Flex w={"100%"} justifyContent={"center"}>
        <Spinner size="xl" />
      </Flex>
    )
  }

  if (!user) {
    return (
      <Flex w={"100%"} justifyContent={"center"} alignItems={"center"} minHeight={"90vh"}>
        <Heading textAlign={"center"}>You are not logged in. Please log in first.</Heading>
      </Flex>
    );
  }

  if (loading){
    return(
      <Flex w={"100%"} justifyContent={"center"}>
        <Spinner size="xl" />
      </Flex>
    )
  }

  return (
    <Box p={5} minHeight={"90vh"} w={"95%"} m={"auto"} h={"auto"}>
      <Text fontSize="2xl" fontWeight={"bold"} mb={4}>My Competitions</Text>
      <VStack spacing={4} w={"100%"}>
      <SimpleGrid w={"100%"}  columns={[1, 2, 3]} spacing={10}>
        {events.length > 0 ? (
          events.map(event => (
            <Card
        sx={{
          backgroundImage: `url(${cardBackground})`, // Use the imported variable
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: 'white', // Ensures text is readable against background
          borderRadius: '15px'
        }}
        maxW='sm' 
        align='center'
        key={event._id}
      >
        <CardHeader
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background for better readability
            width: '100%',
            textAlign: 'center',
            borderRadius: '15px 15px 0 0',
            width:"100%",
          }}
        >
            <Flex  spacing='4' justifyContent={"space-between"} alignItems={"center"}>
          <Heading marginRight={"10px"} size='md'>{event.category === "men"? "MAG":"WAG"}</Heading>
          <IconButton
        variant='ghost'
        colorScheme='gray'
        color={"white"}
        sx={{
            _hover: {
                color:"black",
                backgroundColor:"gray.200",
                borderRadius:"50%"
            }}
        }
        aria-label='See menu'
        icon={<BsThreeDotsVertical />}
      />
      </Flex>
        </CardHeader>
        <CardBody
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background for better readability
            width: '100%',
            textAlign: 'center'
          }}
        >
            <Box key={event._id} p={5} shadow="md">
            <Heading marginRight={"10px"} bgGradient="linear(to-r, yellow.300, yellow.500, yellow.600)" bgClip={"text"} size="lg">
                  {capitalizeFirstLetter(event.competitionLevel)} Level
                  </Heading>
            <Heading marginRight={"10px"} size='lg'>{event.eventName}</Heading>
              <Text mt={2}>Category: {event.category}</Text>
              <Text>{new Date(event.dateTime).toLocaleString()}</Text>
              <Text>For live scoring go to <Link to={"https://www.gymnazien.com/live"}><Text fontSize={"18px"} bgClip={"text"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"> Gymnazien Live Scoring</Text></Link></Text>
              {/* <List mt={4} spacing={2}>
                <ListItem>As Player:</ListItem>
                {Object.keys(event.players).map(game => (
                  <Box key={game} pl={4}>
                    <Text fontWeight="bold">{game}:</Text>
                    {event.players[game].map(player => (
                      <Text key={player.mobile} pl={4}>
                        {player.name} ({player.mobile})
                      </Text>
                    ))}
                  </Box>
                ))}
              </List> */}
            </Box>
            </CardBody>
            <CardFooter
          
        >
          {/* <Button

sx={{
    backgroundColor: 'rgba(255, 255, 255, 0.2)', // Make the button background transparent
    color: 'white', // Button text color
    border: '1px solid white', // Optional: Add a border to the button
    _hover: {
      backgroundColor: 'rgba(255, 255, 255, 0.5)', // Change background color on hover
    },
    _active: {
      backgroundColor: 'rgba(255, 255, 255, 0.)', // Change background color when active
    }
  }}
          
          
          margin={"auto"}  colorScheme='blue'>View here</Button> */}
        </CardFooter>
            </Card>
          ))
        ) : (
          <Flex minHeight={"70vh"} w={{base:"100%", md:"100", lg:"320%"}} justifyContent={"center"} alignItems={"center"}>
          <Heading textAlign={"center"}>No competitions found.</Heading>
          </Flex>
        )}
        </SimpleGrid>
      </VStack>
    </Box>
  );
};

export default MyEvents;
