
import React from "react";
import { Box, Heading, Text, Image } from "@chakra-ui/react";
import logo from "../logo/gymglogo.png"

export const About = () => {
  return (
    <Box 
      padding="4" 
      maxW="1200px" 
      margin="auto" 
      textAlign="center"
      bgColor="gray.50"
      minHeight="100vh"
    >
      <Heading as="h1" size="xl" mb="6">
        About Gymnazien Tech
      </Heading>
      <Box 
        display="flex" 
        flexDirection={["column", "column", "row"]} 
        alignItems="center"
        justifyContent="space-between"
        marginBottom="8"
        paddingX="4"
      >
        <Image 
          src={logo} 
          alt="Gymnazien Logo" 
          boxSize={["100px", "200px", "300px"]} 
          marginBottom={["4", "4", "0"]} 
        />
        <Box textAlign={["center", "center", "left"]} maxWidth="600px">
          <Text fontSize={["md", "lg", "xl"]} mb="4">
            Gymnazien Tech is a forward-thinking tech company providing innovative digital solutions for the gymnastics industry. Our flagship product, Gymnazien, is a comprehensive scoring software used by gym owners, associations, and competition organizers.
          </Text>
          <Text fontSize={["sm", "md", "lg"]}>
            With over 10k followers and a growing user base, Gymnazien is quickly becoming an essential tool for the gymnastics community.
          </Text>
        </Box>
      </Box>
      <Box>
        <br />
      <Heading as="h1" size="xl" mb="6">
        Our Vision
      </Heading>
      <Box textAlign={["center", "center", "center"]} >
          <Text fontSize={["md", "lg", "xl"]} fontWeight={"bold"} mb="4">
          To revolutionize the gymnastics ecosystem globally by providing state-of-the-art digital solutions that enhance the experience for athletes, coaches, judges, and organizers, making gymnastics accessible, transparent, and efficient.
          </Text>
        </Box>

      </Box>
      <Box>
        <br />
      <Heading as="h1" size="xl" mb="6">
        Our Mission
      </Heading>
      <Box textAlign={["center", "center", "center"]} >
          <Text fontSize={["md", "lg", "xl"]} fontWeight={"bold"} mb="4">
          Our mission is to empower the gymnastics community through innovative technology solutions. We aim to streamline competition management, enhance scoring accuracy, and provide platforms for digital marketing and branding, all while fostering a community of athletes and organizations that can thrive in a modern, tech-enabled environment.
          </Text>
        </Box>

      </Box>
    </Box>
  );
};