import React, { useEffect, useState } from 'react';
import { Box, Button, Heading, List, ListItem, Text } from '@chakra-ui/react';
import axios from 'axios';
import {API_URL} from "../../utils/apiConfig";

const AdminList = () => {
    const [admins, setAdmins] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAdmins = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/admin/superadmin/admins`);
                setAdmins(response.data);
            } catch (error) {
                console.error('Error fetching admins:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAdmins();
    }, []);

    const handleDelete = async (uid, email) => {
        try {
            await axios.delete(`${API_URL}/api/admin/superadmin/admins/${uid}`, { data: { email } });
            setAdmins(admins.filter(admin => admin.uid !== uid));
        } catch (error) {
            console.error('Error deleting admin:', error);
        }
    };

    return (
        <Box p={4} w="400px" m="20px auto">
            <Heading mb={6}>Admin List</Heading>
            {loading ? (
                <Text>Loading...</Text>
            ) : (
                <List spacing={3}>
                    {admins.map(admin => (
                        <ListItem key={admin.uid} display="flex" justifyContent="space-between" alignItems="center">
                            <Text>{admin.displayName} ({admin.email})</Text>
                            <Button colorScheme="red" onClick={() => handleDelete(admin.uid, admin.email)}>Delete</Button>
                        </ListItem>
                    ))}
                </List>
            )}
        </Box>
    );
};

export default AdminList;
