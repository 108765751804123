import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Grid, GridItem, Heading, SimpleGrid, Text } from '@chakra-ui/react'
import axios from "axios";
import { useNavigate } from "react-router-dom";

const DashboardGrid = () => {
    const [athletes, setAthletes] = useState([]);
    const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const athletesPerPage = 10;
  const fakephotourl = "";
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
    useEffect(() => {
        const fetchAthletes = async () => {
          setLoading(true);
          try {
            const response = await axios.get(`https://aniketrasal.online/api/athlete/athletes/all?page=${currentPage}&limit=${athletesPerPage}`, {
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            });
            setAthletes(response.data); // Assuming this is an array
            setLoading(false);
          } catch (error) {
            console.error('Error fetching athletes:', error);
            setLoading(false);
          }
        };
    
        fetchAthletes();
      }, [currentPage]);

      const handleClickAthleteList = () => {
        navigate(`/admin/superadmin/dashboard/athlete/athleteslist`)
      }

      const handleClickUsersList = () => {
        navigate(`/admin/superadmin/dashboard/users/userslist`)
      }

      useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`https://aniketrasal.online/api/auth/users?page=${currentPage}&limit=${athletesPerPage}`);
        setUsers(response.data); // Assuming this is an array
        setLoading(false);
      } catch (error) {
        console.error('Error fetching athletes:', error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

      const totalAthletes = athletes.length; // Count total athletes based on array length
      const totalUsers = users.length;
      console.log("total athletes", totalAthletes);


  return (
    <div style={{width:"90%", margin:"auto"}} >
      <SimpleGrid spacing={10} templateColumns='repeat(auto-fill, minmax(150px, 1fr))'>
  <Card margin={"auto"}>
      <Heading margin={"auto"} size='md'>{totalAthletes}</Heading>
      <Text margin={"auto"}>Total Athletes</Text>
      <Button onClick={handleClickAthleteList} margin={"auto"} marginTop={"10px"} width={"100%"}padding={"10px 30px"} color={"blue"}>View more</Button>
  </Card>
  <Card margin={"auto"}>
      <Heading margin={"auto"} size='md'>{totalUsers}</Heading>
      <Text margin={"auto"}>Total users</Text>
      <Button onClick={handleClickUsersList} margin={"auto"} marginTop={"10px"} width={"100%"}padding={"10px 40px"} color={"blue"}>View more</Button>
  </Card>
  <Card margin={"auto"}>
      <Heading margin={"auto"} size='md'>57</Heading>
      <Text margin={"auto"}>New users</Text>
      <Button margin={"auto"} marginTop={"10px"} width={"100%"}padding={"10px 30px"} color={"blue"}>View more</Button>
  </Card>
  <Card margin={"auto"}>
      <Heading margin={"auto"} size='md'>1520</Heading>
      <Text margin={"auto"}>Unique visitors</Text>
      <Button margin={"auto"} marginTop={"10px"} width={"100%"}padding={"10px 30px"} color={"blue"}>View more</Button>
  </Card>
  <Card margin={"auto"}>
      <Heading margin={"auto"} size='md'>14</Heading>
      <Text margin={"auto"}>New Requests</Text>
      <Button margin={"auto"} marginTop={"10px"} width={"100%"}padding={"10px 30px"} color={"blue"}>View more</Button>
  </Card>
</SimpleGrid>
<br />
<br />
<SimpleGrid spacing={30} templateColumns='repeat(auto-fill, minmax(250px, 1fr))'>
  <Card margin={"auto"} color={"white"} bg={"blue.500"} padding={"10px 0px 0px 0px"}>
      <Heading margin={"auto"} color={"white"} size='md'>1,00,000 Rs</Heading>
      <Text margin={"auto"}>Revenue</Text>
      <Button margin={"auto"} marginTop={"10px"} width={"100%"}padding={"20px 80px"} color={"blue"}>View more</Button>
  </Card>
  <Card margin={"auto"} color={"white"} bg={"green.500"} padding={"10px 0px 0px 0px"}>
      <Heading margin={"auto"} size='md'>75,000 Rs</Heading>
      <Text margin={"auto"}>Profit</Text>
      <Button margin={"auto"} marginTop={"10px"} width={"100%"}padding={"20px 80px"} color={"blue"}>View more</Button>
  </Card>
  <Card margin={"auto"} color={"white"} bg={"red.500"} padding={"10px 0px 0px 0px"}>
      <Heading margin={"auto"} size='md'>0 Rs</Heading>
      <Text margin={"auto"}>Loss</Text>
      <Button margin={"auto"} marginTop={"10px"} width={"100%"}padding={"20px 80px"} color={"blue"}>View more</Button>
  </Card>
</SimpleGrid>
    </div>
  );
};

export default DashboardGrid;
