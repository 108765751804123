import React, { useEffect, useState } from "react";
import {
  Box,
  Image,
  Text,
  Spinner,
  Flex,
  Heading,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  ModalFooter,
  useDisclosure
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../utils/apiConfig";
import {
  differenceInYears,
  differenceInMonths,
  differenceInDays,
} from "date-fns";
import DatePicker from 'react-datepicker';

import cardBackground from "../../Images/gymnastics-background.jpg"; // Make sure the path is correct
import maleathleteimg from "../../Images/Screenshot (2).png";
import femaleathleteimg from "../../Images/Screenshot (1).png";
import indflag from "../../Images/Flag-India.webp";

const DetailsAthlete = () => {
  const { id } = useParams();
  const [athlete, setAthlete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [age, setAge] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isModalOpen, onOpen: openModal, onClose: closeModal } = useDisclosure();
  const [formState, setFormState] = useState({});
    const [originalState, setOriginalState] = useState({});
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const fetchAthlete = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${API_URL}/api/athlete/athletes/${id}`
        );
        setAthlete(response.data);
        setFormState(response.data);
        setOriginalState(response.data); // Save original state
        setLoading(false);
      } catch (error) {
        console.error("Error fetching athlete details:", error);
        setLoading(false);
      }
    };

    fetchAthlete();
  }, [id]);

  // Function to format date to dd/mm/yyyy
  const formatDate = (dob) => {
    if (!dob || dob === "N/A") return "N/A";
    const date = new Date(dob);
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };

  const calculateAge = (dob) => {
    const now = new Date();
    const years = differenceInYears(now, dob);
    const months = differenceInMonths(now, dob) % 12;
    const days = differenceInDays(now, dob) % 30; // Approximate days, for simplicity

    return `${years} yrs, ${months} months, ${days} days`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
        ...prev,
        [name]: value,
    }));
    checkIfFormIsDirty({ ...formState, [name]: value }); // Check if form is dirty with the new value
};

// Function to check if form state is different from the original state
const checkIfFormIsDirty = (updatedFormState) => {
    const isDirty = Object.keys(updatedFormState).some(key => updatedFormState[key] !== originalState[key]);
    setIsFormDirty(isDirty);
};

const handleUpdate = () => {
    if (!isFormDirty) {
        // If the form is not dirty, do nothing
        return;
    }

    axios.put(`${API_URL}/api/athlete/athletes/${athlete.email}`, formState)
        .then(response => {
            setAthlete(response.data); // Update the athlete state with the new data
            setFormState(response.data);
            setOriginalState(response.data); // Reset original state to new updated data
            setIsFormDirty(false); // Reset the form dirty state
            onClose(); // Close the modal
        })
        .catch(error => {
            console.error("There was an error updating the athlete data!", error);
        });
};

  if (loading) {
    return <Spinner size="xl" />;
  }

  if (!athlete) {
    return <Text>No athlete found.</Text>;
  }

  return (
    // <Box>
    //   <Image
    //     src={athlete.aadhaarData? `data:image/jpeg;base64,${athlete.aadhaarData.photoUrl}`:""}
    //     alt={athlete.name? athlete.name : ""}
    //     boxSize="150px"
    //     borderRadius="full"
    //     mb={4}
    //   />
    //   <Text fontSize="2xl">{athlete.name? athlete.name : ""}</Text>
    //   <Text>Athlete Code: {athlete.athleteCode? athlete.athleteCode: ""}</Text>
    //   <Text>Email: {athlete.email}</Text>
    //   <Text>Mobile: {athlete.mobile || 'N/A'}</Text>
    //   <Text>Date of Birth: { athlete.dob? new Date(athlete.dob.$date).toLocaleDateString():""}</Text>
    //   <Text>Gender: {athlete.gender}</Text>
    //   <Text>Club: {athlete.club}</Text>
    //   <Text>City: {athlete.city}</Text>
    //   <Text>District: {athlete.district}</Text>
    //   <Text>State: {athlete.state}</Text>
    //   <Text>Country: {athlete.country}</Text>
    // </Box>
    <Box
      p={4}
      minHeight={"90vh"}
      sx={{
        // backgroundImage: `url(${cardBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "white",
      }}
    >
      <br />
      <Box
        border={"10px solid gray"}
        w={{ sm: "100%", md: "80%", lg: "80%" }}
        m={"auto"}
        bg={"black"}
      >
        <br />
        <Flex
          flex={1}
          flexWrap={"wrap-reverse"}
          justifyContent={"space-evenly"}
          alignItems={"center"}
        >
          <Box>
            <Box color={"gray.200"} textAlign={"center"}>
              <Box >
                {athlete.country && athlete.country === "India" && (
                  <Box >
                    <Image src={indflag} m={"auto"} w={{ base: "50px", md: "100px", lg: "100px" }} />
                  </Box>
                )}
              </Box>
              <Heading
                fontSize={{ base: "xl", md: "3xl", lg: "3xl" }}
                color={"#ffbf00"}
              >
                {athlete.name ? athlete.name : ""}
              </Heading>
              <Heading fontSize={{ base: "xl", md: "3xl", lg: "3xl" }}>
                #{athlete.athleteCode ? athlete.athleteCode : ""}
              </Heading>
              <Button mt={2} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" onClick={onOpen}>Edit Profile</Button>
                
            </Box>
            <br />
            <Divider color={"yellow"} />
            <br />
            <Flex
              flex={1}
              justifyContent={{ base: "left", md: "left", lg: "space-evenly" }}
              flexWrap={"wrap"}
              paddingLeft={{ base: "20px", md: "0px", lg: "0px" }}
              gap={"50px"}
              color={"gray.300"}
            >
              <Box>
                <Heading
                  lineHeight={"40px"}
                  fontSize={{ base: "md", md: "xl", lg: "xl" }}
                >
                  <span style={{ color: "#ffbf00", marginRight: "5px" }}>
                    AGE:
                  </span>
                  {calculateAge(athlete.dob)}
                </Heading>
                <Heading
                  lineHeight={"40px"}
                  fontSize={{ base: "md", md: "xl", lg: "xl" }}
                >
                  <span style={{ color: "#ffbf00", marginRight: "5px" }}>
                    MOBILE:
                  </span>
                  {athlete.mobile || "N/A"}
                </Heading>
                <Heading
                  lineHeight={"40px"}
                  fontSize={{ base: "md", md: "xl", lg: "xl" }}
                >
                  <span style={{ color: "#ffbf00", marginRight: "5px" }}>
                    DATE OF BIRTH:
                  </span>
                  {formatDate(athlete.dob)}
                </Heading>
                <Heading
                  lineHeight={"40px"}
                  fontSize={{ base: "md", md: "xl", lg: "xl" }}
                >
                  <span style={{ color: "#ffbf00", marginRight: "5px" }}>
                    GENDER:
                  </span>
                  {athlete.gender}
                </Heading>
                <Heading
                  lineHeight={"40px"}
                  fontSize={{ base: "md", md: "xl", lg: "xl" }}
                >
                  <span style={{ color: "#ffbf00", marginRight: "5px" }}>
                    AADHAAR VERIFIED:
                  </span>
                  {athlete.aadhaarVerified ? "Yes" : "No"}
                </Heading>
              </Box>
              <Box>
                <Heading
                  lineHeight={"40px"}
                  fontSize={{ base: "md", md: "xl", lg: "xl" }}
                >
                  <span style={{ color: "#ffbf00", marginRight: "5px" }}>
                    CLUB:
                  </span>
                  {athlete.club}
                </Heading>
                <Heading
                  lineHeight={"40px"}
                  fontSize={{ base: "md", md: "xl", lg: "xl" }}
                >
                  <span style={{ color: "#ffbf00", marginRight: "5px" }}>
                    CITY:
                  </span>
                  {athlete.city}
                </Heading>
                <Heading
                  lineHeight={"40px"}
                  fontSize={{ base: "md", md: "xl", lg: "xl" }}
                >
                  <span style={{ color: "#ffbf00", marginRight: "5px" }}>
                    DISTRICT:
                  </span>
                  {athlete.district}
                </Heading>
                <Heading
                  lineHeight={"40px"}
                  fontSize={{ base: "md", md: "xl", lg: "xl" }}
                >
                  <span style={{ color: "#ffbf00", marginRight: "5px" }}>
                    STATE:
                  </span>
                  {athlete.state}
                </Heading>
                <Heading
                  lineHeight={"40px"}
                  fontSize={{ base: "md", md: "xl", lg: "xl" }}
                >
                  <span style={{ color: "#ffbf00", marginRight: "5px" }}>
                    COUNTRY:
                  </span>
                  {athlete.country}
                </Heading>
              </Box>
            </Flex>
          </Box>
          <Box textAlign={"center"}>
            <Image
              src={
                athlete.aadhaarData
                  ? `data:image/jpeg;base64,${athlete.aadhaarData.photoUrl}`
                  : athlete.gender === "Male"
                  ? maleathleteimg
                  : athlete.gender === "Female"
                  ? femaleathleteimg
                  : undefined // Fallback in case gender is neither Male nor Female
              }
              alt={athlete.name ? athlete.name : ""}
              boxSize={{ base: "240px", md: "350px", lg: "350px" }}
              border={"7px solid white"}
              mb={4}
            />
            <Heading
              color={"gray.300"}
              fontSize={{ base: "md", md: "xl", lg: "xl" }}
            >
              {athlete.email}
            </Heading>
          </Box>
        </Flex>
        <br />
      </Box>
      <br />
      <Modal isOpen={isOpen} onClose={onClose} >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Profile</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={4}>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input
                                    name="name"
                                    value={formState.name}
                                    onChange={handleInputChange}
                                    // disabled
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    name="email"
                                    type="email"
                                    value={formState.email || ''}
                                    onChange={handleInputChange}
                                    disabled // Email should not be editable
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Phone</FormLabel>
                                <Input
                                    name="mobile"
                                    value={formState.mobile || ''}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Date of Birth</FormLabel>
                                <Input
                                    name="dob"
                                    type="date"
                                    value={formState.dob ? formState.dob.split('T')[0] : ''}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Pincode</FormLabel>
                                <Input
                                    name="pincode"
                                    value={formState.pincode || ''}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Club</FormLabel>
                                <Input
                                    name="club"
                                    value={formState.club || ''}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>City</FormLabel>
                                <Input
                                    name="city"
                                    value={formState.city || ''}
                                    onChange={handleInputChange}
                                    // disabled
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>District</FormLabel>
                                <Input
                                    name="district"
                                    value={formState.district || ''}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>State</FormLabel>
                                <Input
                                    name="state"
                                    value={formState.state || ''}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Country</FormLabel>
                                <Input
                                    name="country"
                                    value={formState.country || ''}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Aadhaar Number</FormLabel>
                                <Input
                                    name="aadhaarNumber"
                                    value={formState.aadhaarNumber || ''}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme={isFormDirty ? 'blue' : 'gray'}
                            color={isFormDirty ? 'white' : 'gray'}
                            mr={3}
                            onClick={handleUpdate}
                            disabled={!isFormDirty}
                            _disabled={{
                                cursor: 'not-allowed', // Prevent click
                                opacity: 0.5, // Reduce opacity to indicate disabled state
                                bg: 'gray.300', // Change background color to gray
                                color: 'gray.600', // Change text color to gray
                                _hover: { bg: 'gray.300' } // Remove hover effect
                            }}
                        >
                            Update
                        </Button>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
    </Box>
  );
};

export default DetailsAthlete;
