import React, { useState, useEffect } from 'react';
import "react-phone-input-2/lib/style.css";
import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button,
  VStack, HStack, Input, Text, Box, List, ListItem, useDisclosure, Flex, Heading,
  Avatar, Tabs, TabList, Tab, TabPanels, TabPanel
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { AiOutlineUserAdd } from "react-icons/ai";
import {API_URL} from "../../utils/apiConfig";

const ManageEventModal = ({ isOpen, onClose, eventId, fetchEvents }) => {
  const [step, setStep] = useState(1);
  const [eventName, setEventName] = useState('');
  const [category, setCategory] = useState('');
  const [switches, setSwitches] = useState({
    floor: false,
    pommel_horse: false,
    rings: false,
    vaultMen: false,
    parallel_bars: false,
    horizontal_bar: false,
    vaultWomen: false,
    uneven_bars: false,
    balance_beam: false,
    floor_exercise: false,
  });
  const [teamFormat, setTeamFormat] = useState({ input1: '', input2: '', result: '' });
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [result, setResult] = useState('');
  const [players, setPlayers] = useState({});
  const [playerName, setPlayerName] = useState('');
  const [playerMobile, setPlayerMobile] = useState('');
  const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const { isOpen: isConfirmModalOpen, onOpen: onConfirmModalOpen, onClose: onConfirmModalClose } = useDisclosure();
  const [teams, setTeams] = useState({});
  const [newTeamName, setNewTeamName] = useState('');
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [currentGymnastSlot, setCurrentGymnastSlot] = useState(null);
  const [assignedGymnasts, setAssignedGymnasts] = useState([]);
  const { isOpen: isTeamModalOpen, onOpen: openTeamModal, onClose: closeTeamModal } = useDisclosure();
  const { isOpen: isAssignGymnastModalOpen, onOpen: openAssignGymnastModal, onClose: closeAssignGymnastModal } = useDisclosure();
  const [competitionLevel, setCompetitionLevel] = useState('');

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/events/${eventId}`);
        const event = response.data;
        setEventName(event.eventName);
        setCategory(event.category);
        setSwitches(event.sports);
        setTeamFormat({ input1: event.teamFormat.input1, input2: event.teamFormat.input2, result: event.teamFormat.result });
        setInput1(event.teamFormat.input1);
        setInput2(event.teamFormat.input2);
        setResult(event.teamFormat.result);
        setPlayers(event.players || {});
        setTeams(event.teams || {});
        setCompetitionLevel(event.competitionLevel || "");

        let assigned = [];
        Object.values(event.teams || {}).forEach(team => {
          assigned = assigned.concat(team.gymnasts || []);
        });

        setAssignedGymnasts(assigned);

      } catch (error) {
        console.error('Error fetching event:', error);
      }
    };

    if (eventId) {
      fetchEvent();
    }
  }, [eventId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const eventData = {
      eventName,
      category,
      sports: switches,
      teamFormat: { input1, input2, result },
      players,
      teams,
    };

    Object.keys(teams).forEach((teamName) => {
      teams[teamName].gymnasts.forEach((gymnast, index) => {
        if (gymnast) {
          const sport = "general"; // Adjust this based on your sport mapping logic
          if (!eventData.players[sport]) {
            eventData.players[sport] = [];
          }
          const existingPlayer = eventData.players[sport].find(p => p.mobile === gymnast.mobile);
          if (!existingPlayer) {
            eventData.players[sport].push({
              ...gymnast,
              teamName: teamName
            });
          }
        }
      });
    });

    try {
      await axios.put(`${API_URL}/api/events/${eventId}`, eventData);
      fetchEvents();
      onClose();
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  const handleAddPlayer = async () => {
    const newPlayer = { name: playerName, mobile: playerMobile };

    const updatedPlayers = { ...players };
    let isDuplicate = false;
    for (const game in switches) {
      if (switches[game]) {
        if (!updatedPlayers[game]) {
          updatedPlayers[game] = [];
        }
        const duplicatePlayer = updatedPlayers[game].find(
          player => player.mobile === newPlayer.mobile || (player.name === newPlayer.name && player.mobile === newPlayer.mobile)
        );
        if (duplicatePlayer) {
          isDuplicate = true;
          break;
        }
      }
    }

    if (isDuplicate) {
      alert("Duplicate Player")
    } else {
      for (const game in switches) {
        if (switches[game]) {
          updatedPlayers[game].push(newPlayer);
        }
      }

      setPlayers(updatedPlayers);
      setPlayerName('');
      setPlayerMobile('');
      setIsPlayerModalOpen(false);
      onConfirmModalClose();
      alert("Player added successfully.")
      try {
        await axios.patch(`${API_URL}/api/events/${eventId}/players`, updatedPlayers);
      } catch (error) {
        console.error('Error updating players:', error);
      }
    }
  };

  const handleSearchPlayer = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length > 2) {
      try {
        console.log(`Searching for: ${query}`);
        const response = await axios.get(`${API_URL}/api/athlete/athletes?search=${query}`);
        const athletes = response.data;
        console.log('Search results:', athletes);

        if (athletes.length > 0) {
          setSearchResults(athletes);
        } else {
          setSearchResults([{ id: 'no_result', name: 'No athlete found', email: '' }]);
        }
      } catch (error) {
        console.error('Error searching players:', error);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleSelectPlayer = (player) => {
    setSelectedPlayer(player);
    onConfirmModalOpen();
};

const handleConfirmAddPlayer = async () => {
    if (selectedPlayer) {
        const updatedPlayers = { ...players };
        let isDuplicate = false;

        // Check for duplicates in the current sports switches
        for (const game in switches) {
            if (switches[game]) {
                if (!updatedPlayers[game]) {
                    updatedPlayers[game] = [];
                }
                const gamePlayers = updatedPlayers[game];
                const existingPlayer = gamePlayers.find((p) => p.mobile === selectedPlayer.mobile);
                if (existingPlayer) {
                    isDuplicate = true;
                    break;
                }
            }
        }

        if (isDuplicate) {
            alert('This player is already added. Please choose another one.');
            return;
        }

      //    // Check if player's city matches the team name
      //    if (selectedTeam && selectedPlayer.city !== selectedTeam) {
      //     alert(`This player's city (${selectedPlayer.city}) does not match the team name (${selectedTeam}). Player cannot be added.`);
      //     return;
      // }

      // Check if the team name matches based on competition level
      // const competitionLevel = event.competitionLevel; // Assuming this is available in your component

      // let isCityMatch = false;
      // if (competitionLevel === 'district' && selectedTeam === selectedPlayer.club) {
      //     isCityMatch = true;
      // } else if (competitionLevel === 'state' && selectedTeam === selectedPlayer.city) {
      //     isCityMatch = true;
      // } else if (competitionLevel === 'national' && selectedTeam === selectedPlayer.state) {
      //     isCityMatch = true;
      // }

      // if (!isCityMatch) {
      //     alert(`This player's city (${selectedPlayer.city}) does not match the team name (${selectedTeam}).`);
      //     return;
      // }

      let isCityMatch = false;
      let mismatchMessage = '';

      if (competitionLevel === 'district') {
          if (selectedTeam === selectedPlayer.club) {
              isCityMatch = true;
          } else {
              mismatchMessage = `This player's club (${selectedPlayer.club}) does not match the team name (${selectedTeam}).`;
          }
      } else if (competitionLevel === 'state') {
          if (selectedTeam === selectedPlayer.district) {
              isCityMatch = true;
          } else {
              mismatchMessage = `This player's District (${selectedPlayer.district}) does not match the team name (${selectedTeam}).`;
          }
      } else if (competitionLevel === 'national') {
          if (selectedTeam === selectedPlayer.state) {
              isCityMatch = true;
          } else {
              mismatchMessage = `This player's state (${selectedPlayer.state}) does not match the team name (${selectedTeam}).`;
          }
      }

      if (!isCityMatch) {
          alert(mismatchMessage);
          return;
      }

        // Add the player to the updatedPlayers
        for (const game in switches) {
            if (switches[game]) {
                updatedPlayers[game].push({
                    name: selectedPlayer.name,
                    mobile: selectedPlayer.mobile,
                    email: selectedPlayer.email,
                    athleteCode: selectedPlayer.athleteCode,
                    city: selectedPlayer.city,
                    club: selectedPlayer.club,
                    district: selectedPlayer.district,
                    state: selectedPlayer.state
                });
            }
        }

        setPlayers(updatedPlayers);

        // Now, handle assignment to team if a team and slot are selected
        if (selectedTeam && currentGymnastSlot !== null) {
            const updatedTeams = { ...teams };
            const team = updatedTeams[selectedTeam];

            if (!team) {
                console.error(`Team ${selectedTeam} not found.`);
                return;
            }

            // Check for duplicate assignment
            if (team.gymnasts[currentGymnastSlot] && team.gymnasts[currentGymnastSlot].mobile === selectedPlayer.mobile) {
                alert(`Gymnast ${selectedPlayer.name} is already assigned to this slot in team ${selectedTeam}.`);
                return;
            }
            

            // Assign the gymnast
            team.gymnasts[currentGymnastSlot] = {
                name: selectedPlayer.name,
                mobile: selectedPlayer.mobile,
                email: selectedPlayer.email,
                athleteCode: selectedPlayer.athleteCode,
                city: selectedPlayer.city,
                club: selectedPlayer.club,
                district: selectedPlayer.district,
                state: selectedPlayer.state
            };

            setTeams(updatedTeams);
            setAssignedGymnasts([...assignedGymnasts, selectedPlayer]);
            alert("Gymnast assigned successfully.");
        }

        onConfirmModalClose();
        alert("Player added successfully.");
    }
};

  

  const isGymnastAssigned = (gymnast) => {
    return assignedGymnasts.some((assigned) => assigned?.mobile === gymnast.mobile || assigned?.email === gymnast.email);
  };

  const addTeam = () => {
    if (newTeamName.trim() === '') {
      alert('Please enter a valid team name.');
      return;
    }
    const updatedTeams = { ...teams };
    updatedTeams[newTeamName] = { name: newTeamName, gymnasts: Array(input1+input2).fill(null) };
    setTeams(updatedTeams);
    setNewTeamName('');
    closeTeamModal();
  };

  const assignGymnastToTeam = (gymnast, teamName, slot) => {
    if (!gymnast || !teamName || slot === null) return;

    const updatedTeams = { ...teams };
    const team = updatedTeams[teamName];

    if (!team) {
      console.error(`Team ${teamName} not found.`);
      return;
    }

    if (team.gymnasts[slot] && team.gymnasts[slot].id === gymnast.id) {
      console.warn(`Gymnast ${gymnast.name} is already assigned to the same slot in team ${teamName}.`);
      return;
    }

    team.gymnasts[slot] = gymnast;
    updatedTeams[teamName] = team;
    setTeams(updatedTeams);
    setAssignedGymnasts([...assignedGymnasts, gymnast]);
    closeAssignGymnastModal();
  };

  const handleAssignGymnast = (teamName, slot) => {
    setSelectedTeam(teamName);
    setCurrentGymnastSlot(slot);
    openAssignGymnastModal();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Manage Event</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Tabs isFitted variant="enclosed" index={step - 1} onChange={(index) => setStep(index + 1)}>
              <TabList mb="1em">
                <Tab>General Info</Tab>
                <Tab>Add Teams & Gymnasts</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <VStack spacing={4}>
                    <Input placeholder="Event Name" value={eventName} onChange={(e) => setEventName(e.target.value)} />
                    <Input placeholder="Category" value={category} onChange={(e) => setCategory(e.target.value)} />
                  </VStack>
                </TabPanel>
                <TabPanel>
                  <VStack spacing={4}>
                    <Heading>Competition Level: {competitionLevel}</Heading>
                    <HStack spacing={4}>
                      <Input
                        placeholder="Team Name"
                        value={newTeamName}
                        onChange={(e) => setNewTeamName(e.target.value)}
                      />
                      <Button colorScheme="blue" onClick={addTeam}>Add Team</Button>
                    </HStack>
                    <Box w="100%" borderWidth="1px" borderRadius="lg" overflow="hidden" p={4}>
                      <Heading size="md">Teams</Heading>
                      <List spacing={3}>
                        {Object.keys(teams).map((teamName) => (
                          <ListItem key={teamName}>
                            <Text fontWeight="bold">{teamName}</Text>
                            <List spacing={2}>
                              {teams[teamName].gymnasts.map((gymnast, index) => (
                                <ListItem key={index}>
                                  {gymnast ? (
                                    <HStack>
                                      <Text>{gymnast.name}</Text>
                                    </HStack>
                                  ) : (
                                    <Button
                                      size="sm"
                                      onClick={() => handleAssignGymnast(teamName, index)}
                                      leftIcon={<AiOutlineUserAdd />}
                                    >
                                      Assign Gymnast
                                    </Button>
                                  )}
                                </ListItem>
                              ))}
                            </List>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs>
            <Button type="submit" colorScheme="blue" mt={4}>Save</Button>
          </form>
        </ModalBody>
      </ModalContent>

      <Modal isOpen={isAssignGymnastModalOpen} onClose={closeAssignGymnastModal} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Assign Gymnast</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Search Gymnasts"
              value={searchQuery}
              onChange={handleSearchPlayer}
            />
            <List spacing={3} mt={4}>
              {searchResults.map((player) => (
                <ListItem key={player.id}>
                  <Box
                    p={2}
                    borderWidth="1px"
                    borderRadius="lg"
                    cursor="pointer"
                    onClick={() => handleSelectPlayer(player)}
                  >
                    <Text fontWeight="bold">{player.name}</Text>
                    <Text>{player.email}</Text>
                  </Box>
                </ListItem>
              ))}
            </List>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isConfirmModalOpen} onClose={onConfirmModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Add Gymnast</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedPlayer && (
              <>
                <Text>Name: {selectedPlayer.name}</Text>
                <Text>Email: {selectedPlayer.email}</Text>
                <Text>Mobile: {selectedPlayer.mobile}</Text>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleConfirmAddPlayer} colorScheme="blue">Confirm</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Modal>
  );
};

export default ManageEventModal;
