// import React, { useEffect, useState } from 'react';
// import { Box, Button, Input, Text, useToast, VStack } from '@chakra-ui/react';
// import axios from 'axios';
// import { useAuth } from '../context/AuthContext';
// import { useParams, useNavigate } from 'react-router-dom';

// const CertificatePage = () => {
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [event, setEvent] = useState(null);
//   const { user, userSupervisorRole, loading: userLoading } = useAuth();
//   const toast = useToast();
//   const [loading, setLoading] = useState(true);
//   const { eventId, game } = useParams();
//   const navigate = useNavigate();
//   const [players, setPlayers] = useState([]);

//   useEffect(() => {
//     if (!userLoading) {
//       if (!user || userSupervisorRole !== 'supervisor') {
//         navigate('/supervisor');
//         return;
//       }

//       const fetchPlayers = async () => {
//         try {
//           const response = await axios.get(`${API_URL}/api/events/${eventId}`);
//           const eventDetails = response.data;
//           setEvent(eventDetails);
          
//           if (eventDetails && eventDetails.supervisors) {
//             const supervisors = eventDetails.supervisors[game];
//             const isSupervisor = supervisors && Object.values(supervisors).some(
//               supervisor => supervisor && (supervisor.email === user.email || supervisor.mobile === user.phoneNumber)
//             );
//             if (!isSupervisor) {
//               navigate('/notaccess');
//               return;
//             }

//             const gamePlayers = eventDetails.players[game];
//             setPlayers(gamePlayers || []);
//           } else {
//             navigate('/notaccess');
//             console.error('Event not found');
//           }
//         } catch (error) {
//           console.error('Error fetching players:', error);
//         } finally {
//           setLoading(false);
//         }
//       };
//       fetchPlayers();
//     }
//   }, [userLoading, user, userSupervisorRole, eventId, game, navigate]);

//   const handleFileUpload = (event) => {
//     setSelectedFile(event.target.files[0]);
//   };

//   const handleSendCertificates = async () => {
//     try {
//       const certificates = players.map((player) => ({
//         playerName: player.name,
//         athleteCode: player.athleteCode,
//         email: player.email,
//       }));

//       const formData = new FormData();
//       formData.append('file', selectedFile);
//       formData.append('certificates', JSON.stringify(certificates));

//       const response = await axios.post(`${API_URL}/api/events/${eventId}/certificates`, {
//         eventId,
//         certificates,
//       });

//       toast({
//         title: 'Certificates sent successfully!',
//         status: 'success',
//         duration: 5000,
//         isClosable: true,
//       });

//       navigate(`/event/${eventId}/${game}`, { state: { updatedCertificates: response.data.certificates } });
//     } catch (error) {
//       console.error('Error sending certificates:', error);
//       toast({
//         title: 'Error sending certificates',
//         description: error.message,
//         status: 'error',
//         duration: 5000,
//         isClosable: true,
//       });
//     }
//   };

//   return (
//     <VStack spacing={4}>
//       <Input type="file" onChange={handleFileUpload} />
//       <Button onClick={handleSendCertificates} isDisabled={!selectedFile}>
//         Send Certificates
//       </Button>
//       {selectedFile && (
//         <Box
//           bgImage={`url(${URL.createObjectURL(selectedFile)})`}
//           bgSize="cover"
//           width="500px"
//           height="350px"
//           p={4}
//         >
//           <Text fontSize="xl" fontWeight="bold">Name</Text>
//           <Text fontSize="md">Age</Text>
//           <Text fontSize="md">Athlete Code</Text>
//         </Box>
//       )}
//     </VStack>
//   );
// };

// export default CertificatePage;


// import React, {useEffect, useState } from 'react';
// import axios from 'axios';
// import { useToast, Box, Button, Input, Image, Text } from '@chakra-ui/react';
// import { useParams, useNavigate } from 'react-router-dom';
// import { useAuth } from '../context/AuthContext';

// const CertificatePage = () => {
//     const { eventId, game } = useParams();
//     const [event, setEvent] = useState(null);
//     const [players, setPlayers] = useState([]);
//     const { user, userSupervisorRole, loading: userLoading } = useAuth();
//   const [file, setFile] = useState(null);
//   const [preview, setPreview] = useState(null);
//   const [namePosition, setNamePosition] = useState({ left: 320, top: 370 });
//   const [athleteCodePosition, setAthleteCodePosition] = useState({ left: 100, top: 250 });
//   const toast = useToast();
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate();

//     useEffect(() => {
//     if (!userLoading) {
//       if (!user || userSupervisorRole !== 'supervisor') {
//         navigate('/supervisor');
//         return;
//       }

//       const fetchPlayers = async () => {
//         try {
//           const response = await axios.get(`${API_URL}/api/events/${eventId}`);
//           const eventDetails = response.data;
//           setEvent(eventDetails);
          
//           if (eventDetails && eventDetails.supervisors) {
//             const supervisors = eventDetails.supervisors[game];
//             const isSupervisor = supervisors && Object.values(supervisors).some(
//               supervisor => supervisor && (supervisor.email === user.email || supervisor.mobile === user.phoneNumber)
//             );
//             if (!isSupervisor) {
//               navigate('/notaccess');
//               return;
//             }

//             const gamePlayers = eventDetails.players[game];
//             setPlayers(gamePlayers || []);
//           } else {
//             navigate('/notaccess');
//             console.error('Event not found');
//           }
//         } catch (error) {
//           console.error('Error fetching players:', error);
//         } finally {
//           setLoading(false);
//         }
//       };
//       fetchPlayers();
//     }
//   }, [userLoading, user, userSupervisorRole, eventId, game, navigate]);


//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     setFile(file);
//     setPreview(URL.createObjectURL(file));
//   };

//   const handleUpload = async () => {
//     try {
//       const certificates = players.map((player) => ({
//                 playerName: player.name,
//                 athleteCode: player.athleteCode,
//                 email: player.email,
//               }));
//       const formData = new FormData();
//       formData.append('certificate', file);
//       formData.append('certificates', JSON.stringify(certificates));

//       const res = await axios.post(`${API_URL}/api/events/${eventId}/upload-certificate`, formData);
//       const filePath = res.data.filePath;

//       const sendCertificatesRes = await axios.post(`${API_URL}/api/events/${eventId}/certificates`, { 
//         eventId,
//         certificates,
//         certificatePath: filePath,
//         namePosition,
//         athleteCodePosition
//       });

//       sendCertificatesRes.data.forEach(result => {
//         toast({
//           title: `${result.user}'s certificate ${result.status === 'Success' ? 'sent successfully' : 'failed to send'}`,
//           status: result.status === 'Success' ? 'success' : 'error',
//           duration: 3000,
//           isClosable: true,
//         });
//       });
//     } catch (error) {
//       console.error('Error uploading or sending certificates:', error);
//       toast({
//         title: 'Error uploading or sending certificates.',
//         status: 'error',
//         duration: 3000,
//         isClosable: true,
//       });
//     }
//   };

//   const move = (setPosition, direction) => {
//     setPosition(prev => {
//       switch (direction) {
//         case 'left':
//           return { ...prev, left: prev.left - 10 };
//         case 'right':
//           return { ...prev, left: prev.left + 10 };
//         case 'up':
//           return { ...prev, top: prev.top - 10 };
//         case 'down':
//           return { ...prev, top: prev.top + 10 };
//         default:
//           return prev;
//       }
//     });
//   };

//   return (
//     <Box>
//       <Input type="file" onChange={handleFileChange} />
      
//       <Button colorScheme="blue" onClick={() => move(setNamePosition, 'left')}>Move Name Left</Button>
//       <Button colorScheme="blue" onClick={() => move(setNamePosition, 'right')}>Move Name Right</Button>
//       <Button colorScheme="blue" onClick={() => move(setNamePosition, 'up')}>Move Name Up</Button>
//       <Button colorScheme="blue" onClick={() => move(setNamePosition, 'down')}>Move Name Down</Button>
//       <Button colorScheme="blue" onClick={() => move(setAthleteCodePosition, 'left')}>Move Code Left</Button>
//       <Button colorScheme="blue" onClick={() => move(setAthleteCodePosition, 'right')}>Move Code Right</Button>
//       <Button colorScheme="blue" onClick={() => move(setAthleteCodePosition, 'up')}>Move Code Up</Button>
//       <Button colorScheme="blue" onClick={() => move(setAthleteCodePosition, 'down')}>Move Code Down</Button>
//       <Button colorScheme="blue" onClick={handleUpload}>Send Certificate</Button>

//       {preview && <Image w={"841px"} height={"595px"} src={preview} alt="Certificate Preview" />}
//       <Box position="relative">
//         {preview && (
//           <Box
//             position="absolute"
//             left={`${namePosition.left}px`}
//             mt={"-610px"}
//             top={`${namePosition.top}px`}
//             background="rgba(255, 255, 255, 0.7)"
//             padding="5px"
//             border={"1px solid black"}
//           >
//             <Text fontSize={"30px"}>Aniket Rasal</Text>
//           </Box>
//         )}
//         {preview && (
//           <Box
//             position="absolute"
//             left={`${athleteCodePosition.left}px`}
//             top={`${athleteCodePosition.top}px`}
//             background="rgba(255, 255, 255, 0.7)"
//             padding="5px"
//           >
//             <Text>Athlete Code</Text>
//           </Box>
//         )}
//       </Box>
//     </Box>
//   );
// };

// export default CertificatePage;

// import React, { useEffect, useState } from 'react';
// import { Box, Button, Input, Text, useToast, Image } from '@chakra-ui/react';
// import axios from 'axios';
// import { useAuth } from '../context/AuthContext';
// import { useParams, useNavigate } from 'react-router-dom';
// import {API_URL} from "../utils/apiConfig";

// const CertificatePage = () => {
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [event, setEvent] = useState(null);
//   const { user, userSupervisorRole, loading: userLoading } = useAuth();
//   const toast = useToast();
//   const [loading, setLoading] = useState(true);
//   const { eventId, game } = useParams();
//   const navigate = useNavigate();
//   const [players, setPlayers] = useState([]);
//   const [preview, setPreview] = useState(null);
//   const [namePosition, setNamePosition] = useState({ top: 370 });
//   const [certificateNumberPosition, setCertificateNumberPosition] = useState({ top: 0, right:0 });
//   const [recipientEmail, setRecipientEmail] = useState('');
//   const [certificatesSent, setCertificatesSent] = useState(false);

//   useEffect(() => {
//     if (!userLoading) {
//       if (!user || userSupervisorRole !== 'supervisor') {
//         navigate('/supervisor');
//         return;
//       }

//       const fetchPlayers = async () => {
//         try {
//           const response = await axios.get(`${API_URL}/api/events/${eventId}`);
//           const eventDetails = response.data;
//           setEvent(eventDetails);
          
//           if (eventDetails && eventDetails.supervisors) {
//             const supervisors = eventDetails.supervisors[game];
//             const isSupervisor = supervisors && Object.values(supervisors).some(
//               supervisor => supervisor && (supervisor.email === user.email || supervisor.mobile === user.phoneNumber)
//             );
//             if (!isSupervisor) {
//               navigate('/notaccess');
//               return;
//             }

//             const gamePlayers = eventDetails.players[game];
//             setPlayers(gamePlayers || []);
//           } else {
//             navigate('/notaccess');
//             console.error('Event not found');
//           }
//         } catch (error) {
//           console.error('Error fetching players:', error);
//         } finally {
//           setLoading(false);
//         }
//       };
//       fetchPlayers();
//     }
//   }, [userLoading, user, userSupervisorRole, eventId, game, navigate]);

//   const handleFileUpload = (event) => {
//     setSelectedFile(event.target.files[0]);
//   };

//   const handleSendCertificates = async () => {
//     try {
//       const certificates = players.map((player) => ({
//         playerName: player.name,
//         athleteCode: player.athleteCode,
//         email: player.email,
//       }));

//       const formData = new FormData();
//       formData.append('file', selectedFile);
//       formData.append('certificates', JSON.stringify(certificates));
//       formData.append('namePosition', JSON.stringify(namePosition));
//       formData.append('certificateNumberPosition', JSON.stringify(certificateNumberPosition));

//       const response = await axios.post(`${API_URL}/api/events/${eventId}/certificates`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });

//       toast({
//         title: 'Certificates sent successfully!',
//         status: 'success',
//         duration: 5000,
//         isClosable: true,
//       });
//       setCertificatesSent(true);

//       navigate(`/event/${eventId}/${game}`, { state: { updatedCertificates: response.data.certificates } });
//     } catch (error) {
//       console.error('Error sending certificates:', error);
//       toast({
//         title: 'Error sending certificates',
//         description: error.message,
//         status: 'error',
//         duration: 5000,
//         isClosable: true,
//       });
//     }
//   };

//   const handleSendAllCertificatesToOnePerson = async () => {
//     try {
//         const formData = new FormData();
//         formData.append('file', selectedFile);
//         formData.append('namePosition', JSON.stringify(namePosition));
//         formData.append('certificateNumberPosition', JSON.stringify(certificateNumberPosition));
//         formData.append('recipientEmail', recipientEmail);

//         const response = await axios.post(`${API_URL}/api/events/${eventId}/${game}/certificates/send-to-one`, formData, {
//             headers: {
//                 'Content-Type': 'multipart/form-data',
//             },
//         });

//         toast({
//             title: 'All certificates sent to one person successfully!',
//             status: 'success',
//             duration: 5000,
//             isClosable: true,
//         });

//         setRecipientEmail('');
//         setCertificatesSent(true);
//     } catch (error) {
//         console.error('Error sending all certificates to one person:', error);
//         toast({
//             title: 'Error sending certificates',
//             description: error.message,
//             status: 'error',
//             duration: 5000,
//             isClosable: true,
//         });
//     }
// };


//   const move = (setPosition, direction) => {
//     setPosition(prev => {
//       const newPosition = { ...prev };
//       switch (direction) {
//         case 'left':
//           newPosition.left -= 10;
//           break;
//         case 'right':
//           newPosition.left += 10;
//           break;
//         case 'up':
//           newPosition.top -= 10;
//           break;
//         case 'down':
//           newPosition.top += 10;
//           break;
//         default:
//           break;
//       }
//       console.log('New Position:', newPosition); // Debug output to check new position
//       return newPosition;
//     });
//   };
  

//   return (
//     <Box spacing={4} minHeight={"140vh"}>
//       <Input type="file" onChange={handleFileUpload} />
//       <Button onClick={handleSendCertificates} isDisabled={!selectedFile || certificatesSent}>
//         Send Certificates
//       </Button>
//       {/* <Button colorScheme="blue" onClick={() => move(setNamePosition, 'left')}>Move Name Left</Button> */}
//       {/* <Button colorScheme="blue" onClick={() => move(setNamePosition, 'right')}>Move Name Right</Button> */}
//       <Button colorScheme="blue" onClick={() => move(setNamePosition, 'up')}>Move Name Up</Button>
//       <Button colorScheme="blue" onClick={() => move(setNamePosition, 'down')}>Move Name Down</Button>
//       <Button colorScheme="blue" onClick={() => move(setCertificateNumberPosition, 'left')}>Move Certificate Number Left</Button>
//       <Button colorScheme="blue" onClick={() => move(setCertificateNumberPosition, 'right')}>Move Certificate Number Right</Button>
//       <Button colorScheme="blue" onClick={() => move(setCertificateNumberPosition, 'up')}>Move Certificate Number Up</Button>
//       <Button colorScheme="blue" onClick={() => move(setCertificateNumberPosition, 'down')}>Move Name Down</Button>
//       <Input
//                 type="email"
//                 placeholder="Recipient email"
//                 value={recipientEmail}
//                 onChange={(e) => setRecipientEmail(e.target.value)}
//             />
//             <Button onClick={handleSendAllCertificatesToOnePerson} isDisabled={!selectedFile || !recipientEmail || certificatesSent}>
//                 Send All Certificates to One Person
//             </Button>
//       {selectedFile && (
//         <Box
//           bgImage={`url(${URL.createObjectURL(selectedFile)})`}
//           bgSize="cover"
//           width="500px"
//           height="750px"
//           p={4}
//           position={"relative"}
//         >
//           <Text fontSize="xl" w={"100%"}  position="absolute" textAlign={"center"} top={`${namePosition.top}px`}  fontWeight="bold">Name</Text>
//           {/* <Box
//             position="absolute"
            
//             // mb={"200px"}
//             left={`${namePosition.left}px`}
//             // m={`${namePosition.left}px`}
//             mt={"-610px"}
//             top={`${namePosition.top}px`}
//             background="rgba(255, 255, 255, 0.7)"
//             padding="5px"
//             border={"1px solid black"}
//           >
//             <Text fontSize={"30px"} >Aniket Rasal</Text>
//           </Box> */}
//           <Text fontSize="md" >Certificate Number: 123456789098</Text>
//           <Text fontSize="md">Athlete Code</Text>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default CertificatePage;


import React, { useEffect, useState } from 'react';
import { Box, Button, Input, Text, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import {API_URL} from "../utils/apiConfig";

const CertificatePage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [event, setEvent] = useState(null);
  const [players, setPlayers] = useState([]);
  const [namePosition, setNamePosition] = useState({ top: 370 });
  const [scorePosition, setScorePosition] = useState({top: 490});
  const [certificateNumberPosition, setCertificateNumberPosition] = useState({ top: 0, right: 0 });
  const [recipientEmail, setRecipientEmail] = useState('');
  const [certificatesSent, setCertificatesSent] = useState(false);
  const { user, userSupervisorRole, loading: userLoading } = useAuth();
  const toast = useToast();
  const { eventId, game } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!userLoading) {
      if (!user || userSupervisorRole !== 'supervisor') {
        navigate('/supervisor');
        return;
      }

      const fetchPlayers = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/events/${eventId}`);
          const eventDetails = response.data;
          setEvent(eventDetails);

          if (eventDetails && eventDetails.supervisors) {
            const supervisors = eventDetails.supervisors[game];
            const isSupervisor = supervisors && Object.values(supervisors).some(
              supervisor => supervisor && (supervisor.email === user.email || supervisor.mobile === user.phoneNumber)
            );
            if (!isSupervisor) {
              navigate('/notaccess');
              return;
            }

            const gamePlayers = eventDetails.players[game];
            if (gamePlayers) {
              const playersWithScores = await calculateAllAroundScores(gamePlayers, eventDetails);
              setPlayers(playersWithScores);
            }
          } else {
            navigate('/notaccess');
            console.error('Event not found');
          }
        } catch (error) {
          console.error('Error fetching players:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchPlayers();
    }
  }, [userLoading, user, userSupervisorRole, eventId, game, navigate]);

  // const calculateAllAroundScores = async (players, eventDetails) => {
  //   const aggregatedPlayers = {};

  //   players.forEach(player => {
  //     const uniqueId = player.email || player.mobile;

  //     if (!aggregatedPlayers[uniqueId]) {
  //       aggregatedPlayers[uniqueId] = {
  //         name: player.name,
  //         email: player.email,
  //         mobile: player.mobile,
  //         scores: {},
  //         totalAllAround: 0,
  //       };

  //       Object.keys(eventDetails.teams).some((team) => {
  //         const teamMembers = eventDetails.teams[team].gymnasts;
  //         const match = teamMembers.some((member) =>
  //           (member.email && member.email === player.email) ||
  //           (member.mobile && member.mobile === player.mobile)
  //         );
  //         if (match) {
  //           aggregatedPlayers[uniqueId].teamName = team;
  //           return true;
  //         }
  //         return false;
  //       });
  //     }

  //     aggregatedPlayers[uniqueId].scores[game] = {
  //       e: player.scores.e,
  //       d1: player.scores.d1,
  //       penalty: player.scores.penalty,
  //       total: player.scores.total,
  //     };

  //     aggregatedPlayers[uniqueId].totalAllAround += player.scores.total;
  //   });

  //   return Object.values(aggregatedPlayers).sort((a, b) => b.totalAllAround - a.totalAllAround);
  // };

  const calculateAllAroundScores = async (players, eventDetails) => {
    const aggregatedPlayers = {};
  
    // Get all enabled games
    const enabledGames = Object.keys(eventDetails.sports || {}).filter(game => eventDetails.sports[game]);
  
    enabledGames.forEach((game) => {
      const gamePlayers = eventDetails.players[game] || []; // Default to an empty array if no players
  
      gamePlayers.forEach(player => {
        const uniqueId = player.email || player.mobile;
  
        if (!aggregatedPlayers[uniqueId]) {
          aggregatedPlayers[uniqueId] = {
            name: player.name,
            scores: {},
            totalAllAround: 0,
            teamName: 'N/A', // Default team name
          };
  
          // Check if player is in any team
          let teamFound = false;
          Object.keys(eventDetails.teams || {}).forEach((team) => {
            const teamMembers = eventDetails.teams[team].gymnasts || [];
            const match = teamMembers.some((member) => 
              (member.email && member.email === player.email) || 
              (member.mobile && member.mobile === player.mobile)
            );
            if (match) {
              aggregatedPlayers[uniqueId].teamName = team;
              teamFound = true;
            }
          });
  
          // If no team found, use player.city as teamName
          if (!teamFound) {
            aggregatedPlayers[uniqueId].teamName = player.city || 'N/A';
          }
        }
  
        // Add or update scores for the current game
        aggregatedPlayers[uniqueId].scores[game] = {
          e: player.scores.e,
          d1: player.scores.d1,
          penalty: player.scores.penalty,
          total: player.scores.total,
        };
  
        // Accumulate the total score across all games
        aggregatedPlayers[uniqueId].totalAllAround += player.scores.total;
      });
    });
  
    // Convert aggregated data into an array and sort by totalAllAround score
    const playersWithAggregatedScores = Object.values(aggregatedPlayers).map(player => {
      // Compute totalAllAround score from all games
      const totalAllAround = Object.values(player.scores).reduce((sum, score) => sum + score.total, 0);
      return { ...player, totalAllAround };
    });
  
    return playersWithAggregatedScores.sort((a, b) => b.totalAllAround - a.totalAllAround);
  };
  

  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSendCertificates = async () => {
    try {
      const certificates = players.map((player) => ({
        playerName: player.name,
        athleteCode: player.athleteCode,
        email: player.email,
        totalAllAround: player.totalAllAround, // Add totalAllAround to certificate data
      }));

      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('certificates', JSON.stringify(certificates));
      formData.append('namePosition', JSON.stringify(namePosition));
      formData.append('scorePosition',JSON.stringify(scorePosition));
      formData.append('certificateNumberPosition', JSON.stringify(certificateNumberPosition));

      const response = await axios.post(`${API_URL}/api/events/${eventId}/certificates`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast({
        title: 'Certificates sent successfully!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setCertificatesSent(true);

      navigate(`/event/${eventId}/${game}`, { state: { updatedCertificates: response.data.certificates } });
    } catch (error) {
      console.error('Error sending certificates:', error);
      toast({
        title: 'Error sending certificates',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSendAllCertificatesToOnePerson = async () => {
    try {

      const certificates = players.map((player) => ({
        playerName: player.name,
        athleteCode: player.athleteCode,
        email: player.email,
        totalAllAround: player.totalAllAround,
      }));

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('certificates', JSON.stringify(certificates));
        formData.append('namePosition', JSON.stringify(namePosition));
        formData.append('scorePosition', JSON.stringify(scorePosition));
        formData.append('certificateNumberPosition', JSON.stringify(certificateNumberPosition));
        formData.append('recipientEmail', recipientEmail);

        const response = await axios.post(`${API_URL}/api/events/${eventId}/${game}/certificates/send-to-one`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        toast({
            title: 'All certificates sent to one person successfully!',
            status: 'success',
            duration: 5000,
            isClosable: true,
        });

        setRecipientEmail('');
        setCertificatesSent(true);
    } catch (error) {
        console.error('Error sending all certificates to one person:', error);
        toast({
            title: 'Error sending certificates',
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
    }
  };

  const move = (setPosition, direction) => {
    setPosition(prev => {
      const newPosition = { ...prev };
      switch (direction) {
        case 'left':
          newPosition.left -= 10;
          break;
        case 'right':
          newPosition.left += 10;
          break;
        case 'up':
          newPosition.top -= 10;
          break;
        case 'down':
          newPosition.top += 10;
          break;
        default:
          break;
      }
      return newPosition;
    });
  };

  return (
    <Box spacing={4} minHeight={"140vh"}>
      <Input type="file" onChange={handleFileUpload} />
      <Button onClick={handleSendCertificates} isDisabled={!selectedFile || certificatesSent}>
        Send Certificates
      </Button>
      <Button colorScheme="blue" onClick={() => move(setNamePosition, 'up')}>Move Name Up</Button>
      <Button colorScheme="blue" onClick={() => move(setNamePosition, 'down')}>Move Name Down</Button>
      <Button colorScheme="blue" onClick={() => move(setCertificateNumberPosition, 'left')}>Move Certificate Number Left</Button>
      <Button colorScheme="blue" onClick={() => move(setCertificateNumberPosition, 'right')}>Move Certificate Number Right</Button>
      <Button colorScheme="blue" onClick={() => move(setCertificateNumberPosition, 'up')}>Move Certificate Number Up</Button>
      <Button colorScheme="blue" onClick={() => move(setCertificateNumberPosition, 'down')}>Move Certificate Number Down</Button>
      <br/>
      <Button colorScheme='green' onClick={()=> move(setScorePosition, 'up')}>Move Score Up</Button>
      <Button colorScheme='green' onClick={()=>move(setScorePosition, 'down')}>Move Name Down</Button>
      <Input
        type="email"
        placeholder="Recipient email"
        value={recipientEmail}
        onChange={(e) => setRecipientEmail(e.target.value)}
      />
      <Button onClick={handleSendAllCertificatesToOnePerson} isDisabled={!selectedFile || !recipientEmail || certificatesSent}>
        Send All Certificates to One Person
      </Button>
      {selectedFile && (
        <Box
          bgImage={`url(${URL.createObjectURL(selectedFile)})`}
          bgSize="cover"
          width="500px"
          height="750px"
          p={4}
          position={"relative"}
        >
          <Text fontSize="xl" w={"100%"} position="absolute" textAlign={"center"} marginTop={"-20px"} top={`${namePosition.top}px`} fontWeight="bold">Name</Text>
          <Text fontSize="md" w={"100%"} position={"absolute"} textAlign={"center"} marginTop={"-40px"} top={`${scorePosition.top}px`} fontWeight={"bold"}>Score</Text>
          <Text fontSize="md" position="absolute" bottom="10px" left="10px">Certificate Number: 123456789098</Text>
          <Text fontSize="md" position="absolute" bottom="30px" left="10px">Athlete Code</Text>
        </Box>
      )}
    </Box>
  );
};

export default CertificatePage;

