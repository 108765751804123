import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {useReactToPrint} from "react-to-print";
import { Box, Button, Heading, List, ListItem, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import {API_URL} from "../utils/apiConfig";

const LiveScoreEventWise = () => {
  const [view, setView] = useState('events');
  const [events, setEvents] = useState([]);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [currentSport, setCurrentSport] = useState(null);
  const [sports, setSports] = useState([]);
  const [players, setPlayers] = useState([]);
  const componentPDF = useRef();

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/approved-players-spot`);
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const handleEventClick = (eventName) => {
    setCurrentEvent(eventName);
    setSports(events[eventName]);
    setView('sports');
  };

  const handleSportClick = (sportName) => {
    setCurrentSport(sportName);
    const sortedPlayers = sports[sportName].sort((a, b) => b.scores.total - a.scores.total);
    setPlayers(sortedPlayers);
    setView('players');
  };

  const handleBack = () => {
    if (view === 'sports') {
      setView('events');
      setCurrentEvent(null);
    } else if (view === 'players') {
      setView('sports');
      setCurrentSport(null);
    }
  };

  const generatePDF = useReactToPrint({
    content: ()=> componentPDF.current,
    documentTitle: "gymnast-scorelist-gymnazien",
    onAfterPrint: ()=>alert("Gymnast Scorelist PDF export successfully")
  })

  return (
    <Box height={"100vh"}  bg={"#26263c"} p={4}>
      <Heading color={"white"} as="h1" mb={4}>Gymnazien Sports Events</Heading>
      {view === 'events' && (
        <List spacing={3}>
          {Object.keys(events).map((eventName, index) => (
            <ListItem color={"white"} key={index} onClick={() => handleEventClick(eventName)} cursor="pointer">
              {eventName}
            </ListItem>
          ))}
        </List>
      )}
      {view === 'sports' && (
        <Box>
          <Button onClick={handleBack} mb={4}>Back</Button>
          <Heading color={"white"} as="h2" mb={4}>{currentEvent}</Heading>
          <List spacing={3}>
            {Object.keys(sports).map((sportName, index) => (
              <ListItem color={"white"} key={index} onClick={() => handleSportClick(sportName)} cursor="pointer">
                {sportName}
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      {view === 'players' && (
        <Box>
            <Box display={"flex"} justifyContent={"space-between"}>
        <Button onClick={handleBack} mb={4}>Back</Button>
        <Heading color={"white"} as="h3" mb={4}>{currentSport}</Heading>
        <Button bg={"#ffbf00"}>Refresh</Button>
        </Box>
        
        <div ref={componentPDF} style={{width:"100%"}}>
        {players.length > 0 ? (
          <Table variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bg={"#ffbf00"} colorScheme="gray">
            <Thead>
              <Tr>
                <Th>Rank</Th>
                <Th>Name</Th>
                <Th>D Score</Th>
                <Th>E Score</Th>
                <Th>Penalty</Th>
                <Th>Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              {players.map((player, index) => (
                <Tr key={index}>
                    <Td>{index+1}</Td>
                  <Td>{player.name}</Td>
                  <Td>{player.scores.e}</Td>
                    <Td>{player.scores.d1}</Td>
                    <Td>{player.scores.penalty}</Td>
                    <Td>{player.scores.total}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
            <div>
            <br/>
          <Text>No approved players found.</Text>
          </div>
        )}
        </div>
      </Box>
        
      )}
    </Box>
  );
};

export default LiveScoreEventWise;
