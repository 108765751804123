import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Box, Button, Heading, Flex, Table, Thead, Tbody, Tr, Th, Td, Text, Spinner, Input } from '@chakra-ui/react';
import { FaMedal } from 'react-icons/fa';
import { useAuth } from '../../context/AuthContext';
import io from 'socket.io-client';
import { API_URL } from '../../utils/apiConfig';

const CertificatesMertiAllAround = () => {
  const { eventId, game } = useParams();
  const [eventName, setEventName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  // const { user, loading: userLoading } = useAuth();
  // const [players, setPlayers] = useState([]);
  const [allPlayers, setAllPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isGameSupervisor, setIsGameSupervisor] = useState(false);
  const [recipientEmail, setRecipientEmail] = useState('');
  const componentPDF = useRef();
  const socketRef = useRef();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [namePosition, setNamePosition] = useState({ top: 0, left: 0 });
  const [rankPosition, setRankPosition] = useState({top: 0, left: 0});
  const [appratusPosition, setAppratusPosition] = useState({top:0, left:0});
  const [scorePosition, setScorePosition] = useState({top:0, left:0});
  const [dobPosition, setDobPosition] = useState({top:0, left:0});
  const [clubPosition, setClubPosition] = useState({top:0, left:0});
  const [ageGroupPosition, setAgeGroupPosition] = useState({top:0, left:0});
  const { user, userSupervisorRole, loading: userLoading } = useAuth();

  const fromSupervisorPanel = location.state?.fromSupervisorPanel || false;

  useEffect(() => {
    socketRef.current = io(`${API_URL}`, {
      transports: ['websocket', 'polling'],
    });

    socketRef.current.on('playerUpdated', (updatedPlayers) => {
      if (updatedPlayers[eventId] && updatedPlayers[eventId].sports[game]) {
        const gamePlayers = updatedPlayers[eventId].sports[game];
        const filteredPlayers = gamePlayers.filter(player => player.approve);
        const sortedAndRankedPlayers = sortAndRankPlayers(gamePlayers);
        setAllPlayers(sortedAndRankedPlayers);
      }
    });

    if (user) {
      checkSupervisor();
    }

    return () => {
      socketRef.current.disconnect();
    };
  }, [eventId, game, user]);

  useEffect(() => {
    if (!userLoading) {
      if (!user || userSupervisorRole !== 'supervisor') {
        navigate('/supervisor');
        return;
      }

      const fetchAllPlayers = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/events/${eventId}`);
          const eventDetails = response.data;
          setEventName(eventDetails.eventName);
      
          if (eventDetails && eventDetails.players && eventDetails.sports) {
            const enabledGames = Object.keys(eventDetails.sports).filter(game => eventDetails.sports[game]);
            const aggregatedPlayers = {};
      
            for (const game of enabledGames) {
              const gamePlayers = eventDetails.players[game];
      
              if (gamePlayers) {
                // Fetch additional data for each player
                const playersWithAdditionalInfo = await Promise.all(gamePlayers.map(async (player) => {
                  try {
                    // Fetch additional info using the athlete's email
                    const additionalInfoResponse = await axios.get(`${API_URL}/api/athlete/athletes/${player.email}`);
                    const { dob, country, athleteCode, state, club, district } = additionalInfoResponse.data;
      
                    // Return the player object with additional info
                    return { ...player, dob, country, athleteCode, state, club, district };
                  } catch (error) {
                    console.error(`Failed to fetch additional info for player ${player.email}:`, error);
                    return player; // Return the player without additional info in case of error
                  }
                }));
      
                playersWithAdditionalInfo.forEach(player => {
                  const uniqueId = player.email || player.mobile;
      
                  if (!aggregatedPlayers[uniqueId]) {
                    aggregatedPlayers[uniqueId] = {
                      name: player.name,
                      email: player.email,
                      dob: player.dob || 'N/A',
                      country: player.country || 'N/A',
                      athleteCode: player.athleteCode || 'N/A',
                      state: player.state || 'N/A',
                      district: player.district || "N/A",
                      club: player.club || 'N/A',
                      scores: {},
                      totalAllAround: 0,
                      teamName: 'N/A',
                    };
      
                    let teamFound = false;
                    Object.keys(eventDetails.teams || {}).forEach((team) => {
                      const teamMembers = eventDetails.teams[team].gymnasts || [];
                      const match = teamMembers.some((member) =>
                        (member.email && member.email === player.email) ||
                        (member.mobile && member.mobile === player.mobile)
                      );
                      if (match) {
                        aggregatedPlayers[uniqueId].teamName = team;
                        teamFound = true;
                      }
                    });
      
                    if (!teamFound) {
                      aggregatedPlayers[uniqueId].teamName = player.city || 'N/A';
                    }
                  }
      
                  // Add the scores for the current game
                  aggregatedPlayers[uniqueId].scores[game] = {
                    e: player.scores.e,
                    d1: player.scores.d1,
                    penalty: player.scores.penalty,
                    total: player.scores.total,
                  };
      
                  // Update totalAllAround score
                  aggregatedPlayers[uniqueId].totalAllAround += player.scores.total;
                });
              }
            }
      
            // Sort players by totalAllAround
            const sortedPlayers = sortAndRankPlayers(aggregatedPlayers);
            // const sortedPlayers = Object.values(aggregatedPlayers).sort((a, b) => b.totalAllAround - a.totalAllAround);
            setAllPlayers(sortedPlayers);
          } else {
            console.error('No players or sports found');
          }
        } catch (error) {
          console.error('Error fetching players:', error);
        } finally {
          setLoading(false);
        }
      };
      
      fetchAllPlayers();
    }
  }, [userLoading, user, userSupervisorRole, eventId, navigate]);

  const sortAndRankPlayers = (players) => {
    const sortedPlayers = Object.values(players).sort((a, b) => {
      if ((b.totalAllAround || 0) !== (a.totalAllAround || 0)) {
        return (b.totalAllAround || 0) - (a.totalAllAround || 0);
      }
    });

    let currentRank = 1;
    let currentTotalScore = sortedPlayers[0]?.totalAllAround || 0;

    sortedPlayers.forEach((player, index) => {
      if (player.scores.total !== currentTotalScore) {
        currentRank = index + 1;
        currentTotalScore = player.totalAllAround;
      }
      player.rank = currentRank;
    });

    return sortedPlayers;
  };

  const checkSupervisor = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/events/${eventId}`);
      const eventDetails = response.data;
      if (eventDetails && eventDetails.supervisors && eventDetails.supervisors[game]) {
        const supervisors = eventDetails.supervisors[game];
        const supervisorKeys = Object.keys(supervisors);
        const isAssignedSupervisor = supervisorKeys.some(key => supervisors[key].email === user.email);
        setIsGameSupervisor(isAssignedSupervisor);
      } else {
        setIsGameSupervisor(false);
      }
    } catch (error) {
      console.error('Error checking supervisor status:', error);
      setIsGameSupervisor(false);
    }
  };

  const handleBack = () => {
    if (fromSupervisorPanel) {
      navigate(`/supervisorpanel/${eventId}/${game}`);
    } else {
      navigate(`/live/${eventId}`);
    }
  };

  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const sendCertificates = async () => {
    if (recipientEmail && allPlayers.length > 0 && selectedFile) {
      const formData = new FormData();
    //   formData.append('image', selectedImage);
        formData.append('image', selectedFile);
        formData.append('recipientEmail', recipientEmail);
        formData.append('players', JSON.stringify(allPlayers.filter(player => [1, 2, 3].includes(player.rank))));
        formData.append('namePosition', JSON.stringify(namePosition));
        formData.append('rankPosition', JSON.stringify(rankPosition));
        formData.append('appratusPosition', JSON.stringify(appratusPosition));
        formData.append('scorePosition', JSON.stringify(scorePosition));
        formData.append('dobPosition', JSON.stringify(dobPosition));
        formData.append('clubPosition', JSON.stringify(clubPosition));
        formData.append('ageGroupPosition', JSON.stringify(ageGroupPosition));
        formData.append('appratus', game);
        formData.append('eventName', eventName)
        formData.append('eventId', eventId);
        // formData.append('score', totalAllAround)

      try {
        await axios.post(`${API_URL}/api/generate-certificates-allaround`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        alert('Certificates sent successfully');
      } catch (error) {
        console.error('Error sending certificates:', error);
        alert('Failed to send certificates');
      }
    } else {
      alert('Please provide recipient email and upload a background image');
    }
  };

  

  const move = (setPosition, direction) => {
    setPosition(prev => {
      const newPosition = { ...prev };
      switch (direction) {
        case 'left':
          newPosition.left -= 10;
          break;
        case 'right':
          newPosition.left += 10;
          break;
        case 'up':
          newPosition.top -= 10;
          break;
        case 'down':
          newPosition.top += 10;
          break;
        default:
          break;
      }
      return newPosition;
    });
  };


  if (userLoading || loading) {
    return <Spinner />;
  }

  if (error) {
    return <Text>{error}</Text>;
  }

  return (
    <Box minHeight="100vh" width="100%" bg="black" p={4}>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Button onClick={handleBack}>Back</Button>
        <Heading color="white" as="h3">{game}</Heading>
          <>
            <Input 
              placeholder="Recipient Email" 
              value={recipientEmail} 
              onChange={(e) => setRecipientEmail(e.target.value)} 
              mb={4}
              size="md"
            />
            <Input 
              type="file" 
              accept="image/png, image/jpeg" 
              onChange={handleFileUpload} 
              mb={4}
            />
            <Button bg="#ffbf00" onClick={sendCertificates}>Generate and Send Certificates</Button>
          </>
        {/* <Button onClick={fetchPlayers}>Refresh</Button> */}
        <Box>
          <br/>
        <br/>
      </Box>
      </Box>
      <br/>
      {selectedFile && (
        <Flex>
        <Box
          bgImage={`url(${URL.createObjectURL(selectedFile)})`}
          bgSize="cover"
          width="1123px"  // Landscape A4 width in pixels at 96 DPI
          height="794px"  // Landscape A4 height in pixels at 96 DPI
          p={4}
          position={"relative"}
          border={"1px solid white"}
        >
          <Text fontSize="22px" marginTop={"-10px"} w={"100%"} position="absolute"  top={`${namePosition.top}px`} left={`${namePosition.left}px`}>Awarded to: Aniket Rasal</Text>
          <Text fontSize="22px" marginTop={"-10px"} w={"100%"} position={"absolute"} top={`${rankPosition.top}px`} left={`${rankPosition.left}px`} fontWeight={"500"}>Rank 1</Text>
          <Text fontSize="22px" marginTop={"-10px"} w={"100%"} position="absolute"  top={`${appratusPosition.top}px`} left={`${appratusPosition.left}px`}>Appratus</Text>
          <Text fontSize="22px" marginTop={"-10px"} w={"100%"} position="absolute"  top={`${scorePosition.top}px`} left={`${scorePosition.left}px`}>Score</Text>
          <Text fontSize="22px" marginTop={"-10px"} w={"100%"} position="absolute"  top={`${dobPosition.top}px`} left={`${dobPosition.left}px`}>dd/mm/yyyy</Text>
          <Text fontSize="22px" marginTop={"-10px"} w={"100%"} position="absolute"  top={`${clubPosition.top}px`} left={`${clubPosition.left}px`}>Club</Text>
          <Text fontSize="22px" marginTop={"-10px"} w={"100%"} position="absolute"  top={`${ageGroupPosition.top}px`} left={`${ageGroupPosition.left}px`}>Age Group</Text>
        </Box>
        <Box>
        <Button colorScheme="blue" onClick={() => move(setNamePosition, 'up')}>Name U</Button>
        <br/>
      <Button colorScheme="blue" onClick={() => move(setNamePosition, 'down')}>Name D</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setNamePosition, 'left')}>Name L</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setNamePosition, 'right')}>Name R</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setRankPosition, 'left')}>Rank L</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setRankPosition, 'right')}>Rank R</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setRankPosition, 'up')}>Rank U</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setRankPosition, 'down')}>Rank D</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setAppratusPosition, 'up')}>Appratus U</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setAppratusPosition, 'down')}>Appratus D</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setAppratusPosition, 'left')}>Appratus L</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setAppratusPosition, 'right')}>Appratus R</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setScorePosition, 'up')}>Score U</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setScorePosition, 'down')}>Score D</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setScorePosition, 'left')}>Score L</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setScorePosition, 'right')}>Score R</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setDobPosition, 'up')}>DOB U</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setDobPosition, 'down')}>DOB D</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setDobPosition, 'left')}>DOB L</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setDobPosition, 'right')}>DOB R</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setClubPosition, 'up')}>Club U</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setClubPosition, 'down')}>Club D</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setClubPosition, 'left')}>Club L</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setClubPosition, 'right')}>Club R</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setAgeGroupPosition, 'up')}>Age Group U</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setAgeGroupPosition, 'down')}>Age Group D</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setAgeGroupPosition, 'left')}>Age Group L</Button>
      <br/>
      <Button colorScheme="blue" onClick={() => move(setAgeGroupPosition, 'right')}>Age Group R</Button>
      
        </Box>
        </Flex>
      )}
      <br />
      <div ref={componentPDF} style={{ width: '100%' }}>
        {allPlayers.length > 0 ? (
          <>
            <Table
              width="100%"
              margin="auto"
              borderRadius="20px"
              bg="purple"
              color="white"
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 15px 25px, rgba(0, 0, 0, 0.22) 0px 10px 10px',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <Thead>
                <Tr>
                  <Th color={"white"}>SR</Th>
                  <Th color={"white"}>Name</Th>
                  <Th color={"white"}>Athlete Code</Th>
                  <Th color={"white"}>Team name</Th>
                  <Th color={"white"}>State</Th>
                  <Th color={"white"}>Club</Th>
                  <Th color={"white"}>Total</Th>
                  <Th color={"white"}>Rank</Th>
                </Tr>
              </Thead>
              <Tbody>
                {allPlayers.filter(player => [1, 2, 3].includes(player.rank)).map((player, index) => (
                  <Tr key={player._id} style={{ position: 'relative', overflow: 'hidden', backgroundColor: index % 2 === 1 ? 'purple' : 'white', color: index % 2 === 1 ? 'white' : 'black' }}>
                    <Td fontWeight="bold">{index + 1}</Td>
                    <Td fontWeight="bold">{player.name}</Td>
                    <Td fontWeight="bold">{player.athleteCode}</Td>
                    <Td fontWeight="bold">{player.teamName}</Td>
                    <Td fontWeight="bold">{player.state}</Td>
                    <Td fontWeight="bold">{player.club}</Td>
                    <Td fontWeight="bold">{player.totalAllAround}</Td>
                    <Td fontWeight="bold">
                      <Flex flex={1} alignItems={"center"}>
                        {player.rank}
                        <FaMedal color={"#ffbf00"} style={{ marginLeft: '10px' }} />
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </>
        ) : (
          <Text color="white">No players found</Text>
        )}
      </div>
    </Box>
  );
};

export default CertificatesMertiAllAround;