import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, Toaster } from "react-hot-toast";
import { Box, Text, VStack, List, ListItem, Spinner, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input, Td, Th, Table, Thead, Tr, Tbody, Flex, } from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { MdEdit } from "react-icons/md";
import {API_URL} from "../utils/apiConfig";

const PlayerScoreDetailsPage = () => {
  const { eventId, game, playerId } = useParams();
  const { user, userSupervisorRole, loading: userLoading } = useAuth();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [score, setScore] = useState('');
  const [penalty, setPenalty] = useState('');
  const [total, setTotal] = useState(0);
  const [isScoreModalOpen, setIsScoreModalOpen] = useState(false);
  const [isPenaltyModalOpen, setIsPenaltyModalOpen] = useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [judgeToEdit, setJudgeToEdit] = useState('');
  const navigate = useNavigate();
  const [initialScores, setInitialScores] = useState(null); // Track initial scores
  const [isChangesSaved, setIsChangesSaved] = useState(true); // Track if changes are saved
  const [disab, isDisab] = useState(true);

  useEffect(() => {
    if (!userLoading) {
      if (!user || userSupervisorRole !== 'supervisor') {
        // If not a supervisor, redirect to the supervisor dashboard or show a "Not Authorized" page
        navigate('/supervisor');
        return;
      }

      const fetchEvent = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/events/${eventId}`);
          const eventDetails = response.data;
          setEvent(eventDetails);
          if (eventDetails && eventDetails.supervisors) {
            const supervisors = eventDetails.supervisors[game];
            const isSupervisor = supervisors && Object.values(supervisors).some(
              supervisor => supervisor && (supervisor.email === user.email || supervisor.mobile === user.phoneNumber)
            )
            if (!isSupervisor) {
                navigate('/notaccess'); // Redirect if not authorized
                return;
              }           
            const gamePlayers = eventDetails.players[game];
            const currentPlayer = gamePlayers.find(player => player._id === playerId);
            setSelectedPlayer(currentPlayer);
            if (currentPlayer) {
              updateTotalScore(currentPlayer.scores);
            }
          } else {
            navigate('/notaccess'); // Redirect if the game is not found or not authorized
            console.error('Event not found');
          }
        } catch (error) {
          console.error('Error fetching event:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchEvent();
    }
  }, [userLoading, user, userSupervisorRole, eventId, game, playerId, navigate]);

  useEffect(() => {
    if (selectedPlayer && initialScores) {
      const hasChanges = JSON.stringify(selectedPlayer.scores) !== JSON.stringify(initialScores);
      setIsChangesSaved(!hasChanges); // Enable Save Changes button if there are changes
      isDisab(true)
    }
  }, [selectedPlayer, initialScores]);

  const openScoreModal = (judge) => {
    setJudgeToEdit(judge);
    setScore(selectedPlayer.scores[judge] || '');
    setIsScoreModalOpen(true);
  };

  const openPenaltyModal = () => {
    setPenalty(selectedPlayer.scores.penalty || '');
    setIsPenaltyModalOpen(true);
  };

  const closeModal = () => {
    setScore('');
    setPenalty('');
    setIsScoreModalOpen(false);
    setIsPenaltyModalOpen(false);
    setIsApproveModalOpen(false);
  };

  const openApproveModal = () => {
    setIsApproveModalOpen(true);
  };

  const handleApprove = async () => {
    try {
      const updatedEvent = { ...event };
      if (!updatedEvent.players[game]) {
        console.error('No players for this game');
        return;
      }
      const playerIndex = updatedEvent.players[game].findIndex(player => player._id === selectedPlayer._id);
      if (playerIndex === -1) {
        console.error('Player not found');
        return;
      }

      updatedEvent.players[game][playerIndex].status = 'completed';
      updatedEvent.players[game][playerIndex].approve = true;

      // Save the updated status to the server
      await axios.put(`${API_URL}/api/events/${eventId}`, updatedEvent);

      setEvent(updatedEvent);
      setSelectedPlayer(updatedEvent.players[game][playerIndex]); // Update the selected player with the new status
      toast.success("Player status updated to completed");
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Error updating status');
    } finally {
      closeModal();
    }
  };

  const calculateAverage = (scores) => {
    if (!scores || !Array.isArray(scores) || scores.length <= 2) {
      return 0; // Not enough scores to calculate an average after removing the highest and lowest
    }
    scores.sort((a, b) => a - b);
    const filteredScores = scores.slice(1, -1);
    const totalScore = filteredScores.reduce((acc, score) => acc + score, 0);
    const numberOfScores = filteredScores.length;
    return (totalScore / numberOfScores).toFixed(2);
  };

  const updateTotalScore = (scores) => {
    const judgeScores = [
      scores.judge1,
      scores.judge2,
      scores.judge3,
      scores.judge4
    ].filter(score => score !== undefined);

    const averageE = parseFloat(calculateAverage(judgeScores));
    const d1Score = scores.d1 ? parseFloat(scores.d1) : 0;
    const penaltyScore = scores.penalty ? parseFloat(scores.penalty) : 0;
    
    const totalScore = (averageE + d1Score) - penaltyScore;
    setTotal(totalScore.toFixed(2));
  };

  const handleScoreSubmit = async () => {
    if (judgeToEdit === '') {
      return;
    }
    try {
      const updatedEvent = { ...event };
      if (!updatedEvent.players[game]) {
        console.error('No players for this game');
        return;
      }
      const playerIndex = updatedEvent.players[game].findIndex(player => player._id === selectedPlayer._id);
      if (playerIndex === -1) {
        console.error('Player not found');
        return;
      }
      
      updatedEvent.players[game][playerIndex].scores = updatedEvent.players[game][playerIndex].scores || {};
      updatedEvent.players[game][playerIndex].scores[judgeToEdit] = parseFloat(score);

      // Update the total score
      updateTotalScore(updatedEvent.players[game][playerIndex].scores);

      // Save the updated scores and total to the server
      await axios.put(`${API_URL}/api/events/${eventId}`, updatedEvent);

      setEvent(updatedEvent);
      setSelectedPlayer(updatedEvent.players[game][playerIndex]); // Update the selected player with new score
      isDisab(false);
      closeModal();
    } catch (error) {
      console.error('Error updating score:', error);
    }
  };

  const handlePenaltySubmit = async () => {
    try {
      const updatedEvent = { ...event };
      if (!updatedEvent.players[game]) {
        console.error('No players for this game');
        return;
      }
      const playerIndex = updatedEvent.players[game].findIndex(player => player._id === selectedPlayer._id);
      if (playerIndex === -1) {
        console.error('Player not found');
        return;
      }
      updatedEvent.players[game][playerIndex].scores = updatedEvent.players[game][playerIndex].scores || {};
      updatedEvent.players[game][playerIndex].scores.penalty = parseFloat(penalty);

      // Update the total score
      updateTotalScore(updatedEvent.players[game][playerIndex].scores);

      // Save the updated penalty and total to the server
      await axios.put(`${API_URL}/api/events/${eventId}`, updatedEvent);

      setEvent(updatedEvent);
      setSelectedPlayer(updatedEvent.players[game][playerIndex]); // Update the selected player with new penalty
      isDisab(false);
      closeModal();
    } catch (error) {
      console.error('Error updating penalty:', error);
    }
  };

  const handleSaveChanges = async () => {
    try {
      const updatedEvent = { ...event };
      if (!updatedEvent.players[game]) {
        console.error('No players for this game');
        return;
      }
      const playerIndex = updatedEvent.players[game].findIndex(player => player._id === selectedPlayer._id);
      if (playerIndex === -1) {
        console.error('Player not found');
        return;
      }

      // Calculate the average score using the calculateAverage function
      const averageScore = calculateAverage([
        updatedEvent.players[game][playerIndex].scores.judge1,
        updatedEvent.players[game][playerIndex].scores.judge2,
        updatedEvent.players[game][playerIndex].scores.judge3,
        updatedEvent.players[game][playerIndex].scores.judge4
      ].filter(score => score !== undefined))
      console.log("avd score", averageScore);

      const result = ( Number (calculateAverage([
        updatedEvent.players[game][playerIndex].scores.judge1,
        updatedEvent.players[game][playerIndex].scores.judge2,
        updatedEvent.players[game][playerIndex].scores.judge3,
        updatedEvent.players[game][playerIndex].scores.judge4
      ].filter(score => score !== undefined))) + Number(updatedEvent.players[game][playerIndex].scores.d1) - Number(updatedEvent.players[game][playerIndex].scores.penalty));
      console.log("Total Result", result);
  

      updatedEvent.players[game][playerIndex].scores.total = total;
      updatedEvent.players[game][playerIndex].scores.e = averageScore;

      // Save the updated event including the total score to the server
      await axios.put(`${API_URL}/api/events/${eventId}`, updatedEvent);

      setEvent(updatedEvent);
      setSelectedPlayer(updatedEvent.players[game][playerIndex]); // Update the selected player with the new total
      setInitialScores(updatedEvent.players[game][playerIndex].scores); // Reset initial scores to the updated scores
      setIsChangesSaved(true); // Disable the Save Changes button after saving
      isDisab(true);
      // toast.success("Score Updated Successfully");
      toast.success("Score Updated Successfully")
      
    } catch (error) {
      console.error('Error saving changes:', error);
      toast.error(error)
    }
  };

  const handleBack = (playerId) => {
    navigate(`/supervisorpanel/${eventId}/${game}`);
  };

  if (loading || userLoading) {
    return <Spinner />;
  }

  if (!user || userSupervisorRole !== 'supervisor') {
    return <Text>You are not authorized to view this page.</Text>;
  }

  return (
    <Box p={4} bg={"#26263c"}>
      <Toaster toastOptions={{ duration: 4000 }} />
      <Flex flex={1} flexWrap={"wrap"} gap={{base:"30px", md:"0px", lg:"0px"}}  w={"100%"} m={"auto"}>
        <Box w={{base:"50%", md:"auto", lg:"auto"}}>
        <Button onClick={handleBack} mb={4}>Back</Button>
        </Box>
        <Box w={{base:"100%", md:"60%", lg:"60%"}} m={"auto"}>
        <Flex bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"  borderRadius={"20px"} flex={1} flexWrap={"wrap"} justifyContent={{base:"start", md:"space-between", lg:"space-between"}} mr={{base:"auto", md:"auto", lg:"65px"}} alignItems={"center"}>
        <Box ml={{base:"20px", md:"auto", lg:"20px"}}>
          <Text fontFamily={"sans-serif"} fontWeight={"bold"} fontSize="17px" color={"black"} mb={4} mt={4}>Name: {selectedPlayer?.name}</Text>
          <Text fontWeight={"bold"} fontSize="17px" color={"black"} mb={4}>Email: {selectedPlayer?.email || "abc@gmail.com"}</Text>
        </Box>
        <Box ml={{base:"20px", md:"auto", lg:"20px"}} mr={{base:"0px", md:"auto", lg:"20px"}}>
          <Text fontWeight={"bold"} fontSize="17px" color={"black"} mb={4} mt={4}>AthleteCode: {selectedPlayer?.athleteCode || "000000"}</Text>
          <Text fontWeight={"bold"} fontSize="17px" color={"black"} mb={4}>Appratus: {game}</Text>
        </Box>
        </Flex>
        </Box>
      </Flex>
      {/* <Text fontSize="2xl" mb={4}>{selectedPlayer?.name}'s Score Details</Text> */}
      <VStack align="start" mt={4}>
        {/* <Text><strong>Total:</strong> {total}</Text> */}
        {selectedPlayer && (
          <Table color={"black"} textAlign={"center"} variant="striped" width={{base:"100%", md:"55%", lg:"55%"}} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray">
          <Thead>
            <Tr p={"10px"}>
              <Th color={"white"} >Type</Th>
              <Th color={"white"} textAlign={"center"}>Score</Th>
              <Th color={"white"} textAlign={"end"}>Action</Th>
              {/* <Th color={"white"}>E3</Th>
              <Th color={"white"}>E4</Th>
              <Th color={"white"}>D</Th>
              <Th color={"white"}>E</Th>
              <Th color={"white"}>Penalty</Th>
              <Th color={"white"}>Total</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
            <Td fontWeight={"bold"}>E1</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{selectedPlayer.scores.judge1 || 'N/A'}</Td>
            <Td textAlign={"end"}><Button size="xs" onClick={() => openScoreModal('judge1')}><MdEdit style={{fontSize:"25px", color:"green"}} /></Button></Td>
            </Tr>
            <Tr>
            <Td fontWeight={"bold"}>E2</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{selectedPlayer.scores.judge2 || 'N/A'}</Td>
            <Td textAlign={"end"}><Button background={"none"} size="xs" onClick={() => openScoreModal('judge2')}><MdEdit style={{fontSize:"25px", color:"green"}} /></Button></Td>
            </Tr>
            <Tr>
            <Td fontWeight={"bold"}>E3</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{selectedPlayer.scores.judge3 || 'N/A'}</Td>
            <Td textAlign={"end"}><Button size="xs" onClick={() => openScoreModal('judge3')}><MdEdit style={{fontSize:"25px", color:"green"}} /></Button></Td>
            </Tr>
            <Tr>
            <Td fontWeight={"bold"}>E4</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{selectedPlayer.scores.judge4 || 'N/A'}</Td>
            <Td textAlign={"end"}><Button background={"none"} size="xs" onClick={() => openScoreModal('judge4')}><MdEdit style={{fontSize:"25px", color:"green"}} /></Button></Td>
            </Tr>
            <Tr>
            <Td fontWeight={"bold"}>D</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{selectedPlayer.scores.d1 || 'N/A'}</Td>
            <Td textAlign={"end"}><Button size="xs" onClick={() => openScoreModal('d1')}><MdEdit style={{fontSize:"25px", color:"green"}} /></Button></Td>
            </Tr>
            <Tr>
            <Td fontWeight={"bold"}>E</Td>
            <Td fontWeight={"bold"} textAlign={"center"}><Text>{calculateAverage([
              selectedPlayer.scores.judge1,
              selectedPlayer.scores.judge2,
              selectedPlayer.scores.judge3,
              selectedPlayer.scores.judge4
            ].filter(score => score !== undefined))}</Text></Td>
            </Tr>
            <Tr>
            <Td fontWeight={"bold"}>Penalty</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{selectedPlayer.scores.penalty || 'N/A'}</Td>
            <Td textAlign={"end"}><Button size="xs" onClick={() => openPenaltyModal('d1')}><MdEdit style={{fontSize:"25px", color:"green"}} /></Button></Td>
            </Tr>
            <Tr>
            <Td fontWeight={"bold"}>Total</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{total || 'N/A'}</Td>
            </Tr>
          </Tbody>
        </Table>
        )}
      </VStack>
      <Box w={"55%"} m={"auto"}>
        <Box textAlign={{base:"center", md:"end", lg:"end"}}>
        <Button mt={4} colorScheme="green" isDisabled={disab}  onClick={handleSaveChanges}>Save Changes</Button>
        <Button mt={4} colorScheme="blue" onClick={openApproveModal}>Approve</Button> {/* Approve Button */}
        </Box>
      
      </Box>


      {/* Score Edit Modal */}
      <Modal isOpen={isScoreModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit ({judgeToEdit === "judge1" ? "E1" : judgeToEdit === "judge2"? "E2" : judgeToEdit === "judge3"? "E3" : judgeToEdit === "judge4"? "E4" : judgeToEdit === "d1"? "D" : judgeToEdit}) Score of {selectedPlayer?.name} </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="number"
              id="score"
              value={score}
              onChange={(e) => setScore(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={handleScoreSubmit}>Save</Button>
            <Button variant="ghost" onClick={closeModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Penalty Edit Modal */}
      <Modal isOpen={isPenaltyModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Penalty for {selectedPlayer?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="number"
              id="penalty"
              value={penalty}
              onChange={(e) => setPenalty(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handlePenaltySubmit}>Save</Button>
            <Button variant="ghost" onClick={closeModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      {/* Approve Modal */}
      <Modal isOpen={isApproveModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Approve Player</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to mark this player as completed?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleApprove}>Approve</Button>
            <Button variant="ghost" onClick={closeModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PlayerScoreDetailsPage;
