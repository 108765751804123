import {
    ChakraProvider,
    Box,
    VStack,
    HStack,
    useDisclosure,
    Heading,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Text,
    IconButton,
    Flex,
    SimpleGrid,
  } from "@chakra-ui/react";
  import cardBackground from "../cardBackground.jpg"; // Make sure the path is correct
  import { BsThreeDotsVertical } from "react-icons/bs";
  import React, { useState, useEffect } from "react";
  import axios from "axios";
  import ManageEventModal from '../pages/ManageEvent';
  import { useAuth } from '../context/AuthContext';
  import { Link, useNavigate } from "react-router-dom";
import SimpleSidebar from "./AdminEventSidebar";
import {API_URL} from "../utils/apiConfig";
  
  const AdminEvents = () => {
    const [events, setEvents] = useState([]);
    const [selectedEventId, setSelectedEventId] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { user, loading: userLoading } = useAuth();
    const navigate = useNavigate();
  
    // Function to fetch and filter events
    const fetchAndFilterEvents = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/events-admin`, {
          params: { email: user.email } // Sending the email as a query parameter
        });
        
        // Since the backend is now filtering the events, no need to filter here
        const filteredEvents = response.data;
        console.log("FilteredEvents", filteredEvents);
        filteredEvents.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
        
        setEvents(filteredEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    
  
    useEffect(() => {
      if (!userLoading && user) {  // Ensure we have user info before fetching
        fetchAndFilterEvents();
      }
    }, [user, userLoading]); // Fetch events when user data is available
  
    const handleManage = (id) => {
      setSelectedEventId(id);
      onOpen();
    };
  
    const handleDelete = async (id) => {
      try {
        await axios.delete(`${API_URL}/api/events/${id}`);
        setEvents(events.filter((event) => event._id !== id));
      } catch (error) {
        console.error("Error deleting event:", error);
      }
    };

    const capitalizeFirstLetter = (string) => {
      if (!string) return '';
      return string.charAt(0).toUpperCase() + string.slice(1);
    };
  
    return (
      <VStack spacing={4} mt={4}>
        <SimpleGrid columns={[1, 2, 3]} spacing={10}>
        {events.length === 0 ? (
            <Box>
        <Text>No events available</Text>
        
          </Box>
      ) : (
          events.map((event) => (
            <Card
              sx={{
                backgroundImage: `url(${cardBackground})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                color: "white",
                borderRadius: "15px",
              }}
              maxW="sm"
              align="center"
              key={event._id}
            >
              <CardHeader
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  width: "100%",
                  textAlign: "center",
                  borderRadius: "15px 15px 0 0",
                  width: "100%",
                }}
              >
                <Flex
                  spacing="4"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Heading marginRight={"10px"} size='md'>{event.category === "men"? "MAG":"WAG"}</Heading>
                  <IconButton
                    variant="ghost"
                    colorScheme="gray"
                    color={"white"}
                    sx={{
                      _hover: {
                        color: "black",
                        backgroundColor: "gray.200",
                        borderRadius: "50%",
                      },
                    }}
                    aria-label="See menu"
                    icon={<BsThreeDotsVertical />}
                  />
                </Flex>
              </CardHeader>
              <CardBody
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Heading marginRight={"10px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" bgClip={"text"} size="lg">
                  {capitalizeFirstLetter(event.competitionLevel)} Level
                  </Heading>
                <Heading marginRight={"10px"} size="lg">
                  {event.eventName}
                </Heading>
                <Text>{new Date(event.dateTime).toLocaleString()}</Text>
                <Text>For live scoring go to <Link to={"https://www.gymnazien.com/live"}><Text fontSize={"18px"} bgClip={"text"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"> Gymnazien Live Scoring</Text></Link></Text>
              </CardBody>
              <CardFooter w={"100%"}>
                <Flex
                  spacing="4"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  w={"100%"}
                >
                  <Link to={`/adminEvents/${event._id}`}>
                    <Button
                      sx={{
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        color: "white",
                        border: "1px solid white",
                        _hover: {
                          backgroundColor: "rgba(255, 255, 255, 0.5)",
                        },
                        _active: {
                          backgroundColor: "rgba(255, 255, 255, 0.)",
                        },
                      }}
                      margin={"auto"}
                      colorScheme="blue"
                    >
                      View here
                    </Button>
                  </Link>
                  <Button
                    sx={{
                      backgroundColor: "#ffbf00",
                      color: "white",
                      _hover: {
                        bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
                      },
                      _active: {
                        backgroundColor: "rgba(255, 255, 255, 0.)",
                      },
                    }}
                    margin={"auto"}
                    colorScheme="blue"
                    bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
                    onClick={() => handleManage(event._id)}
                  >
                    Manage Competition
                  </Button>
                </Flex>
              </CardFooter>
            </Card>
          ))
        )}
        </SimpleGrid>
        {selectedEventId && (
          <ManageEventModal
            isOpen={isOpen}
            onClose={onClose}
            eventId={selectedEventId}
            fetchEvents={fetchAndFilterEvents}  // Reuse the same filtering logic
          />
        )}
        <br/>
        
      </VStack>
    );
  };
  
  export default AdminEvents;
  