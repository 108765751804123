import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Text, VStack, List, ListItem, Spinner, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input } from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import {API_URL} from "../utils/apiConfig";

const PlayerScoreDetailsPageAdmin = () => {
  const { eventId, game, playerId } = useParams();
  const { user, userSupervisorRole, loading: userLoading } = useAuth();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [score, setScore] = useState('');
  const [penalty, setPenalty] = useState('');
  const [total, setTotal] = useState(0);
  const [isScoreModalOpen, setIsScoreModalOpen] = useState(false);
  const [isPenaltyModalOpen, setIsPenaltyModalOpen] = useState(false);
  const [judgeToEdit, setJudgeToEdit] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!userLoading) {
      if (!user || userSupervisorRole !== 'supervisor') {
        // If not a supervisor, redirect to the supervisor dashboard or show a "Not Authorized" page
        navigate('/supervisor');
        return;
      }

      const fetchEvent = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/events/${eventId}`);
          const eventDetails = response.data;
          setEvent(eventDetails);
          if (eventDetails && eventDetails.event_admin) {
            const isEventAdmin = eventDetails.event_admin === user.email;

            if(!isEventAdmin){
              navigate('/notaccess');
              return;
            }
            const gamePlayers = eventDetails.players[game];
            const currentPlayer = gamePlayers.find(player => player._id === playerId);
            setSelectedPlayer(currentPlayer);
            if (currentPlayer) {
              updateTotalScore(currentPlayer.scores);
            }
          } else {
            navigate('/notaccess'); // Redirect if the game is not found or not authorized
            console.error('Event not found');
          }
        } catch (error) {
          console.error('Error fetching event:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchEvent();
    }
  }, [userLoading, user, userSupervisorRole, eventId, game, playerId, navigate]);

  const openScoreModal = (judge) => {
    setJudgeToEdit(judge);
    setScore(selectedPlayer.scores[judge] || '');
    setIsScoreModalOpen(true);
  };

  const openPenaltyModal = () => {
    setPenalty(selectedPlayer.scores.penalty || '');
    setIsPenaltyModalOpen(true);
  };

  const closeModal = () => {
    setScore('');
    setPenalty('');
    setIsScoreModalOpen(false);
    setIsPenaltyModalOpen(false);
  };

  const calculateAverage = (scores) => {
    if (!scores || !Array.isArray(scores) || scores.length <= 2) {
      return 0; // Not enough scores to calculate an average after removing the highest and lowest
    }
    scores.sort((a, b) => a - b);
    const filteredScores = scores.slice(1, -1);
    const totalScore = filteredScores.reduce((acc, score) => acc + score, 0);
    const numberOfScores = filteredScores.length;
    return (totalScore / numberOfScores).toFixed(2);
  };

  const updateTotalScore = (scores) => {
    const judgeScores = [
      scores.judge1,
      scores.judge2,
      scores.judge3,
      scores.judge4
    ].filter(score => score !== undefined);

    const averageE = parseFloat(calculateAverage(judgeScores));
    const d1Score = scores.d1 ? parseFloat(scores.d1) : 0;
    const penaltyScore = scores.penalty ? parseFloat(scores.penalty) : 0;
    
    const totalScore = (averageE + d1Score) - penaltyScore;
    setTotal(totalScore.toFixed(2));
  };

  const handleScoreSubmit = async () => {
    if (judgeToEdit === '') {
      return;
    }
    try {
      const updatedEvent = { ...event };
      if (!updatedEvent.players[game]) {
        console.error('No players for this game');
        return;
      }
      const playerIndex = updatedEvent.players[game].findIndex(player => player._id === selectedPlayer._id);
      if (playerIndex === -1) {
        console.error('Player not found');
        return;
      }
      
      updatedEvent.players[game][playerIndex].scores = updatedEvent.players[game][playerIndex].scores || {};
      updatedEvent.players[game][playerIndex].scores[judgeToEdit] = parseFloat(score);

      // Update the total score
      updateTotalScore(updatedEvent.players[game][playerIndex].scores);

      // Save the updated scores and total to the server
      await axios.put(`${API_URL}/api/events/${eventId}`, updatedEvent);

      setEvent(updatedEvent);
      setSelectedPlayer(updatedEvent.players[game][playerIndex]); // Update the selected player with new score
      closeModal();
    } catch (error) {
      console.error('Error updating score:', error);
    }
  };

  const handlePenaltySubmit = async () => {
    try {
      const updatedEvent = { ...event };
      if (!updatedEvent.players[game]) {
        console.error('No players for this game');
        return;
      }
      const playerIndex = updatedEvent.players[game].findIndex(player => player._id === selectedPlayer._id);
      if (playerIndex === -1) {
        console.error('Player not found');
        return;
      }
      updatedEvent.players[game][playerIndex].scores = updatedEvent.players[game][playerIndex].scores || {};
      updatedEvent.players[game][playerIndex].scores.penalty = parseFloat(penalty);

      // Update the total score
      updateTotalScore(updatedEvent.players[game][playerIndex].scores);

      // Save the updated penalty and total to the server
      await axios.put(`${API_URL}/api/events/${eventId}`, updatedEvent);

      setEvent(updatedEvent);
      setSelectedPlayer(updatedEvent.players[game][playerIndex]); // Update the selected player with new penalty
      closeModal();
    } catch (error) {
      console.error('Error updating penalty:', error);
    }
  };

  const handleSaveChanges = async () => {
    try {
      const updatedEvent = { ...event };
      if (!updatedEvent.players[game]) {
        console.error('No players for this game');
        return;
      }
      const playerIndex = updatedEvent.players[game].findIndex(player => player._id === selectedPlayer._id);
      if (playerIndex === -1) {
        console.error('Player not found');
        return;
      }

      // Calculate the average score using the calculateAverage function
      const averageScore = calculateAverage([
        updatedEvent.players[game][playerIndex].scores.judge1,
        updatedEvent.players[game][playerIndex].scores.judge2,
        updatedEvent.players[game][playerIndex].scores.judge3,
        updatedEvent.players[game][playerIndex].scores.judge4
      ].filter(score => score !== undefined))
      console.log("avd score", averageScore);

      const result = ( Number (calculateAverage([
        updatedEvent.players[game][playerIndex].scores.judge1,
        updatedEvent.players[game][playerIndex].scores.judge2,
        updatedEvent.players[game][playerIndex].scores.judge3,
        updatedEvent.players[game][playerIndex].scores.judge4
      ].filter(score => score !== undefined))) + Number(updatedEvent.players[game][playerIndex].scores.d1) - Number(updatedEvent.players[game][playerIndex].scores.penalty));
      console.log("Total Result", result);
  

      updatedEvent.players[game][playerIndex].scores.total = total;
      updatedEvent.players[game][playerIndex].scores.e = averageScore;

      // Save the updated event including the total score to the server
      await axios.put(`${API_URL}/api/events/${eventId}`, updatedEvent);

      setEvent(updatedEvent);
      setSelectedPlayer(updatedEvent.players[game][playerIndex]); // Update the selected player with the new total
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };

  if (loading || userLoading) {
    return <Spinner />;
  }

  if (!user || userSupervisorRole !== 'supervisor') {
    return <Text>You are not authorized to view this page.</Text>;
  }

  return (
    <Box p={4}>
      <Text fontSize="2xl" mb={4}>{selectedPlayer?.name}'s Score Details</Text>
      <VStack align="start" mt={4}>
        <Text><strong>Total:</strong> {total}</Text>
        {selectedPlayer && (
          <List spacing={3}>
            <ListItem>
              <Text><strong>E1:</strong> {selectedPlayer.scores.judge1 || 'N/A'} <Button size="xs" onClick={() => openScoreModal('judge1')}>Edit</Button></Text>
            </ListItem>
            <ListItem>
              <Text><strong>E2:</strong> {selectedPlayer.scores.judge2 || 'N/A'} <Button size="xs" onClick={() => openScoreModal('judge2')}>Edit</Button></Text>
            </ListItem>
            <ListItem>
              <Text><strong>E3:</strong> {selectedPlayer.scores.judge3 || 'N/A'} <Button size="xs" onClick={() => openScoreModal('judge3')}>Edit</Button></Text>
            </ListItem>
            <ListItem>
              <Text><strong>E4:</strong> {selectedPlayer.scores.judge4 || 'N/A'} <Button size="xs" onClick={() => openScoreModal('judge4')}>Edit</Button></Text>
            </ListItem>
            <ListItem>
               {/* Average E Score */}
            <Text><strong>Total E:</strong> {calculateAverage([
              selectedPlayer.scores.judge1,
              selectedPlayer.scores.judge2,
              selectedPlayer.scores.judge3,
              selectedPlayer.scores.judge4
            ].filter(score => score !== undefined))}</Text>

            </ListItem>
            <ListItem>
              <Text><strong>Penalty:</strong> {selectedPlayer.scores.penalty || 'N/A'} <Button size="xs" onClick={openPenaltyModal}>Edit</Button></Text>
            </ListItem>
            <ListItem>
              <Text><strong>D1:</strong> {selectedPlayer.scores.d1 || 'N/A'} <Button size="xs" onClick={() => openScoreModal('d1')}>Edit</Button></Text>
            </ListItem>
          </List>
        )}
      </VStack>

      <Button mt={4} colorScheme="green" onClick={handleSaveChanges}>Save Changes</Button>

      {/* Score Edit Modal */}
      <Modal isOpen={isScoreModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Score for {selectedPlayer?.name} ({judgeToEdit})</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="number"
              id="score"
              value={score}
              onChange={(e) => setScore(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleScoreSubmit}>Save</Button>
            <Button variant="ghost" onClick={closeModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Penalty Edit Modal */}
      <Modal isOpen={isPenaltyModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Penalty for {selectedPlayer?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="number"
              id="penalty"
              value={penalty}
              onChange={(e) => setPenalty(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handlePenaltySubmit}>Save</Button>
            <Button variant="ghost" onClick={closeModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PlayerScoreDetailsPageAdmin;
