import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  Box, Text, VStack, Spinner, Td, Tr, Tbody, Table, Thead, Th, Button, Heading, Flex, Tabs, TabList, TabPanels, Tab, TabPanel 
} from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from '../../../context/AuthContext';
import { useReactToPrint } from "react-to-print";
import { API_URL } from "../../../utils/apiConfig";

const AppratusCertificateTab = () => {
    const { eventId } = useParams();
    const { user, userSupervisorRole, loading: userLoading } = useAuth();
    const [event, setEvent] = useState(null);
    const [allPlayers, setAllPlayers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(0);  // Track the active tab index
    const navigate = useNavigate();
    const componentPDF = useRef();

    useEffect(() => {
      if (!userLoading) {
        if (!user || userSupervisorRole !== 'supervisor') {
          navigate('/supervisor');
          return;
        }

        const fetchAllPlayers = async () => {
          try {
            const response = await axios.get(`${API_URL}/api/events/${eventId}`);
            const eventDetails = response.data;
            setEvent(eventDetails);
            console.log("Fetched Event Details:", eventDetails);

            if (eventDetails && eventDetails.players && eventDetails.sports) {
              const enabledGames = Object.keys(eventDetails.sports || {}).filter(game => eventDetails.sports[game]);

              const aggregatedPlayers = {};

              enabledGames.forEach((game) => {
                const gamePlayers = eventDetails.players[game] || [];

                gamePlayers.forEach(player => {
                  const uniqueId = player.email || player.mobile;

                  if (!aggregatedPlayers[uniqueId]) {
                    aggregatedPlayers[uniqueId] = {
                      name: player.name,
                      email: player.email,
                      scores: {},
                      teamName: 'N/A', // Default team name
                    };

                    // Check if player is in any team
                    let teamFound = false;
                    Object.keys(eventDetails.teams || {}).forEach((team) => {
                      const teamMembers = eventDetails.teams[team].gymnasts || [];
                      const match = teamMembers.some((member) => 
                        (member.email && member.email === player.email) || 
                        (member.mobile && member.mobile === player.mobile)
                      );
                      if (match) {
                        aggregatedPlayers[uniqueId].teamName = team;
                        teamFound = true;
                      }
                    });

                    if (!teamFound) {
                      aggregatedPlayers[uniqueId].teamName = player.city || 'N/A';
                      console.log(`Player ${player.name} not found in any team. Using city as team name.`);
                    }
                  }

                  // Store the total score for each apparatus (sport)
                  aggregatedPlayers[uniqueId].scores[game] = player.scores.total;
                });
              });

              const sortedPlayers = Object.values(aggregatedPlayers);
              setAllPlayers(sortedPlayers);
              console.log("Aggregated Players:", allPlayers);

              if (sortedPlayers.length === 0) {
                console.error('No players aggregated. Please check the data processing.');
              }
            } else {
              console.error('Event details are incomplete or missing.');
            }
          } catch (error) {
            console.error('Error fetching players:', error);
          } finally {
            setLoading(false);
          }
        };

        fetchAllPlayers();
      }
    }, [userLoading, user, userSupervisorRole, eventId, navigate]);

    const handleBack = () => {
      navigate(`/supervisor`);
    };

    const generatePDF = useReactToPrint({
      content: () => componentPDF.current,
      documentTitle: 'apparatus-scores',
      onAfterPrint: () => alert('PDF export successful'),
    });

    const getPlayerCertificateNumber = (playerEmail, sport) => {
      if (!event || !event.certificates || !event.certificates.merit || !event.certificates.merit[sport]) {
        return 'N/A';
      }

      const certificate = event.certificates.merit[sport].find(cert => cert.email === playerEmail);
      return certificate ? certificate.certificateNumber : 'N/A';
    };

    if (loading || userLoading) {
      return <div>Loading...</div>;
    }

    if (!user || userSupervisorRole !== 'supervisor') {
      return <div>You are not authorized to view this page.</div>;
    }

    return (
      <Box p={4} w={"100%"} minHeight={"100vh"} bg={"#26263c"}>
        <VStack align="start" mt={4}>
          <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-between"}>
            <Button onClick={handleBack} mb={4}>Back</Button>
            <Heading color={"white"} as="h3" mb={4}>Event Sports</Heading>
            <Button bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" onClick={generatePDF}>Export to PDF</Button>
          </Flex>

          <Box w={"100%"}>
            <Tabs onChange={(index) => setActiveTab(index)}> {/* Track active tab index */}
              <TabList w={"45%"} m={"auto"} color={"white"} border={"1px solid #26263c"}>
                {event && event.sports && Object.keys(event.sports).map(sport => (
                  event.sports[sport] && <Tab m={"auto"} bgGradient="linear(to-r, yellow.400, yellow.400, yellow.600)" borderRadius={"10px"} fontWeight={"bold"} key={sport}>{sport}</Tab>
                ))}
              </TabList>

              <TabPanels>
                {event && event.sports && Object.keys(event.sports).map((sport, index) => (
                  event.sports[sport] && (
                    <TabPanel key={sport}>
                      <Text color={"white"} textAlign={"center"} fontSize="2xl" mb={4}>{sport.charAt(0).toUpperCase() + sport.slice(1)} Result</Text>
                      <div ref={activeTab === index ? componentPDF : null} style={{ width: "100%", overflow: "scroll", overflowY: "hidden", overflowX: "revert-layer" }}>
                        {allPlayers.length > 0 ? (
                          <table style={{ width: '100%', borderCollapse: 'collapse', backgroundColor: 'white', tableLayout: 'fixed' }}>
                            <thead style={{ color: 'black' }}>
                              <tr>
                                <th style={{ padding: '10px', border: '1px solid #ddd', width: '7%' }}>SR</th>
                                <th style={{ padding: '10px', border: '1px solid #ddd', width: '20%' }}>Name</th>
                                <th style={{ padding: '10px', border: '1px solid #ddd', width: '15%' }}>Team/Dist</th>
                                <th style={{ padding: '10px', border: '1px solid #ddd', width: '10%' }}>{sport} Score</th>
                                <th style={{ padding: '10px', border: '1px solid #ddd', width: '7%' }}>RANK</th>
                                <th style={{ padding: '10px', border: '1px solid #ddd', width: '15%' }}>Certificate No.</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                [...allPlayers]
                                  .filter(player => player.scores[sport] !== undefined)
                                  .sort((a, b) => b.scores[sport] - a.scores[sport])
                                  .map((player, index) => (
                                    <tr key={index} style={{ textAlign: 'center' }}>
                                      <td style={{ padding: '10px', border: '1px solid #ddd' }}>{index + 1}</td>
                                      <td style={{ padding: '10px', border: '1px solid #ddd' }}>{player.name}</td>
                                      <td style={{ padding: '10px', border: '1px solid #ddd' }}>{player.teamName || 'N/A'}</td>
                                      <td style={{ padding: '10px', border: '1px solid #ddd' }}>
                                        {player.scores[sport] ? player.scores[sport].toFixed(2) : 'N/A'}
                                      </td>
                                      <td style={{ padding: '10px', border: '1px solid #ddd' }}>{index + 1}</td>
                                      <td style={{ padding: '10px', border: '1px solid #ddd' }}>
                                        {getPlayerCertificateNumber(player.email, sport)}
                                      </td>
                                    </tr>
                                  ))
                              }
                            </tbody>
                          </table>
                        ) : (
                          <Text>No players found for this sport.</Text>
                        )}
                      </div>
                    </TabPanel>
                  )
                ))}
              </TabPanels>
            </Tabs>
          </Box>
        </VStack>
      </Box>
    );
};

export default AppratusCertificateTab;
