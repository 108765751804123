// import React, { useState, useEffect, useRef } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { useAuth } from '../../context/AuthContext';
// import { useReactToPrint } from 'react-to-print';
// import { Box, Button, Heading, Text } from '@chakra-ui/react';
// import { API_URL } from "../../utils/apiConfig";

// const ExportAllAround = () => {
//   const { eventId } = useParams();
//   const { user, userSupervisorRole, loading: userLoading } = useAuth();
//   const [event, setEvent] = useState(null);
//   const [allPlayers, setAllPlayers] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate();
//   const componentPDF = useRef();

//   useEffect(() => {
//     if (!userLoading) {
//       if (!user || userSupervisorRole !== 'supervisor') {
//         navigate('/supervisor');
//         return;
//       }

//       const fetchAllPlayers = async () => {
//         try {
//           const response = await axios.get(`${API_URL}/api/events/${eventId}`);
//           const eventDetails = response.data;
//           setEvent(eventDetails);
//           console.log("Fetched Event Details:", eventDetails);
      
//           if (eventDetails && eventDetails.players && eventDetails.sports) {
//             const enabledGames = Object.keys(eventDetails.sports || {}).filter(game => eventDetails.sports[game]);
      
//             const aggregatedPlayers = {};
      
//             enabledGames.forEach((game) => {
//               const gamePlayers = eventDetails.players[game] || []; // Default to an empty array if no players
      
//               console.log(`Processing players for game: ${game}`, gamePlayers);
      
//               gamePlayers.forEach(player => {
//                 const uniqueId = player.email || player.mobile;
      
//                 if (!aggregatedPlayers[uniqueId]) {
//                   aggregatedPlayers[uniqueId] = {
//                     name: player.name,
//                     scores: {},
//                     totalAllAround: 0,
//                     teamName: 'N/A', // Default team name
//                   };
      
//                   // Check if player is in any team
//                   let teamFound = false;
//                   Object.keys(eventDetails.teams || {}).forEach((team) => {
//                     const teamMembers = eventDetails.teams[team].gymnasts || [];
//                     const match = teamMembers.some((member) => 
//                       (member.email && member.email === player.email) || 
//                       (member.mobile && member.mobile === player.mobile)
//                     );
//                     if (match) {
//                       aggregatedPlayers[uniqueId].teamName = team;
//                       teamFound = true;
//                     }
//                   });

//                   // If no team found, use player.city as teamName
//                   if (!teamFound) {
//                     aggregatedPlayers[uniqueId].teamName = player.city || 'N/A';
//                     console.log(`Player ${player.name} not found in any team. Using city as team name.`);
//                   }
      
//                   if (!teamFound) {
//                     console.log(`Player ${player.name} not found in any team.`);
//                   }
//                 }
      
//                 aggregatedPlayers[uniqueId].scores[game] = {
//                   e: player.scores.e,
//                   d1: player.scores.d1,
//                   penalty: player.scores.penalty,
//                   total: player.scores.total,
//                 };
      
//                 aggregatedPlayers[uniqueId].totalAllAround += player.scores.total;
//               });
//             });
      
//             const sortedPlayers = Object.values(aggregatedPlayers).sort((a, b) => b.totalAllAround - a.totalAllAround);
//             setAllPlayers(sortedPlayers);
      
//             if (sortedPlayers.length === 0) {
//               console.error('No players aggregated. Please check the data processing.');
//             }
//           } else {
//             console.error('Event details are incomplete or missing.');
//           }
//         } catch (error) {
//           console.error('Error fetching players:', error);
//         } finally {
//           setLoading(false);
//         }
//       };
      
//       fetchAllPlayers();
//     }
//   }, [userLoading, user, userSupervisorRole, eventId, navigate]);

//   const handleBack = () => {
//     navigate(`/supervisor`);
//   };

//   const generatePDF = useReactToPrint({
//     content: () => componentPDF.current,
//     documentTitle: 'all-sports-scores',
//     onAfterPrint: () => alert('PDF export successful'),
//   });

//   const handleSaveScores = async () => {
//     try {
//       // Prepare the data to update in the backend
//       const updatedEvent = {
//         ...event,
//         allPlayers: allPlayers.map(player => ({
//           email: player.email, // or use another unique identifier
//           totalAllAround: player.totalAllAround
//         }))
//       };
      
//       await axios.put(`${API_URL}/api/events/${eventId}`, updatedEvent);
//       alert('Scores saved successfully');
//     } catch (error) {
//       console.error('Error saving scores:', error);
//       alert('Failed to save scores');
//     }
//   };

//   if (loading || userLoading) {
//     return <div>Loading...</div>;
//   }

//   if (!user || userSupervisorRole !== 'supervisor') {
//     return <div>You are not authorized to view this page.</div>;
//   }

//   const enabledGames = event ? Object.keys(event.sports).filter(game => event.sports[game]) : [];

//   return (
//     <Box bg={"gray.200"} style={{ padding: '20px', minHeight: '100vh' }}>
//       <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
//         <button onClick={handleBack} style={{ padding: '10px 20px' }}>Back</button>
//         <Button onClick={generatePDF} style={{ padding: '10px 20px', border: 'none' }} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)">Export to PDF</Button>
//         <Button onClick={handleSaveScores} style={{ padding: '10px 20px', border: 'none' }} bgGradient="linear(to-r, green.400, green.500, green.600)">Save Scores</Button>
//       </div>
      
//       <div ref={componentPDF} style={{ width: '95%', margin: 'auto', overflowX: 'auto' }}>
//         <Box>
//           <Heading size="md" style={{ color: 'gray', textAlign: 'center' }}>{event.eventName}</Heading>
//           <Heading size="md" style={{ color: 'gray', textAlign: 'center' }}>All Around Result</Heading>
//           <Text textAlign={"center"} color={"grey"}>Date: {new Date(event.dateTime).toLocaleString()}</Text>
//         </Box>
//         {allPlayers.length > 0 ? (
//           <table style={{fontSize:"12px", width: '100%', borderCollapse: 'collapse', backgroundColor: 'white', tableLayout: 'fixed' }}>
//             <thead style={{ backgroundColor: '#FFC107' }}>
//               <tr>
//                 <th style={{ fontSize:"12px", padding: '10px', border: '1px solid #ddd', color: 'white', width: '10%' }}>SR</th>
//                 <th style={{ padding: '10px', border: '1px solid #ddd', color: 'white', width: '20%' }}>Name</th>
//                 <th style={{ padding: '10px', border: '1px solid #ddd', color: 'white', width: '20%' }}>Team/Dist</th>
//                 {enabledGames.map((game) => (
//                   <th key={game} style={{ padding: '10px', border: '1px solid #ddd', color: 'white', width: `${50 / enabledGames.length}%` }}>
//                     {game == "vaultWomen"? "V": game == "floor"? "F": game == "pommel_horse"? "PH": game == "rings"? "R": game == "vaultMen"? "V": game == "parallel_bars"? "PB": game == "horizontal_bar"? "HB": game == "uneven_bars"? "UB": game == "balance_beam"? "BB": game == "floor_exercise"? "FE": game}
//                   </th>
//                 ))}
//                 <th style={{ padding: '10px', border: '1px solid #ddd', color: 'white', width: '15%' }}>All Around</th>
//                 <th style={{ padding: '10px', border: '1px solid #ddd', color: 'white', width: '10%' }}>RANK</th>
//               </tr>
//             </thead>
//             <tbody>
//               {allPlayers.map((player, index) => (
//                 <tr key={index} style={{ textAlign: 'center' }}>
//                   <td style={{ padding: '10px', border: '1px solid #ddd' }}>{index + 1}</td>
//                   <td style={{ padding: '10px', border: '1px solid #ddd' }}>{player.name}</td>
//                   <td style={{ padding: '10px', border: '1px solid #ddd' }}>{player.teamName || 'N/A'}</td>
//                   {enabledGames.map((game) => (
//                     <td key={game} style={{ padding: '10px', border: '1px solid #ddd' }}>
//                       {player.scores[game] ? `${player.scores[game].total}` : 'N/A'}
//                     </td>
//                   ))}
//                   <td style={{ padding: '10px', border: '1px solid #ddd' }}>{player.totalAllAround.toFixed(2)}</td>
//                   <td style={{ padding: '10px', border: '1px solid #ddd' }}>{index + 1}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         ) : (
//           <div style={{ textAlign: 'center' }}>
//             <p>No players found.....</p>
//           </div>
//         )}
//       </div>
//     </Box>
//   );
// };

// export default ExportAllAround;
















// import React, { useState, useEffect, useRef } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { useAuth } from '../../context/AuthContext';
// import { useReactToPrint } from 'react-to-print';
// import { Box, Button, Heading, Text } from '@chakra-ui/react';

// const ExportAllAround = () => {
//   const { eventId } = useParams();
//   const { user, userSupervisorRole, loading: userLoading } = useAuth();
//   const [event, setEvent] = useState(null);
//   const [allPlayers, setAllPlayers] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate();
//   const componentPDF = useRef();

//   useEffect(() => {
//     if (!userLoading) {
//       if (!user || userSupervisorRole !== 'supervisor') {
//         navigate('/supervisor');
//         return;
//       }

//       const fetchAllPlayers = async () => {
//         try {
//           const response = await axios.get(`http://localhost:5000/api/events/${eventId}`);
//           const eventDetails = response.data;
//           setEvent(eventDetails);
//           console.log("AllAroundEventDetails", eventDetails);

//           if (eventDetails && eventDetails.players && eventDetails.sports) {
//             const enabledGames = Object.keys(eventDetails.sports).filter(game => eventDetails.sports[game]);

//             const aggregatedPlayers = {};

//             enabledGames.forEach((game) => {
//               const gamePlayers = eventDetails.players[game];
//               console.log("AllAroundGamePlayers", gamePlayers);

//               if (gamePlayers) {
//                 gamePlayers.forEach(player => {
//                   const uniqueId = player.email || player.mobile;

//                   if (!aggregatedPlayers[uniqueId]) {
//                     aggregatedPlayers[uniqueId] = {
//                       name: player.name,
//                       scores: {},
//                       totalAllAround: 0,
//                       teamName: 'N/A',
//                     };

//                     Object.keys(eventDetails.teams).some((team) => {
//                       const teamMembers = eventDetails.teams[team].gymnasts;
//                       const match = teamMembers.some((member) => 
//                         (member.email && member.email === player.email) || 
//                         (member.mobile && member.mobile === player.mobile)
//                       );
//                       if (match) {
//                         aggregatedPlayers[uniqueId].teamName = team;
//                         return true;
//                       }
//                       return false;
//                     });
//                   }

//                   aggregatedPlayers[uniqueId].scores[game] = {
//                     e: player.scores.e,
//                     d1: player.scores.d1,
//                     penalty: player.scores.penalty,
//                     total: player.scores.total,
//                   };

//                   aggregatedPlayers[uniqueId].totalAllAround += player.scores.total;
//                 });
//               }
//             });

//             const sortedPlayers = Object.values(aggregatedPlayers).sort((a, b) => b.totalAllAround - a.totalAllAround);
//             setAllPlayers(sortedPlayers);
//           } else {
//             console.error('No players or sports found');
//           }
//         } catch (error) {
//           console.error('Error fetching players:', error);
//         } finally {
//           setLoading(false);
//         }
//       };

//       fetchAllPlayers();
//     }
//   }, [userLoading, user, userSupervisorRole, eventId, navigate]);

//   const handleBack = () => {
//     navigate(`/supervisor`);
//   };

//   const generatePDF = useReactToPrint({
//     content: () => componentPDF.current,
//     documentTitle: 'all-sports-scores',
//     onAfterPrint: () => alert('PDF export successful'),
//   });

//   if (loading || userLoading) {
//     return <div>Loading...</div>;
//   }

//   if (!user || userSupervisorRole !== 'supervisor') {
//     return <div>You are not authorized to view this page.</div>;
//   }

//   const enabledGames = event ? Object.keys(event.sports).filter(game => event.sports[game]) : [];

//   return (
//     <Box bg={"gray.200"} style={{ padding: '20px', minHeight: '100vh' }}>
//       <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
//         <button onClick={handleBack} style={{ padding: '10px 20px' }}>Back</button>
//         <Button onClick={generatePDF} style={{ padding: '10px 20px', border: 'none' }} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)">Export to PDF</Button>
//       </div>
      
//       <div ref={componentPDF} style={{ width: '95%', margin: 'auto', overflowX: 'auto' }}>
//         <Box>
//           <Heading size="md" style={{ color: 'gray', textAlign: 'center' }}>{event.eventName}</Heading>
//           <Heading size="md" style={{ color: 'gray', textAlign: 'center' }}>All Around Result</Heading>
//           <Text textAlign={"center"} color={"grey"}>Date: {new Date(event.dateTime).toLocaleString()}</Text>
//         </Box>
//         {allPlayers.length > 0 ? (
//           <table style={{fontSize:"12px", width: '100%', borderCollapse: 'collapse', backgroundColor: 'white', tableLayout: 'fixed' }}>
//             <thead style={{ backgroundColor: '#FFC107' }}>
//               <tr>
//                 <th style={{ fontSize:"12px", padding: '10px', border: '1px solid #ddd', color: 'white', width: '10%' }}>SR</th>
//                 <th style={{ padding: '10px', border: '1px solid #ddd', color: 'white', width: '20%' }}>Name</th>
//                 <th style={{ padding: '10px', border: '1px solid #ddd', color: 'white', width: '20%' }}>Team/Dist</th>
//                 {enabledGames.map((game) => (
//                   <th key={game} style={{ padding: '10px', border: '1px solid #ddd', color: 'white', width: `${50 / enabledGames.length}%` }}>
//                     {game == "vaultWomen"? "V": game == "floor"? "F": game == "pommel_horse"? "PH": game == "rings"? "R": game == "vaultMen"? "V": game == "parallel_bars"? "PB": game == "horizontal_bar"? "HB": game == "uneven_bars"? "UB": game == "balance_beam"? "BB": game == "floor_exercise"? "FE": game}
//                   </th>
//                 ))}
//                 <th style={{ padding: '10px', border: '1px solid #ddd', color: 'white', width: '15%' }}>All Around</th>
//                 <th style={{ padding: '10px', border: '1px solid #ddd', color: 'white', width: '10%' }}>RANK</th>
//               </tr>
//             </thead>
//             <tbody>
//               {allPlayers.map((player, index) => (
//                 <tr key={index} style={{ textAlign: 'center' }}>
//                   <td style={{ padding: '10px', border: '1px solid #ddd' }}>{index + 1}</td>
//                   <td style={{ padding: '10px', border: '1px solid #ddd' }}>{player.name}</td>
//                   <td style={{ padding: '10px', border: '1px solid #ddd' }}>{player.teamName || 'N/A'}</td>
//                   {enabledGames.map((game) => (
//                     <td key={game} style={{ padding: '10px', border: '1px solid #ddd' }}>
//                       {player.scores[game] ? `${player.scores[game].total}` : 'N/A'}
//                     </td>
//                   ))}
//                   <td style={{ padding: '10px', border: '1px solid #ddd' }}>{player.totalAllAround.toFixed(2)}</td>
//                   <td style={{ padding: '10px', border: '1px solid #ddd' }}>{index + 1}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         ) : (
//           <div style={{ textAlign: 'center', color: 'white' }}>
//             <p>No players found.</p>
//           </div>
//         )}
//       </div>
//     </Box>
//   );
// };

// export default ExportAllAround;


// import React, { useState, useEffect, useRef } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { useAuth } from '../../context/AuthContext';
// import { useReactToPrint } from 'react-to-print';
// import { Box, Button, Heading, Text } from '@chakra-ui/react';

// const ExportAllAround = () => {
//   const { eventId } = useParams();
//   const { user, userSupervisorRole, loading: userLoading } = useAuth();
//   const [event, setEvent] = useState(null);
//   const [allPlayers, setAllPlayers] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate();
//   const componentPDF = useRef();

//   useEffect(() => {
//     if (!userLoading) {
//       if (!user || userSupervisorRole !== 'supervisor') {
//         navigate('/supervisor');
//         return;
//       }

//       const fetchAllPlayers = async () => {
//         try {
//           const response = await axios.get(`http://localhost:5000/api/events/${eventId}`);
//           const eventDetails = response.data;
//           setEvent(eventDetails);
//           console.log("AllAroundEventDetails", eventDetails);

//           if (eventDetails && eventDetails.players && eventDetails.sports) {
//             const enabledGames = Object.keys(eventDetails.sports).filter(game => eventDetails.sports[game]);

//             const aggregatedPlayers = {};

//             enabledGames.forEach((game) => {
//               const gamePlayers = eventDetails.players[game];
//               console.log("AllAroundGamePlayers", gamePlayers);

//               if (gamePlayers) {
//                 gamePlayers.forEach(player => {
//                   const uniqueId = player.email || player.mobile;

//                   if (!aggregatedPlayers[uniqueId]) {
//                     aggregatedPlayers[uniqueId] = {
//                       name: player.name,
//                       scores: {},
//                       totalAllAround: 0,
//                       teamName: 'N/A',
//                     };

//                     Object.keys(eventDetails.teams).some((team) => {
//                       const teamMembers = eventDetails.teams[team].gymnasts;
//                       const match = teamMembers.some((member) => 
//                         (member.email && member.email === player.email) || 
//                         (member.mobile && member.mobile === player.mobile)
//                       );
//                       if (match) {
//                         aggregatedPlayers[uniqueId].teamName = team;
//                         return true;
//                       }
//                       return false;
//                     });
//                   }

//                   aggregatedPlayers[uniqueId].scores[game] = {
//                     e: player.scores.e,
//                     d1: player.scores.d1,
//                     penalty: player.scores.penalty,
//                     total: player.scores.total,
//                   };

//                   aggregatedPlayers[uniqueId].totalAllAround += player.scores.total;
//                 });
//               }
//             });

//             const sortedPlayers = Object.values(aggregatedPlayers).sort((a, b) => b.totalAllAround - a.totalAllAround);
//             setAllPlayers(sortedPlayers);

//             // Update event with new totalAllAround scores
//             await updateEventWithScores(sortedPlayers);
//           } else {
//             console.error('No players or sports found');
//           }
//         } catch (error) {
//           console.error('Error fetching players:', error);
//         } finally {
//           setLoading(false);
//         }
//       };

//       fetchAllPlayers();
//     }
//   }, [userLoading, user, userSupervisorRole, eventId, navigate]);

//   const updateEventWithScores = async (players) => {
//     try {
//       // Format the data to match the event schema
//       const updatedEventData = {
//         ...event,
//         allaroundScore: players.map(player => ({
//           totalAllAround: player.totalAllAround,
//         })),
//       };

//       await axios.put(`http://localhost:5000/api/events/${eventId}`, updatedEventData);
//       console.log('Event updated successfully');
//     } catch (error) {
//       console.error('Error updating event:', error);
//     }
//   };

//   const handleBack = () => {
//     navigate(`/supervisor`);
//   };

//   const generatePDF = useReactToPrint({
//     content: () => componentPDF.current,
//     documentTitle: 'all-sports-scores',
//     onAfterPrint: () => alert('PDF export successful'),
//   });

//   if (loading || userLoading) {
//     return <div>Loading...</div>;
//   }

//   if (!user || userSupervisorRole !== 'supervisor') {
//     return <div>You are not authorized to view this page.</div>;
//   }

//   const enabledGames = event ? Object.keys(event.sports).filter(game => event.sports[game]) : [];

//   return (
//     <Box bg={"gray.200"} style={{ padding: '20px', minHeight: '100vh' }}>
//       <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
//         <button onClick={handleBack} style={{ padding: '10px 20px' }}>Back</button>
//         <Button onClick={generatePDF} style={{ padding: '10px 20px', border: 'none' }} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)">Export to PDF</Button>
//       </div>
      
//       <div ref={componentPDF} style={{ width: '95%', margin: 'auto', overflowX: 'auto' }}>
//         <Box>
//           <Heading size="md" style={{ color: 'gray', textAlign: 'center' }}>{event.eventName}</Heading>
//           <Heading size="md" style={{ color: 'gray', textAlign: 'center' }}>All Around Result</Heading>
//           <Text textAlign={"center"} color={"grey"}>Date: {new Date(event.dateTime).toLocaleString()}</Text>
//         </Box>
//         {allPlayers.length > 0 ? (
//           <table style={{fontSize:"12px", width: '100%', borderCollapse: 'collapse', backgroundColor: 'white', tableLayout: 'fixed' }}>
//             <thead style={{ backgroundColor: '#FFC107' }}>
//               <tr>
//                 <th style={{ fontSize:"12px", padding: '10px', border: '1px solid #ddd', color: 'white', width: '10%' }}>SR</th>
//                 <th style={{ padding: '10px', border: '1px solid #ddd', color: 'white', width: '20%' }}>Name</th>
//                 <th style={{ padding: '10px', border: '1px solid #ddd', color: 'white', width: '20%' }}>Team/Dist</th>
//                 {enabledGames.map((game) => (
//                   <th key={game} style={{ padding: '10px', border: '1px solid #ddd', color: 'white', width: `${50 / enabledGames.length}%` }}>
//                     {game == "vaultWomen"? "V": game == "floor"? "F": game == "pommel_horse"? "PH": game == "rings"? "R": game == "vaultMen"? "V": game == "parallel_bars"? "PB": game == "horizontal_bar"? "HB": game == "uneven_bars"? "UB": game == "balance_beam"? "BB": game == "floor_exercise"? "FE": game}
//                   </th>
//                 ))}
//                 <th style={{ padding: '10px', border: '1px solid #ddd', color: 'white', width: '15%' }}>All Around</th>
//                 <th style={{ padding: '10px', border: '1px solid #ddd', color: 'white', width: '10%' }}>RANK</th>
//               </tr>
//             </thead>
//             <tbody>
//               {allPlayers.map((player, index) => (
//                 <tr key={index} style={{ textAlign: 'center' }}>
//                   <td style={{ padding: '10px', border: '1px solid #ddd' }}>{index + 1}</td>
//                   <td style={{ padding: '10px', border: '1px solid #ddd' }}>{player.name}</td>
//                   <td style={{ padding: '10px', border: '1px solid #ddd' }}>{player.teamName || 'N/A'}</td>
//                   {enabledGames.map((game) => (
//                     <td key={game} style={{ padding: '10px', border: '1px solid #ddd' }}>
//                       {player.scores[game] ? `${player.scores[game].total}` : 'N/A'}
//                     </td>
//                   ))}
//                   <td style={{ padding: '10px', border: '1px solid #ddd' }}>{player.totalAllAround.toFixed(2)}</td>
//                   <td style={{ padding: '10px', border: '1px solid #ddd' }}>{index + 1}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         ) : (
//           <Text>No players data available</Text>
//         )}
//       </div>
//     </Box>
//   );
// };

// export default ExportAllAround;

import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { useReactToPrint } from 'react-to-print';
import { API_URL } from "../../utils/apiConfig";
import { useToast, Box, Button, Heading, Text, Input, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Image } from '@chakra-ui/react';

const ExportAllAround = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const { eventId } = useParams();
  const { user, userSupervisorRole, loading: userLoading } = useAuth();
  const [event, setEvent] = useState(null);
  const [allPlayers, setAllPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sending, setSending] = useState(false);
  const navigate = useNavigate();
  const componentPDF = useRef();
  const [namePosition, setNamePosition] = useState({ top: 330 });
  const [scorePosition, setScorePosition] = useState({top: 490});
  const [certificateNumberPosition, setCertificateNumberPosition] = useState({ top: 0, right: 0 });
  const [certificatesSent, setCertificatesSent] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (!userLoading) {
      if (!user || userSupervisorRole !== 'supervisor') {
        navigate('/supervisor');
        return;
      }

      const fetchAllPlayers = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/events/${eventId}`);
          const eventDetails = response.data;
          setEvent(eventDetails);
          console.log("AllAroundEventDetails", eventDetails);

          if (eventDetails && eventDetails.players && eventDetails.sports) {
            const enabledGames = Object.keys(eventDetails.sports).filter(game => eventDetails.sports[game]);

            const aggregatedPlayers = {};

            enabledGames.forEach((game) => {
              const gamePlayers = eventDetails.players[game];
              console.log("AllAroundGamePlayers", gamePlayers);

              if (gamePlayers) {
                gamePlayers.forEach(player => {
                  const uniqueId = player.email || player.mobile;

                  if (!aggregatedPlayers[uniqueId]) {
                    aggregatedPlayers[uniqueId] = {
                      name: player.name,
                      email: player.email,
                      scores: {},
                      totalAllAround: 0,
                      teamName: 'N/A',
                    };

                    // Check if player is in any team
                  let teamFound = false;

                    Object.keys(eventDetails.teams || {}).forEach((team) => {
                    const teamMembers = eventDetails.teams[team].gymnasts || [];
                    const match = teamMembers.some((member) => 
                      (member.email && member.email === player.email) || 
                      (member.mobile && member.mobile === player.mobile)
                    );
                    if (match) {
                      aggregatedPlayers[uniqueId].teamName = team;
                      teamFound = true;
                    }
                  });

                  // If no team found, use player.city as teamName
                  if (!teamFound) {
                    aggregatedPlayers[uniqueId].teamName = player.city || 'N/A';
                    console.log(`Player ${player.name} not found in any team. Using city as team name.`);
                  }
      
                  if (!teamFound) {
                    console.log(`Player ${player.name} not found in any team.`);
                  }
                }

                  aggregatedPlayers[uniqueId].scores[game] = {
                    e: player.scores.e,
                    d1: player.scores.d1,
                    penalty: player.scores.penalty,
                    total: player.scores.total,
                  };

                  // aggregatedPlayers[uniqueId].totalAllAround += player.scores.total;
                  // Skip vault2Men and vault2Women from the totalAllAround calculation
            if (game !== "vault2Men" && game !== "vault2Women") {
              aggregatedPlayers[uniqueId].totalAllAround += player.scores.total;
            }
                });
              }
            });

            const sortedPlayers = Object.values(aggregatedPlayers).sort((a, b) => b.totalAllAround - a.totalAllAround);
            setAllPlayers(sortedPlayers);

            await updateEventWithScores(sortedPlayers);
          } else {
            console.error('No players or sports found');
          }
        } catch (error) {
          console.error('Error fetching players:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchAllPlayers();
    }
  }, [userLoading, user, userSupervisorRole, eventId, navigate]);

  const updateEventWithScores = async (players) => {
    try {
      const updatedEventData = {
        ...event,
        allaroundScore: players.map(player => ({
          totalAllAround: player.totalAllAround,
        })),
      };

      await axios.put(`${API_URL}/api/events/${eventId}`, updatedEventData);
      console.log('Event updated successfully');
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  const handleBack = () => {
    navigate(`/supervisor`);
  };

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: 'all-sports-scores',
    onAfterPrint: () => alert('PDF export successful'),
  });

  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      setFilePreview(URL.createObjectURL(file));
    }
  };

  // const handleSendCertificates = async () => {
  //   try {
  //     const certificates = allPlayers.map((player) => ({
  //       playerName: player.name,
  //       athleteCode: player.athleteCode,
  //       email: player.email,
  //       totalAllAround: player.totalAllAround, // Add totalAllAround to certificate data
  //     }));

  //     const formData = new FormData();
  //     formData.append('file', selectedFile);
  //     formData.append('certificates', JSON.stringify(certificates));
  //     formData.append('namePosition', JSON.stringify(namePosition));
  //     formData.append('scorePosition',JSON.stringify(scorePosition));
  //     formData.append('certificateNumberPosition', JSON.stringify(certificateNumberPosition));

  //     const response = await axios.post(`${API_URL}/api/events/${eventId}/certificates`, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });

  //     toast({
  //       title: 'Certificates sent successfully!',
  //       status: 'success',
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //     setCertificatesSent(true);

  //     // navigate(`/event/${eventId}/${game}`, { state: { updatedCertificates: response.data.certificates } });
  //   } catch (error) {
  //     console.error('Error sending certificates:', error);
  //     toast({
  //       title: 'Error sending certificates',
  //       description: error.message,
  //       status: 'error',
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //   }
  // };

  const handleSendCertificates = async () => {
    try {
      const certificates = allPlayers.map((player) => ({
        playerName: player.name,
        athleteCode: player.athleteCode,
        email: player.email,
        totalAllAround: player.totalAllAround,
      }));
  
      const batchSize = 50; // Adjust based on your email provider's limit
      for (let i = 0; i < certificates.length; i += batchSize) {
        const batch = certificates.slice(i, i + batchSize);
        
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('certificates', JSON.stringify(batch));
        formData.append('namePosition', JSON.stringify(namePosition));
        formData.append('scorePosition', JSON.stringify(scorePosition));
        formData.append('certificateNumberPosition', JSON.stringify(certificateNumberPosition));
  
        await axios.post(`${API_URL}/api/events/${eventId}/certificates`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        
        // Optionally add a delay between batches to avoid hitting rate limits
        await new Promise(resolve => setTimeout(resolve, 2000)); // 2 seconds delay
      }
  
      toast({
        title: 'Certificates sent successfully!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setCertificatesSent(true);
  
    } catch (error) {
      console.error('Error sending certificates:', error);
      toast({
        title: 'Error sending certificates',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  const handleSendCertificatesO = async () => {
    setSending(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      await axios.post(`${API_URL}/api/certificates/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Send certificates to all players
      const certificatePromises = allPlayers.map(player =>
        axios.post(`${API_URL}/api/certificates/send`, {
          email: player.email,
          name: player.name,
          athleteCode: player.athleteCode, // Assuming you have this field
          totalAllAround: player.totalAllAround,
        })
      );

      await Promise.all(certificatePromises);
      alert('Certificates sent successfully');
    } catch (error) {
      console.error('Error sending certificates:', error);
    } finally {
      setSending(false);
    }
  };

  if (loading || userLoading) {
    return <div>Loading...</div>;
  }

  if (!user || userSupervisorRole !== 'supervisor') {
    return <div>You are not authorized to view this page.</div>;
  }

  const enabledGames = Object.keys(event.sports).filter(
    game => event.sports[game] && game !== 'vault2Men' && game !== 'vault2Women'
  );

  return (
    <Box bg={"gray.200"} style={{ padding: '20px', minHeight: '100vh' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <Button onClick={handleBack} style={{ padding: '10px 20px' }}>Back</Button>
        <Button onClick={generatePDF} style={{ padding: '10px 20px', border: 'none' }} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)">Export to PDF</Button>
        <Button onClick={() => setIsModalOpen(true)} style={{ padding: '10px 20px', border: 'none' }} bgGradient="linear(to-r, blue.400, blue.500, blue.600)">Add Certificate</Button>
      </div>
      
      <div ref={componentPDF} style={{ width: '95%', margin: 'auto', overflowX: 'auto' }}>
        <Box>
          <Heading size="md" style={{ color: 'gray', textAlign: 'center' }}>{event.eventName}</Heading>
          <Heading size="md" style={{ color: 'gray', textAlign: 'center' }}>All Around Result</Heading>
          <Text textAlign={"center"} color={"grey"}>Date: {new Date(event.dateTime).toLocaleString()}</Text>
        </Box>
        {allPlayers.length > 0 ? (
          <table style={{fontSize:"12px", width: '100%', borderCollapse: 'collapse', backgroundColor: 'white', tableLayout: 'fixed' }}>
            <thead style={{ backgroundColor: '#FFC107' }}>
              <tr>
                <th style={{ fontSize:"12px", padding: '10px', border: '1px solid #ddd', color: 'white', width: '10%' }}>SR</th>
                <th style={{ padding: '10px', border: '1px solid #ddd', color: 'white', width: '20%' }}>Name</th>
                <th style={{ padding: '10px', border: '1px solid #ddd', color: 'white', width: '20%' }}>Team/Dist</th>
                {enabledGames.map((game) => (
                  <th key={game} style={{ padding: '10px', border: '1px solid #ddd', color: 'white', width: `${50 / enabledGames.length}%` }}>
                    {game =="vault1Men"? "V1" : game =="vault2Men"? "V2" : game =="vault1Women"? "V1" : game =="vault2Women"? "V2" : game == "vaultWomen"? "V": game == "floor"? "F": game == "pommel_horse"? "PH": game == "rings"? "R": game == "vaultMen"? "V": game == "parallel_bars"? "PB": game == "horizontal_bar"? "HB": game == "uneven_bars"? "UB": game == "balance_beam"? "BB": game == "floor_exercise"? "FE": game}
                  </th>
                ))}
                <th style={{ padding: '10px', border: '1px solid #ddd', color: 'white', width: '15%' }}>All Around</th>
              </tr>
            </thead>
            <tbody>
              {allPlayers.map((player, index) => (
                <tr key={index}>
                  <td style={{ padding: '10px', border: '1px solid #ddd', textAlign: 'center' }}>{index + 1}</td>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>{player.name}</td>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>{player.teamName}</td>
                  {enabledGames.map((game) => (
                    <td key={game} style={{ padding: '10px', border: '1px solid #ddd', textAlign: 'center' }}>
                      {player.scores[game] ? player.scores[game].total : 0}
                    </td>
                  ))}
                  <td style={{ padding: '10px', border: '1px solid #ddd', textAlign: 'center' }}>{player.totalAllAround.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <Text>No players available</Text>
        )}
      </div>
      
      <Modal size={"full"} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Certificate</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input type="file" onChange={handleFileUpload} />
            {filePreview && <Image src={filePreview} alt="File preview" />}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSendCertificates} isLoading={sending} mr={3}>
              Send Certificates
            </Button>
            <Button variant="ghost" onClick={() => setIsModalOpen(false)}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ExportAllAround;

