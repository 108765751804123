// ForgotPassword.jsx
import { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase.config";
import { Box, Button, FormLabel, Heading, Input, Text } from "@chakra-ui/react";
import { toast, Toaster } from "react-hot-toast";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const onResetPassword = async () => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success("Password reset email sent!");
    } catch (error) {
      toast.error("Failed to send reset email. Please check the email address.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      bg={"#26263c"}
      className="bg-emerald-500 flex items-center justify-center h-screen"
    >
      <div>
        <Toaster toastOptions={{ duration: 4000 }} />
        <Box
          boxShadow="md"
          border="1px solid"
          style={{
            borderImageSource:
              "linear-gradient(to right, #f6e05e, #ecc94b, #d69e2e)",
            borderImageSlice: 1,
          }}
          mt={"-70px"}
          className="w-80 flex flex-col gap-4 rounded-lg p-6"
        >
          <Heading textAlign={"center"} color={"white"}>
            Reset Password
          </Heading>
          <FormLabel marginBottom={"-10px"} color={"white"}>
            Enter your registered email
          </FormLabel>
          <Input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            bg={"white"}
          />
          <Button
            bgGradient="linear(to-r, yellow.300, yellow.400, yellow.600)"
            onClick={onResetPassword}
          >
            {loading ? "Sending..." : "Send Reset Email"}
          </Button>
          <Text color={"white"} textAlign={"center"}>
            Remember your password?{" "}
            <a
              style={{ textDecoration: "underline", color: "#ffbc0d" }}
              href="/signin"
            >
              Sign In
            </a>
          </Text>
        </Box>
      </div>
    </Box>
  );
};

export default ForgotPassword;
