import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { Box, Heading } from '@chakra-ui/react'; // Assuming you're using Chakra UI

const AadhaarVerifiedPieChart = ({ aadhaarVerifiedCounts }) => {
  // Define colors for the pie chart
  const COLORS = ['green', 'red'];
  console.log(aadhaarVerifiedCounts);

  return (
    <Box>
      {/* <Heading size="md">Athlete Count by Gender</Heading> */}
      <Box>
        {aadhaarVerifiedCounts.map((gender) => (
          <Box textAlign={"center"} fontSize={"18px"} fontWeight={"bold"} key={gender._id}>{gender._id=== true ? "Verified":"Not Verified"}: {gender.count}</Box>
        ))}
      </Box>
      <PieChart width={345} height={270}>
        <Pie
          data={aadhaarVerifiedCounts}
          cx={170}
          cy={120}
          labelLine={false}
          label={({ _id }) => _id=== true ? "Verified":"Not Verified"} // Using gender labels directly
          outerRadius={80}
          fill="#8884d8"
          dataKey="count"
        >
          {aadhaarVerifiedCounts.map((entry, index) => (
            <Cell key={`cell-${entry._id}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend formatter={(value) => <span>{value}</span>} />
      </PieChart>
      
    </Box>
  );
};

export default AadhaarVerifiedPieChart;
