import { Box, Heading, Text, Button } from '@chakra-ui/react';
import {useNavigate} from "react-router-dom"

export default function NotFound() {
    const navigate = useNavigate();

    const handleGoToHome = ()=> {
        navigate("/");
    }

  return (
    <Box  flex={1} border={"1px solid white"} justifyContent={"center"} alignItems={"center"} height={"85vh"}>
        <Box  mt={"100px"} textAlign="center" py={10} px={6}>
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, yellow.400, yellow.600)"
        backgroundClip="text">
        404
      </Heading>
      <Text fontSize="18px" fontWeight={"bold"} mt={3} mb={2}>
      
      Page Not Found
      </Text>
      <Text color={'gray.500'} mb={6}>
      The page you're looking for does not seem to exist
      </Text>

      <Button
        colorScheme="yellow"
        bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
        color="white"
        onClick={handleGoToHome}
        variant="solid">
        Go to Home
      </Button>
      </Box>
    </Box>
  );
}