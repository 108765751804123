import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Text, VStack, Spinner, Td, Tr, Tbody, Table, Thead, Th, Button, Flex, Tfoot } from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useReactToPrint } from 'react-to-print';
import {API_URL} from "../utils/apiConfig";

const PlayerListPage = () => {
  const { eventId } = useParams();
  const { user, userSupervisorRole, loading: userLoading } = useAuth();
  const [event, setEvent] = useState(null);
  const [allPlayers, setAllPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [teamRanks, setTeamRanks] = useState([]);
  const navigate = useNavigate();
  const componentPDF = useRef();

  useEffect(() => {
    if (!userLoading) {
      if (!user || userSupervisorRole !== 'supervisor') {
        navigate('/supervisor');
        return;
      }

      const fetchAllPlayers = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/events/${eventId}`);
          const eventDetails = response.data;
          setEvent(eventDetails);

          if (eventDetails && eventDetails.players && eventDetails.sports) {
            const enabledGames = Object.keys(eventDetails.sports).filter(game => eventDetails.sports[game]);

            const aggregatedPlayers = {};

            // Loop through each enabled game
            enabledGames.forEach((game) => {
              const gamePlayers = eventDetails.players[game];

              if (gamePlayers) {
                gamePlayers.forEach(player => {
                  // Use email or mobile as unique identifier
                  const uniqueId = player.email || player.mobile;

                  if (!aggregatedPlayers[uniqueId]) {
                    // Initialize the player if not already in the aggregated list
                    aggregatedPlayers[uniqueId] = {
                      name: player.name,
                      email: player.email,
                      mobile: player.mobile,
                      scores: {},
                      totalAllAround: 0, // Initialize the all-around score
                      teamName: 'N/A', // Initialize the team name as 'N/A'
                    };

                    // Correctly find and assign the team for the player
                    Object.keys(eventDetails.teams).some((team) => {
                      const teamMembers = eventDetails.teams[team].gymnasts;
                      const match = teamMembers.some((member) => 
                        (member.email && member.email === player.email) || 
                        (member.mobile && member.mobile === player.mobile)
                      );
                      if (match) {
                        aggregatedPlayers[uniqueId].teamName = team; // Assign the team name
                        return true; // Stop further checking once the team is found
                      }
                      return false; // Continue checking other teams
                    });
                  }

                  // Aggregate scores for each enabled game
                  aggregatedPlayers[uniqueId].scores[game] = {
                    e: player.scores.e,
                    d1: player.scores.d1,
                    penalty: player.scores.penalty,
                    total: player.scores.total,
                  };

                  // Sum up the total scores for the all-around score
                  aggregatedPlayers[uniqueId].totalAllAround += player.scores.total;
                });
              }
            });

            // Convert aggregated object to an array for easier rendering
            const playersArray = Object.values(aggregatedPlayers);
            setAllPlayers(playersArray);

            // Calculate team rankings
            const teamScores = {};
            
            // Initialize team scores with players
            playersArray.forEach(player => {
              if (!teamScores[player.teamName]) {
                teamScores[player.teamName] = [];
              }
              teamScores[player.teamName].push(player);
            });

            // Filter and calculate scores only for teams with at least 2 players
            const qualifiedTeams = Object.entries(teamScores)
              .filter(([teamName, players]) => players.length >= 2) // Rule 1: At least 2 players
              .map(([teamName, players]) => {
                // Sort players by All Around score in descending order
                players.sort((a, b) => b.totalAllAround - a.totalAllAround);

                // Select the top 2 players
                const topTwoPlayers = players.slice(0, 2);

                // Calculate the team's total score from the top 2 players
                const teamTotalScore = topTwoPlayers.reduce((sum, player) => sum + player.totalAllAround, 0);

                return {
                  teamName,
                  players,
                  teamTotalScore,
                };
              });

            // Sort teams by their total score in descending order
            qualifiedTeams.sort((a, b) => b.teamTotalScore - a.teamTotalScore);
            setTeamRanks(qualifiedTeams);
          } else {
            console.error('No players or sports found');
          }
        } catch (error) {
          console.error('Error fetching players:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchAllPlayers();
    }
  }, [userLoading, user, userSupervisorRole, eventId, navigate]);

  const handleBack = () => {
    navigate(`/supervisor`);
  };

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: 'all-sports-scores',
    onAfterPrint: () => alert('PDF export successful'),
  });

  if (loading || userLoading) {
    return <Spinner />;
  }

  if (!user || userSupervisorRole !== 'supervisor') {
    return <Text>You are not authorized to view this page.</Text>;
  }

  // Get the list of enabled games to display in the table header
  const enabledGames = event ? Object.keys(event.sports).filter(game => event.sports[game]) : [];

  return (
    <Box p={4} w={'100%'} minHeight={'100vh'} bg={'#26263c'}>
      <VStack align="start" mt={4}>
        <Box w={'100%'}>
          <Flex flex={1} flexWrap={'wrap'} justifyContent={'space-between'}>
            <Button onClick={handleBack} mb={4}>Back</Button>
            <Button bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" onClick={generatePDF}>Export to PDF</Button>
          </Flex>
          <Box style={{ textAlign: 'center' }}>
            <Text color={'white'} fontSize="2xl" mb={4}>All Sports Scores</Text>
          </Box>

          <div ref={componentPDF} style={{ width: '100%', overflow: 'scroll', overflowY: 'hidden', overflowX: 'revert-layer' }}>
            {teamRanks.length > 0 ? (
              <Table color={'black'} variant="striped" width={'90%'} paddingBottom={'1000px'} margin={'auto'} borderRadius={'20px'} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray">
                <Thead>
                  <Tr>
                    <Th color={'white'}>Rank</Th>
                    <Th color={'white'}>Team Name</Th>
                    <Th color={'white'}>Player Name</Th>
                    <Th color={'white'}>Email</Th>
                    <Th color={'white'}>Mobile</Th>
                    {/* Add columns for each enabled game dynamically */}
                    {enabledGames.map((game) => (
                      <Th key={game} color={'white'}>{game}</Th>
                    ))}
                    <Th color={'white'}>All Around</Th> {/* All Around column */}
                  </Tr>
                </Thead>
                <Tbody>
                  {teamRanks.map((team, teamIndex) => (
                    <React.Fragment key={team.teamName}>
                      {team.players.map((player, playerIndex) => (
                        <Tr key={playerIndex}>
                          {playerIndex === 0 && (
                            <Td rowSpan={team.players.length}>{teamIndex + 1}</Td>
                          )}
                          {playerIndex === 0 && (
                            <Td rowSpan={team.players.length}>{team.teamName}</Td>
                          )}
                          <Td>{player.name}</Td>
                          <Td>{player.email}</Td>
                          <Td>+{player.mobile}</Td>
                          {/* Display scores for each enabled game dynamically */}
                          {enabledGames.map((game) => (
                            <Td key={game}>
                              {player.scores[game] ? `E: ${player.scores[game].e}, D: ${player.scores[game].d1}, Penalty: ${player.scores[game].penalty}, Total: ${player.scores[game].total}` : 'N/A'}
                            </Td>
                          ))}
                          <Td>{player.totalAllAround.toFixed(2)}</Td> {/* Display the All Around score */}
                        </Tr>
                      ))}
                    </React.Fragment>
                  ))}
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Td colSpan={enabledGames.length + 4}>
                      <Flex justify="center">
                        <Text>Total Teams: {teamRanks.length}</Text>
                      </Flex>
                    </Td>
                  </Tr>
                </Tfoot>
              </Table>
            ) : (
              <div>
                <br />
                <Text>No qualified teams found.</Text>
              </div>
            )}
          </div>
        </Box>
      </VStack>
    </Box>
  );
};

export default PlayerListPage;
