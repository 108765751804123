import React from 'react';
import { Box, Image, Text, Heading, Flex, IconButton, Stack } from '@chakra-ui/react';
import { FaLinkedin } from 'react-icons/fa';
import aniket from "../logo/aniket.jpg";
import shubham from "../logo/shubham-rajput.jpg"
import dhairya from "../logo/dhairya-deshmukh.jpg"

const teamMembers = [
  {
    name: 'Dhairyashil Deshmukh',
    position: 'Founder & CEO',
    image: dhairya,
    linkedin: 'https://linkedin.com/in/dhairyashil-deshmukh-96b3861b4',
  },
  {
    name: 'Shubham Rajput',
    position: 'Co-Founder & COO',
    image: shubham,
    linkedin: 'https://linkedin.com/in/shubham-rajput-757030210',
  },
  {
    name: 'Aniket Rasal',
    position: 'Co-Founder and CTO',
    image: aniket,
    linkedin: 'https://www.linkedin.com/in/aniket-rasal-5b8510204',
  },
];

const Team = () => {
  return (
    <Box maxW="1200px" mx="auto" py={8} px={4} textAlign="center">
      <Heading mb={4}>Leadership Team</Heading>
      <Text mb={8} fontSize="lg">
        With more than 20 years of combined experience, our Executive Team has one goal: “Unlock the human potential of India by transforming the gymnastic system.”
      </Text>
      
      <Flex wrap="wrap" justify="center" gap={6}>
        {teamMembers.map((member, index) => (
          <Box
            key={index}
            w={["100%", "45%", "25%"]}
            borderRadius="lg"
            overflow="hidden"
            boxShadow="lg"
            bg="white"
            position="relative"
            _hover={{ transform: 'scale(1.05)', transition: '0.3s ease-in-out' }}
          >
            <Image src={member.image} alt={member.name} w="100%" h="auto" />
            <Box position="absolute" bottom="0" left="0" right="0" bg="rgba(0,0,0,0.6)" p={4} color="white">
              <Text fontWeight="bold" fontSize="lg">{member.name}</Text>
              <Text fontSize="sm">{member.position}</Text>
              <Stack direction="row" justify="center" mt={2}>
                <IconButton
                  as="a"
                  href={member.linkedin}
                  target="_blank"
                  colorScheme="linkedin"
                  icon={<FaLinkedin />}
                  aria-label="LinkedIn Profile"
                  size="sm"
                  variant="outline"
                  _hover={{ bg: "linkedin.500", color: "white" }}
                />
              </Stack>
            </Box>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default Team;
