import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../context/AuthContext';
import { useReactToPrint } from 'react-to-print';
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import {API_URL} from "../../../utils/apiConfig";

const ExportResult = () => {
  const { eventId } = useParams();
  const { user, userSupervisorRole, loading: userLoading } = useAuth();
  const [event, setEvent] = useState(null);
  const [allPlayers, setAllPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const componentPDF = useRef();

  useEffect(() => {
    if (!userLoading) {
      if (!user || userSupervisorRole !== 'supervisor') {
        navigate('/supervisor');
        return;
      }

      const fetchAllPlayers = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/events/${eventId}`);
    const eventDetails = response.data;
    setEvent(eventDetails);
    console.log("Fetched Event Details:", eventDetails);

    if (eventDetails && eventDetails.players && eventDetails.sports) {
      const enabledGames = Object.keys(eventDetails.sports || {}).filter(game => eventDetails.sports[game]);

      const aggregatedPlayers = {};

      enabledGames.forEach((game) => {
        const gamePlayers = eventDetails.players[game] || []; // Default to an empty array if no players

        console.log(`Processing players for game: ${game}`, gamePlayers);

        gamePlayers.forEach(player => {
          const uniqueId = player.email || player.mobile;

          if (!aggregatedPlayers[uniqueId]) {
            aggregatedPlayers[uniqueId] = {
              name: player.name,
              email: player.email,
              scores: {},
              totalAllAround: 0,
              teamName: 'N/A', // Default team name
            };

            // Check if player is in any team
            let teamFound = false;
            Object.keys(eventDetails.teams || {}).forEach((team) => {
              const teamMembers = eventDetails.teams[team].gymnasts || [];
              const match = teamMembers.some((member) => 
                (member.email && member.email === player.email) || 
                (member.mobile && member.mobile === player.mobile)
              );
              if (match) {
                aggregatedPlayers[uniqueId].teamName = team;
                teamFound = true;
              }
            });

            // If no team found, use player.city as teamName
            if (!teamFound) {
              aggregatedPlayers[uniqueId].teamName = player.city || 'N/A';
              console.log(`Player ${player.name} not found in any team. Using city as team name.`);
            }

            if (!teamFound) {
              console.log(`Player ${player.name} not found in any team.`);
            }
          }

          aggregatedPlayers[uniqueId].scores[game] = {
            e: player.scores.e,
            d1: player.scores.d1,
            penalty: player.scores.penalty,
            total: player.scores.total,
          };

          aggregatedPlayers[uniqueId].totalAllAround += player.scores.total;
        });
      });

      const sortedPlayers = Object.values(aggregatedPlayers).sort((a, b) => b.totalAllAround - a.totalAllAround);
      setAllPlayers(sortedPlayers);
      console.log("ALLLL Players:", allPlayers)

      if (sortedPlayers.length === 0) {
        console.error('No players aggregated. Please check the data processing.');
      }
    } else {
      console.error('Event details are incomplete or missing.');
    }
  } catch (error) {
    console.error('Error fetching players:', error);
  } finally {
    setLoading(false);
  }
};

      fetchAllPlayers();
    }
  }, [userLoading, user, userSupervisorRole, eventId, navigate]);

  const handleBack = () => {
    navigate(`/supervisor`);
  };

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: 'all-sports-scores',
    onAfterPrint: () => alert('PDF export successful'),
  });

  const getPlayerCertificateNumber = (playerEmail) => {
    const certificate = event.certificates.participants.find(cert => cert.email === playerEmail);
    return certificate ? certificate.certificateNumber : 'N/A';
  };

  if (loading || userLoading) {
    return <div>Loading...</div>;
  }

  if (!user || userSupervisorRole !== 'supervisor') {
    return <div>You are not authorized to view this page.</div>;
  }

  const enabledGames = event ? Object.keys(event.sports).filter(game => event.sports[game]) : [];

  return (
    <Box bg={"gray.200"} style={{ padding: '20px', minHeight: '100vh' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <button onClick={handleBack} style={{ padding: '10px 20px' }}>Back</button>
        <Button onClick={generatePDF} style={{ padding: '10px 20px', border: 'none' }} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)">Export to PDF</Button>
      </div>
      
      
      <div ref={componentPDF} style={{ width: '95%', margin: 'auto', overflowX: 'auto' }}>
        <Box>
          <Heading size= "md"style={{ color: 'black', textAlign: 'center',  }}>{event.eventName}</Heading>
          <Heading size= "md"style={{ color: 'black', textAlign: 'center', }}>Participant Certificate List</Heading>
          <Text textAlign={"center"} color={"black"}>Date: {new Date(event.dateTime).toLocaleString()}</Text>
        </Box>
        {allPlayers.length > 0 ? (
          <table style={{ width: '100%', borderCollapse: 'collapse', backgroundColor: 'white', tableLayout: 'fixed' }}>
            <thead style={{ color: 'black' }}>
              <tr>
                <th style={{ padding: '10px', border: '1px solid #ddd', width: '7%' }}>SR</th>
                <th style={{ padding: '10px', border: '1px solid #ddd', width: '20%' }}>Name</th>
                <th style={{ padding: '10px', border: '1px solid #ddd', width: '15%' }}>Team/Dist</th>
                <th style={{ padding: '10px', border: '1px solid #ddd', width: '10%' }}>All Around</th>
                <th style={{ padding: '10px', border: '1px solid #ddd', width: '7%' }}>RANK</th>
                <th style={{ padding: '10px', border: '1px solid #ddd', width: '15%' }}>Certificate No.</th>
              </tr>
            </thead>
            <tbody>
              {allPlayers.map((player, index) => (
                <tr key={index} style={{ textAlign: 'center' }}>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>{index + 1}</td>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>{player.name}</td>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>{player.teamName || 'N/A'}</td>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>{player.totalAllAround.toFixed(2)}</td>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>{index + 1}</td>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>{getPlayerCertificateNumber(player.email)}</td>

                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div style={{ textAlign: 'center', color: 'white' }}>
            <p>No players found.</p>
          </div>
        )}
      </div>
    </Box>
  );
};

export default ExportResult;
