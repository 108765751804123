import { VStack, HStack, Collapse, Text, Box } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import { motion } from "framer-motion";
import { useState } from "react";

const SidebarMenu = ({ route, isOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <VStack align="start" bg={"white"} border={"10px solid black"} spacing="2" w="100%">
      <HStack w="100%" bg={"white"}  border={"10px solid black"} justify="space-between" onClick={toggleMenu}>
        <Box  border={"10px solid black"}>
          {route.icon}
          <Text ml="2">{route.name}</Text>
        </Box>
        {isOpen && <FaAngleDown />}
      </HStack>
      <Collapse in={isMenuOpen} animateOpacity>
        <VStack align="start"  border={"10px solid black"} pl="4" w="100%">
          {route.subRoutes.map((subRoute, index) => (
            <NavLink key={index} to={subRoute.path}>
              <HStack w="100%"  border={"10px solid black"}>
                {subRoute.icon}
                <Text>{subRoute.name}</Text>
              </HStack>
            </NavLink>
          ))}
        </VStack>
      </Collapse>
    </VStack>
  );
};

export default SidebarMenu;
