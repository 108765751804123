import React from 'react';
import {
  Container,
  SimpleGrid,
  Flex,
  Heading,
  Text,
  Stack,
  Icon,
  useColorModeValue,
  StackDivider,
} from '@chakra-ui/react';
import { IoAnalyticsSharp, IoLogoBitcoin, IoSearchSharp } from 'react-icons/io5';

const Feature = ({ text, icon, iconBg }) => {
  return (
    <Stack direction={'row'} mr={{base:"20px", md:"0px", lg:"0px"}} justifyContent={{base:"center", md:"start", lg:"start"}} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export default function SplitWithImage() {
  return (
    <Container maxW={'90%'} textAlign={{base:"center", md:"start", lg:"start"}} py={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Text
            textTransform={'uppercase'}
            color={'blue.400'}
            fontWeight={600}
            fontSize={'sm'}
            bg={useColorModeValue('blue.50', 'blue.900')}
            p={2}
            alignSelf={{sm:"center", md:'flex-start', lg:"flex-start"}}
            rounded={'md'}
          >
            Our Story
          </Text>
          <Heading>GYMNASTICS SCORING SOFTWARE - GYMNAZIEN</Heading>
          <Text color={'gray.500'} fontSize={'lg'}>
          Gymnazien Tech is a leading provider of digital solutions for the gymnastics industry. Our flagship product, Gymnazien, is a comprehensive scoring software designed for gym owners, associations, and competition organizers. With over 10k followers and a growing user base, Gymnazien is becoming essential in the gymnastics community.
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider borderColor={useColorModeValue('gray.100', 'gray.700')} />
            }
          >
            <Feature
              icon={<Icon as={IoAnalyticsSharp} color={'yellow.500'} w={5} h={5} />}
              iconBg={useColorModeValue('yellow.100', 'yellow.900')}
              text={'Competition Management'}
            />
            <Feature
              icon={<Icon as={IoLogoBitcoin} color={'green.500'} w={5} h={5} />}
              iconBg={useColorModeValue('green.100', 'green.900')}
              text={'Judging System'}
            />
            <Feature
              icon={<Icon as={IoSearchSharp} color={'purple.500'} w={5} h={5} />}
              iconBg={useColorModeValue('purple.100', 'purple.900')}
              text={'Real-Time Updates'}
            />
          </Stack>
        </Stack>
        <Flex>
          <img
            src={
              'https://www.sammobile.com/wp-content/uploads/2022/02/Galaxy-Tab-S8-Plus-1-1536x863.jpg'
            }
            alt={'feature'}
            style={{ borderRadius: '8px' }}
          />
        </Flex>
      </SimpleGrid>
    </Container>
  );
}
