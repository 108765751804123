import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import axios from "axios";
// import { Box, Heading } from '@chakra-ui/react';
import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  Image,
  Flex,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
  VisuallyHidden,
  List,
  ListItem,
  HStack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
} from "@chakra-ui/react";
import { FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { MdLocalShipping } from "react-icons/md";
import {API_URL} from "../utils/apiConfig";

const AdminPage = () => {
  const { eventId } = useParams();
  const [event, setEvent] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, userCompetitionAdmin, loading: userLoading } = useAuth();

  useEffect(() => {
    if (!userLoading) {
      if (!user || userCompetitionAdmin !== "competitionAdmin") {
        // If not a supervisor, redirect to the supervisor dashboard or show a "Not Authorized" page
        navigate("/notaccess");
        return;
      }

      const fetchPlayers = async () => {
        try {
          const response = await axios.get(
            `${API_URL}/api/events/${eventId}`
          );
          const eventDetails = response.data;
          if (eventDetails) {
            setEvent(eventDetails);
          } else {
            console.error("Event not found");
          }
        } catch (error) {
          console.error("Error fetching players:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchPlayers();
    }
  }, [userLoading, user, userCompetitionAdmin, eventId, navigate]);

  // Only show games with non-empty player lists
  const nonEmptyGames = event.players
    ? Object.entries(event.players).filter(
        ([game, players]) => players.length > 0
      )
    : [];

  const handleAppratusPage = () => {
    navigate(`/adminn/${eventId}`);
  };

  return (
    <Box>
      <Container maxW={"7xl"}>
        <SimpleGrid
          columns={{ base: 1, lg: 2 }}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 18, md: 24 }}
        >
          <Stack spacing={{ base: 6, md: 10 }}>
            <Box as={"header"}>
              <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                <Box>
                  <Heading
                    lineHeight={1.1}
                    fontWeight={600}
                    fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
                  >
                    {event.eventName}
                  </Heading>
                  <Text
                    color={useColorModeValue("gray.900", "gray.400")}
                    fontWeight={300}
                    fontSize={"2xl"}
                  >
                    {new Date(event.dateTime).toLocaleString()}
                  </Text>
                  {event.length === 0 ? (
                    <Text></Text>
                  ) : (
                    <Box
                      key={event._id}
                      p={4}
                      mb={6}
                      borderRadius={"10px"}
                      shadow="md"
                      borderWidth="1px"
                      sx={{}}
                    >
                      <VStack align="start" mt={4}>
                        <Box>
                          {nonEmptyGames.length > 0 ? (
                            <Box
                              p={4}
                              mb={6}
                              borderRadius={"10px"}
                              shadow="md"
                              borderWidth="1px"
                            >
                              <VStack align="start" mt={4}>
                                <Text fontSize="lg">Apparatus:</Text>
                                {nonEmptyGames.map(([game, players]) => (
                                  <Box key={game}>
                                    <Link
                                      to={`/adminn/${event._id}/${game}`}
                                    >
                                      <Text
                                        color={"#ffbf00"}
                                        fontSize="md"
                                        fontWeight="bold"
                                      >
                                        {game.charAt(0).toUpperCase() +
                                          game.slice(1)}
                                      </Text>
                                    </Link>
                                  </Box>
                                ))}
                              </VStack>
                            </Box>
                          ) : (
                            <Text>No games with players available</Text>
                          )}
                        </Box>
                      </VStack>
                    </Box>
                  )}
                </Box>
              </Flex>
            </Box>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default AdminPage;
