// src/ManageCompetition.js

import React, { useState } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Button, Input, VStack, HStack, Text, Select } from '@chakra-ui/react';

const ManageCompetition = () => {
  const [players, setPlayers] = useState([]);
  const [judges, setJudges] = useState([]);
  const [teams, setTeams] = useState([]);
  const [currentTeam, setCurrentTeam] = useState({ name: '', player1: '', player2: '' });

  const [newPlayer, setNewPlayer] = useState('');
  const [newJudge, setNewJudge] = useState('');
  const [newTeam, setNewTeam] = useState('');

  // Define games
  const games = ['floor', 'pommel_horse', 'vaultMen', 'rings', 'parallel_bars', 'horizontal_bars'];

  // Functions to handle adding players, judges, and teams
  const handleAddPlayer = () => {
    if (newPlayer.trim()) {
      setPlayers([...players, newPlayer]);
      setNewPlayer('');
    }
  };

  const handleAddJudge = () => {
    if (newJudge.trim()) {
      setJudges([...judges, newJudge]);
      setNewJudge('');
    }
  };

  const handleAddTeam = () => {
    if (newTeam.trim()) {
      setTeams([...teams, { name: newTeam, player1: '', player2: '' }]);
      setNewTeam('');
    }
  };

  const handleSelectPlayerForTeam = (teamName, player, role) => {
    setTeams(
      teams.map((team) =>
        team.name === teamName ? { ...team, [role]: player } : team
      )
    );
  };

  return (
    <Box p={5}>
      <Tabs variant="soft-rounded" colorScheme="blue">
        <TabList mb={4}>
          <Tab>Add Players</Tab>
          <Tab>Add Judges</Tab>
          <Tab>Add Teams</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack spacing={4}>
              <HStack>
                <Input
                  placeholder="Enter player name"
                  value={newPlayer}
                  onChange={(e) => setNewPlayer(e.target.value)}
                />
                <Button onClick={handleAddPlayer}>Add Player</Button>
              </HStack>
              <Box>
                {games.map((game) => (
                  <Box key={game} borderWidth="1px" borderRadius="md" p={3} mb={3}>
                    <Text fontSize="lg" fontWeight="bold" mb={2}>
                      {game.charAt(0).toUpperCase() + game.slice(1)}
                    </Text>
                    <VStack align="start">
                      {players.map((player, index) => (
                        <Text key={index}>{player}</Text>
                      ))}
                    </VStack>
                  </Box>
                ))}
              </Box>
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack spacing={4}>
              <HStack>
                <Input
                  placeholder="Enter judge name"
                  value={newJudge}
                  onChange={(e) => setNewJudge(e.target.value)}
                />
                <Button onClick={handleAddJudge}>Add Judge</Button>
              </HStack>
              <VStack align="start">
                {judges.map((judge, index) => (
                  <Text key={index}>{judge}</Text>
                ))}
              </VStack>
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack spacing={4}>
              <HStack>
                <Input
                  placeholder="Enter team name"
                  value={newTeam}
                  onChange={(e) => setNewTeam(e.target.value)}
                />
                <Button onClick={handleAddTeam}>Add Team</Button>
              </HStack>
              <VStack align="start" w="100%">
                {teams.map((team, index) => (
                  <Box key={index} borderWidth="1px" borderRadius="md" p={3} mb={3} w="100%">
                    <Text fontSize="lg" fontWeight="bold" mb={2}>
                      {team.name}
                    </Text>
                    <HStack spacing={4}>
                      <Select
                        placeholder="Select Player 1"
                        value={team.player1}
                        onChange={(e) => handleSelectPlayerForTeam(team.name, e.target.value, 'player1')}
                        disabled={!!team.player1}
                      >
                        {players
                          .filter((player) => player !== team.player2)
                          .map((player, idx) => (
                            <option key={idx} value={player}>
                              {player}
                            </option>
                          ))}
                      </Select>
                      <Select
                        placeholder="Select Player 2"
                        value={team.player2}
                        onChange={(e) => handleSelectPlayerForTeam(team.name, e.target.value, 'player2')}
                        disabled={!!team.player2}
                      >
                        {players
                          .filter((player) => player !== team.player1)
                          .map((player, idx) => (
                            <option key={idx} value={player}>
                              {player}
                            </option>
                          ))}
                      </Select>
                    </HStack>
                  </Box>
                ))}
              </VStack>
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default ManageCompetition;
