import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import GenderCharts from './GenderCharts';
import AadhaarVerifiedPieCharts from './AadhaarVerifiedCharts';


const ChartFlex = ({genderCounts, aadhaarVerifiedCounts}) => {
  return (
    <div>
        <Flex w={"100%"} m={'auto'} flex={1} flexWrap={"wrap"}>
        <Box>
        <GenderCharts genderCounts={genderCounts}/>
      </Box>

      <Box>
        <AadhaarVerifiedPieCharts aadhaarVerifiedCounts={aadhaarVerifiedCounts}/>
      </Box>

      <Box>
        <AadhaarVerifiedPieCharts aadhaarVerifiedCounts={aadhaarVerifiedCounts}/>
      </Box>

        </Flex>
    </div>
  )
}

export default ChartFlex