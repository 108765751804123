import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';
import './VisitorsChart.css'; // Import CSS file for styles

// Sample data for profit over the last 7 days
const data = [
  { day: 'Jan', profit: 0 },
  { day: 'Feb', profit: 0 },
  { day: 'Mar', profit: 0 },
  { day: 'Apr', profit: 0 },
  { day: 'May', profit: 0 },
  { day: 'Jun', profit: 0 },
  { day: 'Jul', profit: 20 },
  { day: 'Aug', profit: 60 },
  { day: 'Sept', profit: 100 },
  { day: 'Oct', profit: 0 },
  { day: 'Nov', profit: 0 },
  { day: 'Dec', profit: 0 },
];

const ProfitChart = () => {
  return (
    <div className="chart-container">
      <h2>Profit Over the Last 12 Months</h2>
      <BarChart
        width={window.innerWidth < 768 ? 300 : 550} // Adjust width for mobile
        height={window.innerWidth < 768 ? 200 : 300} // Adjust height for mobile
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="day" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="profit" fill="#8884d8" />
      </BarChart>
    </div>
  );
};

export default ProfitChart;
