// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import { Box, Input, List, ListItem, Spinner, Table, Tbody, Th, Tr, Thead, Td } from '@chakra-ui/react';
// import { API_URL } from "../../utils/apiConfig";

// const AthleteSearch = () => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [searchResults, setSearchResults] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();

//   // Debounced search - only make API call when user stops typing for a short period
//   useEffect(() => {
//     const delayDebounceFn = setTimeout(() => {
//       if (searchTerm) {
//         fetchSearchResults(searchTerm);
//       } else {
//         setSearchResults([]);
//       }
//     }, 500); // 500ms debounce time

//     return () => clearTimeout(delayDebounceFn);
//   }, [searchTerm]);

//   // Function to fetch search results from the API
//   const fetchSearchResults = async (query) => {
//     setLoading(true);
//     try {
//       const response = await axios.get(`${API_URL}/api/athlete/search?query=${query}`);
//       setSearchResults(response.data.slice(0, 10)); // Show top 10 results
//     } catch (error) {
//       console.error('Error fetching search results:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Handle input change for search
//   const handleSearchChange = (e) => {
//     setSearchTerm(e.target.value);
//   };

//   // Handle click on athlete to view details
//   const handleAthleteClick = (athleteId) => {
//     navigate(`/admin/superadmin/dashboard/athlete/athletes/${athleteId}`); // Navigate to athlete details page
//   };

//   return (
//     <Box p="4">
//       <Input
//         placeholder="Search by name, athlete code, or email"
//         value={searchTerm}
//         onChange={handleSearchChange}
//         mb="4"
//         size="md"
//         width="90%"
//         m={"auto"}
//       />
//       {loading ? (
//         <Spinner />
//       ) : (
//         <Box overflowX="auto"
//         width="100%"
//         style={{ WebkitOverflowScrolling: 'touch' }}>
//         <Table
//         // variant="striped"
//         width="90%"
//         margin="auto"
//         borderRadius="20px"
//         // bg="#ffbf00"
//         bg="gray.900"
//         color="white"
//         fontSize={"12px"}
//         fontFamily={"sans-serif"}
        
//         // colorScheme="gray"
//         style={{
//           boxShadow: 'rgba(0, 0, 0, 0.25) 0px 15px 25px, rgba(0, 0, 0, 0.22) 0px 10px 10px',
//           position: 'relative', // Make sure the table itself is positioned relative to handle any absolute positioning within it
//           overflow: 'hidden' // Ensure any overflow from rows is hidden
//         }}>
//           <Thead >
//             <Tr>
//               <Th color={"white"}>Name</Th>
//               <Th color={"white"}>Athlete Code</Th>
//               <Th  color={"white"}>Email</Th>
//             </Tr>
//           </Thead>
//           <Tbody>
//           {searchResults.map((athlete, index) => (
//             <Tr
            
//             key={athlete._id}
//             cursor="pointer"
//             onClick={() => handleAthleteClick(athlete.email)}
//             _hover={{ backgroundColor: 'gray.100' }}
//             p="2"
//             style={{ position: 'relative', overflow: 'hidden' , backgroundColor: index % 2 === 1 ? 'gray' : 'white', color: index % 2 === 1 ? 'white':'black' }}>
//               <Td fontWeight="bold">{athlete.name}</Td>
//               <Td fontWeight="bold">{athlete.athleteCode}</Td>
//               <Td fontWeight="bold">{athlete.email}</Td>
//             </Tr>
//           ))}
//           </Tbody>
//         </Table>
//         </Box>
//         // <List spacing={3}>
//         //   {searchResults.map((athlete) => (
//         //     <ListItem
//         //       key={athlete._id}
//         //       cursor="pointer"
//         //       onClick={() => handleAthleteClick(athlete.email)}
//         //       _hover={{ backgroundColor: 'gray.100' }}
//         //       p="2"
//         //     >
//         //       {athlete.name} ({athlete.athleteCode}) - {athlete.email}
//         //     </ListItem>
//         //   ))}
//         // </List>
//       )}
//     </Box>
//   );
// };

// export default AthleteSearch;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Box, Input, Spinner, Table, Tbody, Th, Tr, Thead, Td, Flex, Heading } from '@chakra-ui/react';
import { API_URL } from "../../utils/apiConfig";
import SideBar from "./Sidebar/SideBar"

const AthleteSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Debounced search - only make API call when user stops typing for a short period
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        fetchSearchResults(searchTerm);
      } else {
        setSearchResults([]);
      }
    }, 500); // 500ms debounce time

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  // Function to fetch search results from the API
  const fetchSearchResults = async (query) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/api/athlete/search?query=${query}`);
      setSearchResults(response.data.slice(0, 10)); // Show top 10 results
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle input change for search
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle click on athlete to view details
  const handleAthleteClick = (athleteId) => {
    navigate(`/admin/superadmin/dashboard/athlete/athletes/${athleteId}`); // Navigate to athlete details page
  };

  return (
    <SideBar>
    <Box p="4" minHeight={"90vh"}>
      <Heading textAlign={"center"}>Search Athlete</Heading>
      <Flex flex={1} justifyContent={"center"}>
      <Input
        placeholder="Search by name, athlete code, or email"
        value={searchTerm}
        onChange={handleSearchChange}
        mb="4"
        size="md"
        width="90%"
       
        
      />
      </Flex>
      {loading ? (
        <Flex flex={1} justifyContent={"center"}>
        <Spinner size={"xl"} />
        </Flex>
      ) : (
        <Box overflowX="auto" width="100%" style={{ WebkitOverflowScrolling: 'touch' }}>
          <Table
            width="90%"
            margin="auto"
            borderRadius="20px"
            bg="gray.900"
            color="white"
            fontSize={"12px"}
            fontFamily={"sans-serif"}
            style={{
              boxShadow: 'rgba(0, 0, 0, 0.25) 0px 15px 25px, rgba(0, 0, 0, 0.22) 0px 10px 10px',
              position: 'relative',
              overflow: 'hidden'
            }}
          >
            {searchTerm && (
              <Thead>
                <Tr>
                  <Th color={"white"}>Name</Th>
                  <Th color={"white"}>Athlete Code</Th>
                  <Th color={"white"}>Email</Th>
                </Tr>
              </Thead>
            )}
            <Tbody>
              {searchResults.length > 0 ? (
                searchResults.map((athlete, index) => (
                  <Tr
                    key={athlete._id}
                    cursor="pointer"
                    onClick={() => handleAthleteClick(athlete.email)}
                    _hover={{ backgroundColor: 'gray.100' }}
                    p="2"
                    style={{
                      position: 'relative',
                      overflow: 'hidden',
                      backgroundColor: index % 2 === 1 ? 'gray' : 'white',
                      color: index % 2 === 1 ? 'white' : 'black'
                    }}
                  >
                    <Td fontWeight="bold">{athlete.name}</Td>
                    <Td fontWeight="bold">{athlete.athleteCode}</Td>
                    <Td fontWeight="bold">{athlete.email}</Td>
                  </Tr>
                ))
              ) : searchTerm ? (
                <Tr>
                  <Td colSpan={3} textAlign="center">
                    Athlete not found
                  </Td>
                </Tr>
              ) : null}
            </Tbody>
          </Table>
        </Box>
      )}
    </Box>
    </SideBar>
  );
};

export default AthleteSearch;
