// Navbar.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import WithSubnavigation from "./Nav";
import SignUpNavbar from './SignUpNavbar';
import AdminNavbar from './AdminNavbar';
import {useParams} from 'react-router-dom'
import { useAuth } from '../context/AuthContext';

const Navbar = () => {
  const location = useLocation();
  const {isAdmin} = useAuth
  const {eventId, game, playerId} = useParams()

  // List of routes that should not have the navbar
  const noNavbarRoutes = ['/signin', '/signup'];

  const dynamicNoNavbarRoutes = [
    /^\/supervisorpanel\/[^\/]+\/[^\/]+$/,          // /supervisorpanel/:eventId/:game
    /^\/supervisorpanel\/[^\/]+\/[^\/]+\/[^\/]+$/,
    /^\/event\/[^\/]+\/[^\/]+$/,
    /^\/event\/[^\/]+\/[^\/]+\/[^\/]+$/
  ];

  // Check if the current route is in the list of routes without the navbar
  // const shouldShowNavbar = !noNavbarRoutes.includes(location.pathname);

  // return shouldShowNavbar ? <WithSubnavigation /> : <SignUpNavbar/>;
  const shouldShowNavbar = !noNavbarRoutes.includes(location.pathname) &&
    !dynamicNoNavbarRoutes.some(pattern => pattern.test(location.pathname));

    if (location.pathname === '/admin/superadmin/dashboard' && isAdmin) {
      return <AdminNavbar />;
    }

  return shouldShowNavbar ? <WithSubnavigation /> : <SignUpNavbar />;
};

export default Navbar;
