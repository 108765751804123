import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import OtpInput from "otp-input-react";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../../firebase.config";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import image from "../../google.png";
import {API_URL} from "../../utils/apiConfig";

const SignUp = () => {
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [adminCount, setAdminCount] = useState(0);
  const navigate = useNavigate();

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            if (showOTP) {
              onOTPVerify();
            } else {
              onSignup();
            }
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  }

  useEffect(() => {
    const fetchAdminCount = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/admin/superadmin/admin-count`);
        setAdminCount(response.data.count);
      } catch (error) {
        console.error('Error fetching admin count:', error);
      }
    };

    fetchAdminCount();
  }, []);

  async function onSignupWithEmail() {
    if (adminCount >= 3) {
        toast.error('Admin limit reached. No more signups allowed.');
        return;
      }
  
      setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      setUser(userCredential.user);

      const userData = {
        name: name,
        email: email,
        mobile: ph,
        password: password,
      };

      try {
        const response = await axios.post(
          "`${API_URL}/api/admin/superadmin/signup",
          userData
        );
        setLoading(false);
        toast.success(response.data.msg);
        setTimeout(() => {
          navigate("/admin/superadmin/dashboard");
        }, 2000);
      } catch (error) {
        setLoading(false);
        const errorMsg = error.response
          ? error.response.data.msg
          : error.message;
        toast.error(`${errorMsg}`);
        // Optionally, delete the created user from Firebase to maintain consistency
        await userCredential.user.delete();
      }
    } catch (error) {
      setLoading(false);
      if (error.message === "Firebase: Error (auth/email-already-in-use).") {
        toast.error("Email is already in use, Try to Login");
      } else {
        toast.error(`${error.message}`);
      }
    }
  }

  function onSignup() {
    setLoading(true);
    onCaptchVerify();
    const appVerifier = window.recaptchaVerifier;
    const formatPh = "+" + ph;
    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP sent successfully!");
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Failed to send OTP. Please try again.");
      });
  }

  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        setUser(res.user);
        setLoading(false);
        toast.success("OTP Verification Successful");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Invalid OTP. Please try again.");
      });
  }

  return (
    <Box
      bg={"gray.800"} 
      // className="flex items-center justify-center"
      style={{ backgroundColor: showOTP ? "auto" : "" }}
    >
      <Box w={"90%"} m={"auto"} background="gray.800">
        <Toaster toastOptions={{ duration: 4000 }} />
        <div id="recaptcha-container"></div>
        <br />
        <Box
          // marginLeft={{ sm: "auto", md: "auto", lg: "500px" }}
          // borderRadius={"20px"}
          padding={"10px"}
          w={{ sm: "100%", md: "50%", lg: "30%" }}
          m={"auto"}
          // style={{ border: "1px solid white" }}
          borderRadius="100%"
          boxShadow="md"
          border="1px solid" // Border thickness
          style={{
            borderImageSource:
              "linear-gradient(to right, #f6e05e, #ecc94b, #d69e2e)", // Gradient colors
            borderImageSlice: 1, // Controls how the gradient is sliced into the border
          }}
        >
          <Box w={"100%"}>
            <Heading textAlign={"center"} mt={"10px"} color={"white"}>
              Admin SignUp
            </Heading>
              <Box
                w={{ base: "100%", md: "49%", lg: "100%" }}
                className="flex flex-col gap-4 rounded-lg p-4"
              >
                <FormLabel marginBottom={"-10px"} color={"white"}>
                  Name<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  required
                  bgColor={"white"}
                  placeholder="Enter name"
                  value={name}
                  w={"100%"}
                  onChange={(e) => setName(e.target.value)}
                />
              </Box>
              <Box
                w={{ base: "100%", md: "49%", lg: "100%" }}
                className="flex flex-col gap-4 rounded-lg p-4"
              >
                <FormLabel marginBottom={"-10px"} color={"white"}>
                  Email<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  required
                  bgColor={"white"}
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>
              <Box
                w={{ base: "100%", md: "49%", lg: "100%" }}
                className="flex flex-col gap-4 rounded-lg p-4"
              >
                <FormLabel marginBottom={"-10px"} color={"white"}>
                  Password<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  required
                  bgColor={"white"}
                  placeholder="Create Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Input
                  required
                  bgColor={"white"}
                  placeholder="Confirm Password"
                  type="password"
                />
              </Box>
            <Box className="flex flex-col gap-4 rounded-lg p-4">
              <Button
                w={{ base: "100%", md: "50%", lg: "50%" }}
                m={"auto"}
                bgGradient="linear(to-r, yellow.300, yellow.400, yellow.600)"
                onClick={onSignupWithEmail}
              >
                Admin Register
              </Button>
              <Text color={"white"} textAlign={"center"}>
                Already have an account?
                <Link
                  style={{ textDecoration: "underline", color:"#ffbc0d"}}    
                  to="/admin/superadmin/login"
                >
                  {" "}
                  Login
                </Link>
              </Text>
            </Box>
          </Box>
          <br />
        </Box>
        <br />
        <br />
      </Box>
    </Box>
  );
};

export default SignUp;
