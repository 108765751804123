import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {API_URL} from "../utils/apiConfig";

function LiveScore() {
  const [approvedPlayers, setApprovedPlayers] = useState([]);

  useEffect(() => {
    fetchApprovedPlayers();
  }, []);

  const fetchApprovedPlayers = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/approved-players`);
      setApprovedPlayers(response.data);
    } catch (error) {
      console.error('Error fetching approved players:', error);
    }
  };

  return (
    <div className="App">
      <h1>Approved Players List</h1>
      {approvedPlayers.length > 0 ? (
        <ul>
          {approvedPlayers.map((player, index) => (
            <li key={index}>
              <h3>{player.name}</h3>
              <p>Sport: {player.sport}</p>
              <p>Mobile: {player.mobile}</p>
              <p>E Score: {player.scores.e}</p>
              <p>D Score: {player.scores.d1}</p>
              <p>Total: {player.scores.total}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No approved players found.</p>
      )}
    </div>
  );
}

export default LiveScore;
