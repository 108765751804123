import React, { useState } from 'react';
import { GiHamburgerMenu } from "react-icons/gi";
import { FaTimes, FaChevronDown } from "react-icons/fa";
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import { Box, Flex, Text, Button, IconButton, List, ListItem, Collapse, useBreakpointValue, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverArrow, PopoverCloseButton, PopoverBody, Heading } from "@chakra-ui/react";

const AdminNavbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { user, logout, userRole, isAdmin } = useAuth();

    const toggleNav = () => {
        setIsOpen(!isOpen);
    };

    // Determine if it's desktop or mobile view
    const isDesktop = useBreakpointValue({ base: false, md: true });

    return (
        <Box
            as="header"
            position="fixed"
            top="0"
            left="0"
            width="100%"
            height="75px"
            zIndex="999"
            backgroundColor="white" // Change this to your desired background color
            boxShadow="md" // Add shadow for a raised effect (optional)   
            justifyContent="center"
            alignItems="center"
            p={4}
            background="gray.800"
        >
            <Flex as="nav" height="100%" align="center" justify="space-between" p="4">
                <Box className="logo">
                    <Heading bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" bgClip={"text"} as="h1">Gymnazien Admin</Heading>
                </Box>
                <Box flex="1" textAlign="center">
                    <DesktopNav />
                </Box>
            </Flex>

            <Collapse in={isOpen && !isDesktop} animateOpacity>
                <MobileNav />
            </Collapse>
        </Box>
    );
};

const DesktopNav = () => {
    return (
        <List display={{ base: "none", md: "flex" }} justifyContent="center">
            {NAV_ITEMS.map((navItem) => (
                <ListItem fontSize={"18px"} key={navItem.label} mx="7">
                    <DesktopNavItem {...navItem} />
                </ListItem>
            ))}
        </List>
    );
};

const DesktopNavItem = ({ label, children }) => {
    const hasChildren = children && children.length > 0;

    return (
        <Box className="nav-item">
            {hasChildren ? (
                <Popover isLazy>
                    <PopoverTrigger>
                        <Button fontSize={"18px"} variant="link">{label}</Button>
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader fontWeight='semibold'>{label}</PopoverHeader>
                        <PopoverBody>
                            <List>
                                {children.map((child) => (
                                    <ListItem key={child.label}>
                                        <Text as="a" href="#" display="block" py="2">
                                            {child.label}
                                        </Text>
                                    </ListItem>
                                ))}
                            </List>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            ) : (
                <Text as="a" href="#" mr="2">
                    {label}
                </Text>
            )}
        </Box>
    );
};

const MobileNav = () => {
    return (
        <List display={{ base: "block", md: "none" }}>
            {NAV_ITEMS.map((navItem) => (
                <ListItem key={navItem.label} mx="4">
                    <MobileNavItem {...navItem} />
                </ListItem>
            ))}
        </List>
    );
};

const MobileNavItem = ({ label, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSubNav = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Box className="mobile-nav-item">
            <Button onClick={toggleSubNav} variant="ghost" mr="2">
                {label} <FaChevronDown />
            </Button>
            <Collapse in={isOpen} animateOpacity>
                <List className="mobile-dropdown">
                    {children &&
                        children.map((child) => (
                            <ListItem key={child.label} as="a" href="#">
                                {child.label}
                            </ListItem>
                        ))}
                </List>
            </Collapse>
        </Box>
    );
};

const NAV_ITEMS = [
];

export default AdminNavbar;
