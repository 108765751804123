import {
  Box,
  Flex,
  IconButton,
  Input,
  VStack,
  HStack,
  Text,
  useBreakpointValue,
  Heading,
} from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";
import { FaBars, FaHome, FaLock, FaMoneyBill, FaTimes, FaUser } from "react-icons/fa";
import { MdMessage } from "react-icons/md";
import { BiAnalyse, BiSearch, BiCog } from "react-icons/bi";
import { AiFillHeart, AiTwotoneFileExclamation } from "react-icons/ai";
import { BsCartCheck } from "react-icons/bs";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu"; // Adjust the import path accordingly
import { IoSearchSharp } from "react-icons/io5";

const routes = [
  {
    path: "/admin/superadmin/dashboard",
    name: "Dashboard",
    icon: <FaHome />,
  },
  {
    path: "/admin/superadmin/dashboard/athlete/athleteslist",
    name: "Athletes List",
    icon: <FaUser />,
    exact: true,
    subRoutes: [
      {
        path: "/users/vendor",
        name: "Vendor ",
        icon: <FaUser />,
      },
      {
        path: "/users/bride",
        name: "Bride",
        icon: <FaUser />,
      },
      {
        path: "/users/groom",
        name: "Groom",
        icon: <FaUser />,
      },
    ],
  },
  {
    path: "/admin/superadmin/dashboard/athlete/athletesearch",
    name: "Search Athlete",
    icon: <IoSearchSharp />,
  },
  {
    path: "/admin/superadmin/dashboard/athlete/athletesanalytics",
    name: "Athletes Analytics",
    icon: <BiAnalyse />,
  },
  {
    path: "/admin/superadmin/dashboard/users/userslist",
    name: " UserList",
    icon: <AiTwotoneFileExclamation />,
    subRoutes: [
      {
        path: "/settings/profile",
        name: "Profile ",
        icon: <FaUser />,
      },
      {
        path: "/settings/2fa",
        name: "2FA",
        icon: <FaLock />,
      },
      {
        path: "/settings/billing",
        name: "Billing",
        icon: <FaMoneyBill />,
      },
    ],
  },
  {
    path: "/order",
    name: "Order",
    icon: <BsCartCheck />,
  },
  {
    path: "/settings",
    name: "Settings",
    icon: <BiCog />,
    exact: true,
    // subRoutes: [
    //   {
    //     path: "/settings/profile",
    //     name: "Profile ",
    //     icon: <FaUser />,
    //   },
    //   {
    //     path: "/settings/2fa",
    //     name: "2FA",
    //     icon: <FaLock />,
    //   },
    //   {
    //     path: "/settings/billing",
    //     name: "Billing",
    //     icon: <FaMoneyBill />,
    //   },
    // ],
  },
  {
    path: "/saved",
    name: "Saved",
    icon: <AiFillHeart />,
  },
];

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const location = useLocation();

  // Calculate the sidebar width based on its state
  const sidebarWidth = useBreakpointValue({
    base: isOpen ? "40vw" : "17vw", // For mobile
    md: isOpen ? "30vw" : "10vw", // For tablet
    lg: isOpen ? "17vw" : "5vw", // For desktop
  });

  // Calculate the content width based on the sidebar state
  const contentWidth = useBreakpointValue({
    base: isOpen ? "40vw" : "90vw", // For mobile
    md: isOpen ? "70vw" : "90vw", // For tablet
    lg: isOpen ? "70vw" : "90vw", // For desktop
  });

  return (
    <Flex width="95vw" minHeight="100vh" direction="row">
      <Box
        as={motion.div}
        width={sidebarWidth}
        // borderRadius={"10px"}
        minH="100vh"
        bg="gray.800"
        color="white"
        initial={{ width: "10vw" }}
        animate={{ width: sidebarWidth }}
        transition={{ duration: 0.5, type: "spring", damping: 10 }}
      >
        <VStack align="start" spacing="5">
          <HStack justify="space-between" w="100%">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <Heading bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
                          bgClip="text" fontWeight={"bold"} ml={"10px"}mt={"5px"} fontSize={{base:"18px", md:"26px", lg:"30px"}}>Gymnazien</Heading>
                </motion.h1>
              )}
            </AnimatePresence>
            <IconButton
              icon={isOpen ? <FaTimes /> : <FaBars />}
              onClick={toggle}
              aria-label="Toggle Sidebar"
              variant="ghost"
              colorScheme="white"
              w={"100%"}
              mt={"10px"}
              // p="4"
                  mx={{base:"2",md:"4"}}
            />
          </HStack>
          <VStack align="start" w="100%">
            {routes.map((route, index) => (
              <NavLink key={index} to={route.path}>
                <Flex
                  align="center"
                  p="4"
                  mx="3"
                  borderRadius="lg"
                  role="group"
                  cursor="pointer"
                  _hover={{
                    // bg: "cyan.400",
                    color: "white",
                    bgGradient:"linear(to-r, yellow.300, yellow.400, yellow.500)",
                  }}
                  width={isOpen ? {base:"130px", md:"150px", lg:"200px"} : "auto"}
                  bg={location.pathname === route.path ? "red" : "transparent"}
                  bgGradient={location.pathname === route.path ? "linear(to-r, yellow.400, yellow.500, yellow.600)" : "transparent"}
                >
                  <Box as="span" mr={isOpen ? 3 : 0}>
                    {route.icon}
                  </Box>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -50 }}
                      >
                        <Text>{route.name}</Text>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Flex>
              </NavLink>
            ))}
          </VStack>
        </VStack>
      </Box>
      <Box flex="1" width={contentWidth} p="4">
        {children}
      </Box>
    </Flex>
  );
};

export default SideBar;
