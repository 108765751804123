import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { useReactToPrint } from 'react-to-print';
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { API_URL } from "../../utils/apiConfig";

const AllAthletelist = () => {
  const { eventId } = useParams();
  const { user, userSupervisorRole, loading: userLoading } = useAuth();
  const [event, setEvent] = useState(null);
  const [allPlayers, setAllPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const componentPDF = useRef();

  // Function to fetch player details from the API
  const fetchPlayerDetails = async (player) => {
    try {
      const response = await axios.get(`${API_URL}/api/athlete/athletes/all`);
      const athletes = response.data;
      console.log('Fetched athletes data:', athletes);

      const matchingAthlete = athletes.find(athlete =>
        athlete.name === player.name ||
        athlete.email === player.email ||
        athlete.mobile === player.mobile
      );

      console.log('Matching athlete for player', player, ':', matchingAthlete);

      if (matchingAthlete) {
        return {
          dob: matchingAthlete.dob,
          athleteCode: matchingAthlete.athleteCode
        };
      } else {
        console.warn('No matching athlete found for player:', player);
        return { dob: 'N/A', athleteCode: 'N/A' };
      }
    } catch (error) {
      console.error('Error fetching player details:', error);
      return { dob: 'N/A', athleteCode: 'N/A' };
    }
  };

  // Function to calculate age from DOB
  const calculateAge = (dob) => {
    if (!dob || dob === 'N/A') return 'N/A';
    const birthDate = new Date(dob);
    const ageDifMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  // Function to format date to dd/mm/yyyy
  const formatDate = (dob) => {
    if (!dob || dob === 'N/A') return 'N/A';
    const date = new Date(dob);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  };

  useEffect(() => {
    if (!userLoading) {
      if (!user || userSupervisorRole !== 'supervisor') {
        navigate('/supervisor');
        return;
      }

      const fetchAllPlayers = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/events/${eventId}`);
          const eventDetails = response.data;
          setEvent(eventDetails);
          console.log("Fetched Event Details:", eventDetails);

          if (eventDetails && eventDetails.players && eventDetails.sports) {
            const enabledGames = Object.keys(eventDetails.sports || {}).filter(game => eventDetails.sports[game]);

            const aggregatedPlayers = {};

            for (const game of enabledGames) {
              const gamePlayers = eventDetails.players[game] || []; // Default to an empty array if no players

              console.log(`Processing players for game: ${game}`, gamePlayers);

              for (const player of gamePlayers) {
                const uniqueId = player.email || player.mobile;

                if (!aggregatedPlayers[uniqueId]) {
                  console.log('Processing player:', player);

                  // Fetch details if dob is missing
                  const { dob, athleteCode } = player.dob ? 
                    { dob: player.dob, athleteCode: player.athleteCode } : 
                    await fetchPlayerDetails(player);

                  console.log('Fetched details:', { dob, athleteCode });

                  aggregatedPlayers[uniqueId] = {
                    name: player.name,
                    scores: {},
                    totalAllAround: 0,
                    teamName: 'N/A', // Default team name
                    dob: dob,
                    athleteCode: athleteCode
                  };

                  // Check if player is in any team
                  let teamFound = false;
                  Object.keys(eventDetails.teams || {}).forEach((team) => {
                    const teamMembers = eventDetails.teams[team].gymnasts || [];
                    const match = teamMembers.some((member) =>
                      (member.email && member.email === player.email) ||
                      (member.mobile && member.mobile === player.mobile)
                    );
                    if (match) {
                      aggregatedPlayers[uniqueId].teamName = team;
                      teamFound = true;
                    }
                  });

                  // If no team found, use player.city as teamName
                  if (!teamFound) {
                    aggregatedPlayers[uniqueId].teamName = player.city || 'N/A';
                    console.log(`Player ${player.name} not found in any team. Using city as team name.`);
                  }

                  if (!teamFound) {
                    console.log(`Player ${player.name} not found in any team.`);
                  }
                }

                aggregatedPlayers[uniqueId].scores[game] = {
                  e: player.scores.e,
                  d1: player.scores.d1,
                  penalty: player.scores.penalty,
                  total: player.scores.total,
                };

                aggregatedPlayers[uniqueId].totalAllAround += player.scores.total;
              }
            }

            const sortedPlayers = Object.values(aggregatedPlayers).sort((a, b) => b.totalAllAround - a.totalAllAround);
            setAllPlayers(sortedPlayers);

            if (sortedPlayers.length === 0) {
              console.error('No players aggregated. Please check the data processing.');
            }
          } else {
            console.error('Event details are incomplete or missing.');
          }
        } catch (error) {
          console.error('Error fetching players:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchAllPlayers();
    }
  }, [userLoading, user, userSupervisorRole, eventId, navigate]);

  const handleBack = () => {
    navigate(`/supervisor`);
  };

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: 'all-sports-scores',
    onAfterPrint: () => alert('PDF export successful'),
  });

  if (loading || userLoading) {
    return <div>Loading...</div>;
  }

  if (!user || userSupervisorRole !== 'supervisor') {
    return <div>You are not authorized to view this page.</div>;
  }

  const enabledGames = event ? Object.keys(event.sports).filter(game => event.sports[game]) : [];

  return (
    <Box bg={"gray.200"} style={{ padding: '20px', minHeight: '100vh' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <button onClick={handleBack} style={{ padding: '10px 20px' }}>Back</button>
        <Button onClick={generatePDF} style={{ padding: '10px 20px', border: 'none' }} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)">Export to PDF</Button>
      </div>

      <div ref={componentPDF} style={{ width: '95%', margin: 'auto', overflowX: 'auto' }}>
        <Box>
          <Heading size="md" style={{ color: 'black', textAlign: 'center' }}>{event.eventName}</Heading>
          <Heading size="md" style={{ color: 'black', textAlign: 'center' }}>All Players List</Heading>
          <Text textAlign={"center"} color={"black"}>Date: {new Date(event.dateTime).toLocaleString()}</Text>
        </Box>
        {allPlayers.length > 0 ? (
          <table style={{ width: '100%', borderCollapse: 'collapse', backgroundColor: 'white', tableLayout: 'fixed' }}>
            <thead style={{ color: 'black' }}>
              <tr>
                <th style={{ padding: '10px', border: '1px solid #ddd', width: '7%' }}>SR</th>
                <th style={{ padding: '10px', border: '1px solid #ddd', width: '20%' }}>Name</th>
                <th style={{ padding: '10px', border: '1px solid #ddd', width: '10%' }}>Athlete Code</th>
                <th style={{ padding: '10px', border: '1px solid #ddd', width: '15%' }}>Team/Dist</th>
                <th style={{ padding: '10px', border: '1px solid #ddd', width: '10%' }}>Age</th>
                <th style={{ padding: '10px', border: '1px solid #ddd', width: '10%' }}>DOB</th>
              </tr>
            </thead>
            <tbody>
              {allPlayers.map((player, index) => (
                <tr key={index} style={{ textAlign: 'center' }}>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>{index + 1}</td>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>{player.name}</td>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>{player.athleteCode || 'N/A'}</td>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>{player.teamName || 'N/A'}</td>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>{calculateAge(player.dob)}</td>
                  <td style={{ padding: '10px', border: '1px solid #ddd' }}>{formatDate(player.dob)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div style={{ textAlign: 'center', color: 'white' }}>
            <p>No players found.</p>
          </div>
        )}
      </div>
    </Box>
  );
};

export default AllAthletelist;
