import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, Button, Heading, List, ListItem, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import borderDesignIcon from "../certificateBorder.png"
import {API_URL} from "../utils/apiConfig";
// const borderDesignIcon = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAgAElEQVR4nOzdd1hUZfv8o+WcoSwCGKsgCkEjVxQFmZ7nXaWysK2ySmCCTEAgKCAcVFEEhCJgkYZFQQGEdCIRqjIAYcMJb4+/eU8EsJ5zt86Zy5y9zr3ryv3/vV3VV1XVbdeem/jkvX6mZIkc4XKaJt5vvFtRFDEEIIYQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCCCEEGf0IQQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0IQQQgghhBBCiEEJwEF0AQQgghhBBCCCEEGf0'


const CertificatesEventWise = () => {
  const [view, setView] = useState('events');
  const [events, setEvents] = useState([]);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [currentSport, setCurrentSport] = useState(null);
  const [sports, setSports] = useState([]);
  const [players, setPlayers] = useState([]);
  const componentPDF = useRef();

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/approved-players-spot`);
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const handleEventClick = (eventName) => {
    setCurrentEvent(eventName);
    setSports(events[eventName]);
    setView('sports');
  };

  const handleSportClick = (sportName) => {
    setCurrentSport(sportName);
    const sortedPlayers = sports[sportName].sort((a, b) => b.scores.total - a.scores.total);
    setPlayers(sortedPlayers);
    setView('players');
  };

  const handleBack = () => {
    if (view === 'sports') {
      setView('events');
      setCurrentEvent(null);
    } else if (view === 'players') {
      setView('sports');
      setCurrentSport(null);
    }
  };

//   const generateCertificate = async (player) => {
//   try {
//     const input = document.getElementById(`certificate-${player._id}`);
//     if (!input) {
//       throw new Error(`Element with id certificate-${player._id} not found`);
//     }
//     const canvas = await html2canvas(input);
//     const imgData = canvas.toDataURL('image/jpeg');
    
//     // Create a new jsPDF instance with landscape orientation
//     const pdf = new jsPDF('l', 'mm', 'a4');
    
//     // Add the border design icon to create the border
//     // pdf.addImage(borderDesignIcon, 'PNG', 0, 0, 297, 210); // Adjust the dimensions to fit the entire page with a 10px margin
//     // pdf.addImage(borderDesignIcon, 'JPEG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
//     pdf.addImage(imgData, 'JPEG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
//     // Add certificate content
//     pdf.setFontSize(20);
//     pdf.setFont('helvetica', 'bold');
//     pdf.text('Certificate of Achievement', pdf.internal.pageSize.getWidth() / 2, 50, { align: 'center' }); // Center the text
//     pdf.setFont('helvetica', 'normal');

//     pdf.setFontSize(14);
//     pdf.text(`This is to certify that`, 30, 70); // Adjust the coordinates as needed
//     pdf.text(`${player.name}`, 30, 90); // Adjust the coordinates as needed
//     pdf.text(`has shown outstanding performance.`, 30, 110); // Adjust the coordinates as needed
    
//     pdf.text(`Total Score: ${player.scores.total}`, 30, 130); // Adjust the coordinates as needed

//     return pdf.output('blob');
//   } catch (error) {
//     console.error(`Error generating certificate for ${player.name}:`, error);
//     throw new Error('Failed to create certificate');
//   }
// };


  const generateCertificate = async (player) => {
    try {
      const input = document.getElementById(`certificate-${player._id}`);
      if (!input) {
        throw new Error(`Element with id certificate-${player._id} not found`);
      }
      const canvas = await html2canvas(input);
      const imgData = canvas.toDataURL('image/jpeg');
      const pdf = new jsPDF('l', 'mm', 'a4');pdf.addImage(imgData, 'JPEG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
      // Add certificate content
    pdf.setFontSize(20);
    pdf.setFont('helvetica', 'bold');
    pdf.text('Certificate of Achievement', pdf.internal.pageSize.getWidth() / 2, 50, { align: 'center' }); // Center the text
    pdf.setFont('helvetica', 'normal');

    pdf.setFontSize(14);
    pdf.text(`This is to certify that`, 30, 70); // Adjust the coordinates as needed
    pdf.text(`${player.name}`, 30, 90); // Adjust the coordinates as needed
    pdf.text(`has shown outstanding performance.`, 30, 110); // Adjust the coordinates as needed
    
    pdf.text(`Total Score: ${player.scores.total}`, 30, 130); // Adjust the coordinates as needed

      
      return pdf.output('blob');
    } catch (error) {
      console.error(`Error generating certificate for ${player.name}:`, error);
      throw new Error('Failed to create certificate');
    }
  };
 
  // const generateCertificate = async (player) => {
  //   try {
  //     const pdf = new jsPDF('l', 'mm', 'a4'); // 'l' for landscape orientation
      
  //     // Add the border design icon to create the border
  //     pdf.addImage(borderDesignIcon, 'PNG', 0, 0, 297, 210); // Adjust the dimensions to fit the entire page with a 10px margin
      
  //     // Add certificate content
  //     pdf.setFontSize(20);
  //     pdf.text(`Certificate of Achievement`, 30, 50); // Adjust the coordinates as needed
      
  //     pdf.setFontSize(14);
  //     pdf.text(`This is to certify that`, 30, 70); // Adjust the coordinates as needed
  //     pdf.text(`${player.name}`, 30, 90); // Adjust the coordinates as needed
  //     pdf.text(`has shown outstanding performance.`, 30, 110); // Adjust the coordinates as needed
      
  //     pdf.text(`Total Score: ${player.scores.total}`, 30, 130); // Adjust the coordinates as needed
      
  //     return pdf.output('blob');
  //   } catch (error) {
  //     console.error(`Error generating certificate for ${player.name}:`, error);
  //     throw new Error('Failed to create certificate');
  //   }
  // };
  
  const sendCertificates = async () => {
    for (const player of players) {
      try {
        const certificateBlob = await generateCertificate(player);
        const formData = new FormData();
        formData.append('certificate', certificateBlob, 'certificate.pdf');
        formData.append('player', JSON.stringify(player));
        
        await axios.post(`${API_URL}/api/send-email`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        alert(`Certificate sent to ${player.name}`);
      } catch (error) {
        console.error('Error sending email:', error);
        alert(`Failed to send certificate to ${player.name}`);
      }
    }
  };
  
  

  return (
    <Box height={"100vh"} bg={"#26263c"} p={4}>
      {view === 'events' && (
        <List spacing={3}>
          {Object.keys(events).map((eventName, index) => (
            <ListItem color={"white"} key={index} onClick={() => handleEventClick(eventName)} cursor="pointer">
              {eventName}
            </ListItem>
          ))}
        </List>
      )}
      {view === 'sports' && (
        <Box>
          <Button onClick={handleBack} mb={4}>Back</Button>
          <Heading color={"white"} as="h2" mb={4}>{currentEvent}</Heading>
          <List spacing={3}>
            {Object.keys(sports).map((sportName, index) => (
              <ListItem color={"white"} key={index} onClick={() => handleSportClick(sportName)} cursor="pointer">
                {sportName}
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      {view === 'players' && (
        <Box>
            <Heading color={"white"} size={"lg"} mb={4}>Gymnazien Sports Compititions Certificates</Heading>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Button onClick={handleBack} mb={4}>Back</Button>
            <Heading color={"white"} as="h3" mb={4}>{currentSport}</Heading>
            <Button bg={"#ffbf00"} onClick={sendCertificates}>Send Certificates</Button>
          </Box>
          
          <div ref={componentPDF} style={{ width: "100%" }}>
            {players.length > 0 ? (
              <Table variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bg={"#ffbf00"} colorScheme="gray">
                <Thead>
                  <Tr>
                    <Th>Rank</Th>
                    <Th>Name</Th>
                    <Th>Email</Th>
                    <Th>Mobile</Th>
                    <Th>D Score</Th>
                    <Th>E Score</Th>
                    <Th>Penalty</Th>
                    <Th>Total</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {players.map((player, index) => (
                    <Tr key={index}>
                      <Td>{index + 1}</Td>
                      <Td>{player.name}</Td>
                      <Td>{player.email}</Td>
                      <Td>{player.mobile}</Td>
                      <Td>{player.scores.e}</Td>
                      <Td>{player.scores.d1}</Td>
                      <Td>{player.scores.penalty}</Td>
                      <Td>{player.scores.total}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <div>
                <br />
                <Text>No approved players found.</Text>
              </div>
            )}
          </div>
          {players.map((player, index) => (
            <div id={`certificate-${player._id}`} key={index} style={{ display: 'none' }}>
              <Box padding={"20px"} border={"1px solid #000"} margin={"10px"}>
                <Heading as="h1">Certificate of Achievement</Heading>
                <Text>This is to certify that</Text>
                <Heading as="h2">{player.name}</Heading>
                <Text>has shown outstanding performance.</Text>
              </Box>
            </div>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CertificatesEventWise;
