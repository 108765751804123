import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { keyframes } from "@emotion/react";
import { Box, Button, Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import {API_URL} from "../../utils/apiConfig";

const GamesPage = () => {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchEventDetails();
  }, [eventId]);

  const fetchEventDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/api/approved-players-spot/${eventId}`);
      setEvent(response.data); // Directly use the event details for the given eventId
    } catch (error) {
      console.error('Error fetching event details:', error);
      setError('Failed to load event details');
    } finally {
      setLoading(false);
    }
  };

  const blinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const BlinkingDot = () => (
  <Box as="span" 
       display="inline-block" 
       width="12px" 
       height="12px" 
       borderRadius="50%" 
       bg="green.500"
       mt={"5px"} 
       animation={`${blinkAnimation} 1s infinite`}>
  </Box>
);

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>{error}</Text>;
  }

  if (!event) {
    return (
    <Flex height="90vh" justifyContent={"center"} alignItems={"center"}>
      <Heading >This Competition is not started yet.</Heading>
      </Flex>
    )
  }

  return (
    <Box minHeight="100vh" mb={"10px"} bg="gray.200" p={4}>
      <Button as={Link} to="/live" mb={4}>Back</Button>
      <Heading color="black" as="h2" mb={4}>{event.eventName}</Heading>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
        {Object.keys(event.sports).map((sportName, index) => (
          <Box
            key={index}
            color="white"
            fontWeight={"bold"}
            bg="gray.900"
            p={4}
            borderRadius="md"
            textAlign="center"
            as={Link}
            to={`/live/${eventId}/${sportName}`}
            cursor="pointer"
            transition="background 0.2s"
            _hover={{ bg: "gray.700" }}
          >
            <Box  textAlign={"start"}>
            {sportName === "vault2Women"? "Vault 2" : sportName === "vault1Women"? "Vault 1" : sportName === "vault1Men"? "Vault 1" : sportName === "vault2Men"? "Vault 2" : sportName === "vaultMen"? "Vault": sportName === "floor"? "Floor": sportName === "pommel_horse"? "Pommel Horse" : sportName === "rings"?"Rings": sportName==="horizontal_bar"?"Horizontal Bar": sportName ==="parallel_bars"?"Parallel Bars": sportName === "vaultWomen"?"Vault": sportName ==="uneven_bars"? "Uneven Bars": sportName==="balance_beam"?"Balance Beam": sportName==="floor_exercise"?"Floor Exercise":sportName}{event.eventStatus === "live"?<span style={{color:"green", marginLeft:"20px"}}>Live<BlinkingDot/></span>:""}
          </Box>
          </Box>
        ))}
      </SimpleGrid>
      <br/>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
      <Box
        // key={index}
        color="white"
        fontWeight={"bold"}
        bg="gray.900"
        p={4}
        borderRadius="md"
        textAlign="center"
        as={Link}
        to={`/live/${eventId}/vaultfinal`}
        cursor="pointer"
        transition="background 0.2s"
        _hover={{ bg: "gray.700" }}>
          <Box textAlign={"start"}>
            Vault Total {event.eventStatus === "live"?<span style={{color:"green", marginLeft:"20px"}}>Live<BlinkingDot/></span>:""}
          </Box>
        </Box>
        <Box
        // key={index}
        color="white"
        fontWeight={"bold"}
        bg="gray.900"
        p={4}
        borderRadius="md"
        textAlign="center"
        as={Link}
        to={`/live/${eventId}/allaround`}
        cursor="pointer"
        transition="background 0.2s"
        _hover={{ bg: "gray.700" }}>
          <Box textAlign={"start"}>
            All Around {event.eventStatus === "live"?<span style={{color:"green", marginLeft:"20px"}}>Live<BlinkingDot/></span>:""}
          </Box>
        </Box>
        <Box
        // key={index}
        color="white"
        fontWeight={"bold"}
        bg="gray.900"
        p={4}
        borderRadius="md"
        textAlign="center"
        as={Link}
        to={`/live/${eventId}/teams`}
        cursor="pointer"
        transition="background 0.2s"
        _hover={{ bg: "gray.700" }}>
          <Box textAlign={"start"}>
            Teams Result{event.eventStatus === "live"?<span style={{color:"green", marginLeft:"20px"}}>Live<BlinkingDot/></span>:""}
          </Box>
        </Box>
      </SimpleGrid>
      <br/>
    </Box>
  );
};

export default GamesPage;
