import { useState } from 'react';
import {
  Box,
  chakra,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  Input,
  IconButton,
  useColorModeValue,
  Heading,
} from '@chakra-ui/react';
import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { BiMailSend } from 'react-icons/bi';
import { FaWhatsappSquare } from "react-icons/fa";
const Logo = () => {
  return (
    <chakra.svg
      height={32}
      viewBox="0 0 120 28"
      xmlns="http://www.w3.org/2000/svg">
      {/* Your SVG paths */}
    </chakra.svg>
  );
};

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.a
      href={href}
      rounded={'full'}
      w={8}
      h={8}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      cursor={'pointer'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.a>
  );
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function LargeWithNewsletter() {
  const [email, setEmail] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle subscription logic
  };

  return (
    <Box
      bg={useColorModeValue('gray.800', 'gray.50')}
      color={useColorModeValue('white', 'gray.100')}
      py={10}>
      <Container maxW={'6xl'}>
        <SimpleGrid columns={{ sm: 2, md: 4 }} spacing={8}>
          <Stack spacing={6}>
            <Box>
              {/* <Logo /> */}
              <Heading bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" bgClip={"text"} size={"md"}>Gymnazien</Heading>
            </Box>
            <Text fontSize={'sm'}>
              © 2024 Gymnazien. All rights reserved
            </Text>
            <Stack direction={'row'} spacing={6}>
              <SocialButton label={'Twitter'} href={'https://x.com/gymnazien'}>
                <FaTwitter />
              </SocialButton>
              <SocialButton label={'YouTube'} href={'https://www.youtube.com/@gymnazientv4977'}>
                <FaYoutube />
              </SocialButton>
              <SocialButton label={'Instagram'} href={'https://www.instagram.com/gymnazien_'}>
                <FaInstagram />
              </SocialButton>
              <SocialButton label={'WhatsApp'} href={"https://chat.whatsapp.com/FUYWaWGO0gWKllcViKw9Xj"}>
              <FaWhatsappSquare />
              </SocialButton>
            </Stack>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Company</ListHeader>
            <Link href={'/about'}>About us</Link>
            <Link href={'/blogs'}>Blog</Link>
            <Link href={'/team'}>Our Team</Link>
            <Link href={'/contact'}>Contact us</Link>
            <Link href={'/pricing'}>Pricing</Link>
            
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Support</ListHeader>
            <Link href={'/help'}>Help Center</Link>
            <Link href={'/terms'}>Terms of Service</Link>
            <Link href={'/legal'}>Legal</Link>
            <Link href={'/privacy'}>Privacy Policy</Link>
            <Link href={'/status'}>Status</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Stay up to date</ListHeader>
            <Stack direction={'row'}>
              <Input
                type="email"
                placeholder="Your email address"
                value={email}
                onChange={handleEmailChange}
                bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
                border={0}
                _focus={{
                  borderColor:"1px solid black",
                  bg:""
                }}
              />
              <IconButton
                type="submit"
                bgGradient= "linear(to-r, yellow.400, yellow.500, yellow.600)"
                color={useColorModeValue('white', 'gray.800')}
                _hover={{
                  bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
                }}
                aria-label="Subscribe"
                icon={<BiMailSend />}
                onClick={handleSubmit}
              />
            </Stack>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
