import React, { useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Image,
  Button,
  Spinner,
  Box,
  Heading,
  Input,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SideBar from './Sidebar/SideBar';
import maleathleteimg from "../../Images/Screenshot (2).png";

const UsersList = () => {
  const [athletes, setAthletes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchEmail, setSearchEmail] = useState('');
  const athletesPerPage = 10;
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchAthletes = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`https://aniketrasal.online/api/auth/users?page=${currentPage}&limit=${athletesPerPage}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setAthletes(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching athletes:', error);
        setLoading(false);
      }
    };

    fetchAthletes();
  }, [currentPage]);

  const handleSearchChange = async (e) => {
    const email = e.target.value;
    setSearchEmail(email);

    if (email) {
      try {
        const response = await axios.get(`https://aniketrasal.online/api/auth/users/${email}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setAthletes([response.data]); // Set the athlete data to an array
      } catch (error) {
        console.error('Error fetching user by email:', error);
        setAthletes([]); // Clear athletes if not found
      }
    } else {
      // If the search input is empty, fetch all athletes again
      const fetchAthletes = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`https://aniketrasal.online/api/auth/users?page=${currentPage}&limit=${athletesPerPage}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          setAthletes(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching athletes:', error);
          setLoading(false);
        }
      };
      fetchAthletes();
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalAthletes = athletes.length; // Count total athletes based on array length

  if (loading) {
    return <Spinner size="xl" />;
  }

  return (
    <SideBar>
      <Box>
        <Heading size="lg" mb={4}>
          Total Users: {totalAthletes}
        </Heading>
        <Input
          placeholder="Search by email"
          value={searchEmail}
          onChange={handleSearchChange}
          mb={4}
        />
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Photo</Th>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Mobile</Th>
              </Tr>
            </Thead>
            <Tbody>
              {athletes.map((athlete) => (
                <Tr key={athlete._id}>
                  <Td>
                    <Image
                      src={maleathleteimg}
                      alt={athlete.name}
                      boxSize="50px"
                      borderRadius="full"
                    />
                  </Td>
                  <Td>{athlete.name}</Td>
                  <Td>{athlete.email}</Td>
                  <Td>{athlete.mobile}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <Box mt={4}>
          {/* Pagination controls can be added here if needed */}
        </Box>
      </Box>
    </SideBar>
  );
};

export default UsersList;
