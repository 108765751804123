// src/Profile.js
import React, { useEffect, useState } from 'react';
import {
    Box, Button, Flex, Text, Heading, Stack, Tabs, TabList, TabPanels, Tab, TabPanel, Avatar, Modal,
    ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel,
    Input, useDisclosure
} from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import {API_URL} from "../utils/apiConfig";

const UserProfile = () => {
    const [athlete, setAthlete] = useState(null);
    const [loading, setLoading] = useState(true);
    const { user, loading: authLoading } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [formState, setFormState] = useState({});
    const [originalState, setOriginalState] = useState({});
    const [isFormDirty, setIsFormDirty] = useState(false);

    useEffect(() => {
        if (user && user.email) {
            const userEmail = user.email;
            axios.get(`${API_URL}/api/auth/users/${userEmail}`)
                .then(response => {
                    setAthlete(response.data);
                    setFormState(response.data);
                    setOriginalState(response.data); // Save original state
                    setLoading(false);
                })
                .catch(error => {
                    console.error("There was an error fetching the athlete data!", error);
                    setLoading(false);
                });
        } else if (!authLoading) {
            setLoading(false);
            console.error("User not available to fetch athlete data.");
        }
    }, [user, authLoading]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState((prev) => ({
            ...prev,
            [name]: value,
        }));
        checkIfFormIsDirty({ ...formState, [name]: value }); // Check if form is dirty with the new value
    };

    // Function to check if form state is different from the original state
    const checkIfFormIsDirty = (updatedFormState) => {
        const isDirty = Object.keys(updatedFormState).some(key => updatedFormState[key] !== originalState[key]);
        setIsFormDirty(isDirty);
    };

    const handleUpdate = () => {
        if (!isFormDirty) {
            // If the form is not dirty, do nothing
            return;
        }

        axios.put(`${API_URL}/api/auth/users/${user.email}`, formState)
            .then(response => {
                setAthlete(response.data); // Update the athlete state with the new data
                setFormState(response.data);
                setOriginalState(response.data); // Reset original state to new updated data
                setIsFormDirty(false); // Reset the form dirty state
                onClose(); // Close the modal
            })
            .catch(error => {
                console.error("There was an error updating the athlete data!", error);
            });
    };

    if (loading) {
        return <Box>Loading...</Box>;
    }

    if (!athlete) {
        return <Box>Failed to load user data.</Box>;
    }

    return (
        <Box maxW="800px" mx="auto" p={5}>
            <Flex direction={{ base: 'column', md: 'row' }} align="center" justify="center" mb={8}>
                <Avatar
                    borderRadius="full"
                    boxSize="150px"
                    size='2xl'
                    src={`${API_URL}/${athlete.profileImage}`}
                    bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
                    name={athlete.name}
                    mb={{ base: 4, md: 0 }}
                    mr={{ base: 0, md: 6 }}
                />
                <Box textAlign={{ base: 'center', md: 'left' }}>
                    <Heading as="h2" bgClip="text" bgGradient="linear(to-r, yellow.500, yellow.600, yellow.700)" size="lg">{athlete.name}</Heading>
                    {/* <Heading as="h4" size="md">Athlete Code: {athlete.athleteCode}</Heading> */}
                    {/* <Text fontSize="sm" color="gray.500">Rankings: {athlete.rankings}</Text> */}
                    <Button mt={2} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" onClick={onOpen}>Edit Profile</Button>
                </Box>
            </Flex>
            <Tabs>
                <TabList >
                    <Tab fontSize={"20px"} bgClip="text" bgGradient="linear(to-r, yellow.500, yellow.600, yellow.700)" style={{ color: 'black' }}>About</Tab>
                    <Tab fontSize={"20px"} style={{ color: 'black' }}>Timeline</Tab>
                    <Tab fontSize={"20px"} style={{ color: 'black' }}>Compititions</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Stack spacing={3}>
                            <Flex flexWrap={"wrap"} justifyContent={"space-between"}>
                                <Box>
                                    
                                    <Text pb={"10px"} fontSize="lg"><b>Name:</b> {athlete.name}</Text>
                                    <Text pb={"10px"} fontSize="lg"><b>Email:</b> {athlete.email}</Text>
                                    <Text pb={"10px"} fontSize="lg"><b>Phone:</b> +{athlete.mobile}</Text>
                                    {/* <Text pb={"10px"} fontSize="lg"><b>Aadhaar No:</b> {athlete.aadhaarNumber}</Text> */}
                                </Box>
                                <Box>
                                    {/* <Text pb={"10px"} fontSize="lg"><b>Club:</b> {athlete.club}</Text> */}
                                    {/* <Text pb={"10px"} fontSize="lg"><b>City:</b> {athlete.city}</Text> */}
                                    {/* <Text pb={"10px"} fontSize="lg"><b>State:</b> {athlete.state}</Text> */}
                                    {/* <Text pb={"10px"} fontSize="lg"><b>Country:</b> {athlete.country}</Text> */}
                                </Box>

                            </Flex>



                        </Stack>
                    </TabPanel>
                    <TabPanel>
                        <Text>Timeline content...</Text>
                    </TabPanel>
                    <TabPanel>
                        <Text>No verified compititions available</Text>
                    </TabPanel>
                </TabPanels>
            </Tabs>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Profile</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={4}>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input
                                    name="name"
                                    value={formState.name || ''}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    name="email"
                                    type="email"
                                    value={formState.email || ''}
                                    onChange={handleInputChange}
                                    disabled // Email should not be editable
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Phone</FormLabel>
                                <Input
                                    name="mobile"
                                    value={formState.mobile || ''}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme={isFormDirty ? 'blue' : 'gray'}
                            color={isFormDirty ? 'white' : 'gray'}
                            mr={3}
                            onClick={handleUpdate}
                            disabled={!isFormDirty}
                            _disabled={{
                                cursor: 'not-allowed', // Prevent click
                                opacity: 0.5, // Reduce opacity to indicate disabled state
                                bg: 'gray.300', // Change background color to gray
                                color: 'gray.600', // Change text color to gray
                                _hover: { bg: 'gray.300' } // Remove hover effect
                            }}
                        >
                            Update
                        </Button>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default UserProfile;
